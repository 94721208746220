import React from "react";
import { GoogleLogin } from "react-google-login";
import { refreshTokenSetup } from "../Utils/refreshToken.js";
import { useHistory } from "react-router-dom"; // import Otp from '../Otpcomponent/Otp.js';
import "../Styles/login.css";
import axios from "axios";
import Vector from "../Assets/Vector.svg";
import rightarrow from "../Assets/rightarrow.svg";

const clientId =
  "962270165795-uftqafh4snmgttdur7c7paj7n9gje8uh.apps.googleusercontent.com";

function Login() {
  const history = useHistory();

  const onSuccess = (res) => {
    console.log("Login Success: currentUser:", res);
    history.push("/selection");
    refreshTokenSetup(res);

    // If respose got from google
    if (res) {
      const data = {
        access_token: res.accessToken,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify(data);

      axios
        .post("api/auth/social/google/", body, config)
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("access", response.data.data.access);
            localStorage.setItem("refresh", response.data.data.refresh);
            history.push("/selection");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  return (

      <div className="login-container">
        <div
          className=" login_box "
          id="login_box"
        >
          <h1 className=" text-2xl font-medium pb-10 pt-20  text-center ">
            Sign In
          </h1>

          <GoogleLogin
            clientId={clientId}
            buttonText="Login"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            render={(renderProps) => (
              <button onClick={renderProps.onClick}>
                <div
                  className=" md:w-72 w-64  h-12 flex mx-auto  mt-6 "
                  style={{
                    background: "#8F99FF",
                    borderRadius: "10px",
                    alignItems: "center",
                    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <img className="pl-10" src={Vector} alt="" />
                  <div className=" pl-5 pt-4 h-12  text-xs font-semibold text-white  ">
                    {" "}
                    Sign In With Google
                  </div>
                </div>
              </button>
            )}
          />

          <div
            className="flex  pt-5 pb-3   justify-center text-black "
            style={{ alignItems: "center" }}
          >
            <hr style={{ width: "121px" }} />
            <p className="px-2  text-sm font-medium ">or</p>
            <hr style={{ width: "121px" }} />
          </div>
          <div className="mt-8">
            <div
              onClick={() => history.push("/otp")}
              style={{
                cursor: "pointer",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
              }}
              className=" bg-white md:w-72 w-64 flex justify-around mx-auto  "
            >
              <button className="h-12 px-3  text-xs cursor-pointer ">
                {" "}
                Sign In With Mobile Number
              </button>
              <img className=" pl-6" src={rightarrow} alt="" />
            </div>

            <div
              onClick={() => history.push("/emailverification")}
              style={{
                cursor: "pointer",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                borderRadius: "10px",
              }}
              className=" bg-white md:w-72 w-64  mt-8 flex justify-around mx-auto  "
            >
              <button className="h-12 px-3 text-xs  ">
                Sign In With Email
              </button>
              <img className=" pl-20" src={rightarrow} alt="" />
            </div>

            <div className="pt-10 ">
              <button
                onClick={() => history.push("/home")}
                style={{
                  borderColor: "#717EF0",
                  width: "131px",
                  height: "29px",
                  color: "#717EF0",
                }}
                className="border rounded-lg h-7 text-xs bg-white   "
              >
                Skip Registration
              </button>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Login;
