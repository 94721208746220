import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import leftarrow from "../../Assets/leftarrow.svg"
import { inject, observer } from "mobx-react";
import { Colors } from "../../Utils/colors";
import axios from "axios";
// import Blackarrow from '../../Assets/Blackarrow.png'
import { Skeleton } from "antd";

const Specialization = inject("store")(
  observer(({ store, otherProp }) => {
    const [list, setList] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const history = useHistory();
    const { state } = useLocation();

    useEffect(() => {
      axios
        .get("api/doc/specializations/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setList(res.data.data);
            setShowSkeleton(false);
          }
          console.log(res.data);
        });
    }, []);

    const handleSubmit = (specialization) => {
      let docType =
        store.color === Colors.allopathic
          ? 1
          : store.color === Colors.homeopathic
            ? 2
            : 3;
      // console.log(state.isCovid, docType, specialization, localStorage.userId);
      const data = {
        user: state.id,
        requirement_type: specialization,
        doc_field: docType,
        is_covid: state.isCovid,
      };
      console.log(data);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.access,
        },
      };

      const body = JSON.stringify(data);

      axios
        .post("api/pat/schedule/", body, config)
        .then((response) => {
          console.log(response);
          history.push("/loading", {
            id: response.data.data.id,
            spec_type: response.data.data.requirement_type,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div className=" max-w-lg mx-auto text-center p-4">
        <div onClick={() => history.goBack()}>
          <div className="pl-2 pb-2 pt-4">
            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 15L2 8L9 1" stroke="black" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
        </div>
        <div className="pt-20">
          <div className="text-left mx-8 pb-6">
            <h1 className="font-medium text-2xl mb-5">
              Doctor Specialization
            </h1>
          </div>
          {
            showSkeleton ?
              <div className="p-8">
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div> : null
          }

          {list.map((eachItem) =>
          (eachItem.id !== 1 && eachItem.id !== 2 &&
            <div
              onClick={() => {
                handleSubmit(eachItem.id);
              }}
              key={eachItem.id}
              className="text-black mx-8 my-4 p-12 text-base"
              style={{
                background: "#FFFFFF",
                boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
                borderRadius: "2px"
              }}
            >
              {eachItem.name}
            </div>
          )
          )}
        </div>
      </div>
    );
  })
);

export default Specialization;
