import React from 'react'
import './App.css';
import { Routes } from './Routes';
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { Provider } from "mobx-react";
import Data from "./MobixStore/store";
import SendBirdCall from "sendbird-calls";
import axios from "axios"

const store=window.store=new Data();

function App() {

  SendBirdCall.init("F817BA59-7B07-4909-99D9-6FD0D741489F")
  async function token(){
    var refresh = localStorage.refresh;
          if (refresh !== null) {
            if (refresh) {
              const response = await axios.post("api/auth/refresh_token/", {
                refresh : refresh,
              }).catch((error) => {
                console.log(error);
                  return(
                    <BrowserRouter>
                    <Switch>
                      <Route render={() => <Redirect to={"/login"} />} />
                    </Switch>
                  </BrowserRouter>
                  );
              })
              console.log(response);
              if(response.data === undefined) return(
                <BrowserRouter>
                    <Switch>
                      <Route render={() => <Redirect to={"/login"} />} />
                    </Switch>
                  </BrowserRouter>
              ) ;
              if (response.data.access) {
                localStorage.setItem("access", response.data.access);
                localStorage.setItem("refresh", response.data.refresh);
          } else {
            setTimeout(() => {
              return(
                <BrowserRouter>
                    <Switch>
                      <Route render={() => <Redirect to={"/login"} />} />
                    </Switch>
                  </BrowserRouter>
              );
            }, 2500);
          }
        }
    }
}

  if(window.location.pathname !== "/login")
  token();

  return (
    <div className="App">
      <Provider store={store}>
        <Routes />
      </Provider>

    </div>
  );
}

export default App;
