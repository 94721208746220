import React, { useState, useEffect } from 'react'
import blackShare from '../Assets/blackShare.svg';
import { Input, message, Modal } from 'antd';
import { inject, observer } from "mobx-react";
import whiteShare from '../Assets/whiteShare.svg';
import whiteEdit from '../Assets/whiteEdit.svg'
import { useHistory } from 'react-router';
import axios from 'axios';
import { Colors, HexColors } from '../Utils/colors';

const UploadCard = inject("store")(observer(({ store, obj }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const history = useHistory();
    const [name, setName] = useState("");

    const showModal = () => {
        setIsModalVisible(true);
    };
    const showModal2 = () => {
        setIsModalVisible2(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleCancel2 = () => {
        setIsModalVisible2(false);
    };

    useEffect(() => {
        const dot = ".";
        const dotPos = obj.file.lastIndexOf(dot);
        if (dotPos !== -1 && obj.name != null) {
            setName(obj.name.substring(0, dotPos + 1));
        } else {
            setName(obj.name)
        }

    }, [obj])

    const handleChange = () => {
        const data = {
            id: obj.id,
            name: name
        };

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.access,
            },
        };

        const body = JSON.stringify(data);

        axios
            .post("api/doc/my-uploads/", body, config)
            .then((response) => {
                console.log(response);
                message.success("Name Changed");
                setIsModalVisible2(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <div style={{ width: "98%", }} className="mx-auto px-2">
            <div className="rounded-xl h-40 mt-6" style={{ width: "100%", backgroundColor: store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic), alignItems: "center" }}>
            </div>
            <div onClick={showModal} className="rounded-xl w-full -mt-24" style={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #DADADA",
                borderRadius: "0px 12px 12px 12px"
            }}>
                <img onClick={() => history.push("/share", { obj: obj })} className="pt-2 pl-5" src={blackShare} alt="" />
                <p className="pl-4 pt-10" style={{ color: "black" }}>{obj.created_at.substr(0, 10).split("-").reverse().join("-")}</p>
                <p className="pl-4 text-lg" style={{ color: "black", paddingBottom: "2vh" }}>{name ? name.length > 5 ? name.substring(0, 4) + "..." : name : "NA"}</p>
            </div>

            <Modal
                visible={isModalVisible}
                footer={null}
                mask={true}
                onCancel={handleCancel}
                maskStyle={{ background: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(1px)" }}
                bodyStyle={{ width: "100%", height: "100%", marginTop: "-60px" }}
                className="modal-uploads"
            >
                <div>
                    <div style={{ width: "95%" }} className="mx-auto px-2" >
                        <img className="w-full mt-6" src={obj.file} style={{ height: "65vh" }} alt="" />
                        <div className=" w-full mx-auto -mt-2  " style={{ background: "linear-gradient(180deg, #929BFF 0%, #717EF0 98.75%)", borderRadius: "0px 15px 15px 15px", height: "15vh" }} >
                            <div className="flex justify-end pt-5" >
                                <img onClick={() => history.push("/share", { obj: obj })} className="px-2" src={whiteShare} alt="" />
                                <img onClick={() => showModal2()} className="pl-2 pr-6" src={whiteEdit} alt="" />
                            </div>
                            <div className="pl-6 mt-1">
                                <div style={{ color: "white", fontSize: "10px" }}>{obj.created_at.substr(0, 10).split("-").reverse().join("-")}</div>
                                <div style={{ color: "white" }} className="text-lg">{name ? name.length > 15 ? name.substring(0, 10) + "..." : name : "NA"}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                className="modal-uploads"
                visible={isModalVisible2}
                mask={true}
                onOk={handleOk}
                onCancel={handleCancel2}
                maskStyle={{ background: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(1px)" }}
            ><div
                className="mt-8"
            >
                    <div>File Name</div>
                    <Input
                        name="name"
                        type="text"
                        style={{
                            color: "black",
                            borderRadius: "2px",
                            maxWidth: "95%",
                            marginBottom: "12px",
                            marginTop: "11px",
                            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                            border: "none",
                            paddingLeft: "12px",
                            paddingright: "12px",
                            fontSize: "12px !important",
                        }}
                        className=" h-8 outline-none px-5  text-black text-xs  font-normal "
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        placeholder={name ? name : "Default"}
                    />
                    <button
                        onClick={() => handleChange()}
                        style={{}} className="bg-gray-200 px-3 py-1 mt-4 ml-2">Ok</button>
                </div>
            </Modal>
        </div>
    )
}))

export default UploadCard