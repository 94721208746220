import React from 'react'
import { useHistory } from 'react-router-dom'
import blackleftarrow from '../Assets/blackleftarrow.svg'
import doublearrow from '../Assets/doublearrow.svg'
import waiting from '../Assets/waiting.svg'

function WaitingRoom() {

    const history= useHistory();
    
    return (
        <div className="container text-center mx-w-sm mx-auto" style={{ alignItems: "center" }}>
            <img onClick={()=>history.goBack()} className="pt-10 pl-8" src={blackleftarrow} alt="" />
            <div className="md:grid md:place-items-center md:h-screen md:w-full mt-28" style={{ alignItems: "center" }} >
                <h1 className="text-2xl px-10">Oops, looks like everyone’s busy!</h1>
                <div style={{height:"250px"}} ><img className="mx-auto mt-6" src={waiting} alt="" /></div>
                <div className=" flex justify-evenly mt-16 mx-7 mb-2 py-3 px-2"
                    style={{
                        background: "#FFFFFF",
                        boxShadow: "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
                        borderRadius: "10px"
                    }}>
                    <a href="/" className="text-lg text-black font-medium">Consult a paramedic </a>
                    <img className="text-left" src={doublearrow} alt="" />
                </div>
            </div>
            <div className="fixed md:static bottom-0 w-full mt-2">
                <button
                    className="text-lg py-4 mt-12 text-white"
                    style={{ width:"100%", background: "linear-gradient(180deg, #929BFF 0%, #717EF0 98.75%)", borderRadius: "2px" }}
                >
                    Come back later
                </button>
            </div>
        </div>
    )
}

export default WaitingRoom
