import React, { useEffect, useState } from 'react'
import UploadCard from "../Components/UploadCard"
// import Header from '../Components/Header'
import { inject, observer } from "mobx-react";
//import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import leftarrow_black from "../Assets/leftarrow_black.svg"
import axios from 'axios';
import { Skeleton } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Sidebar from "../Components/Headers/sidebar";

const Uploads = inject("store")(observer(({ store, otherProp }) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(true);
    //const { state } = useLocation();
    //const { user } = state;
    const [searchData, setSearchData] = useState([]);
    const [search, setSearch] = useState("");
    const [searchEnable, setSearchEnable] = useState(false);

    useEffect(() => {

        if (localStorage.access && localStorage.refresh) {
            axios
                .get("api/doc/my-uploads/", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.access,
                    },
                })
                .then((res) => {
                    console.log(res);
                    setData(res.data.data);
                    setShowSkeleton(false);
                })
                .catch(() => {
                    console.log("error");
                })
        }
    }, [])
    console.log({ data })

    const OnSearch = async (search) => {
        setSearch(search);
        setSearchEnable(true);
        if (search !== "") {
            try {
                let filterItems = (arr, query) => {
                    return arr.filter((item) => {
                        let upload = item.name.toLowerCase().indexOf(query) !== -1;
                        return upload;
                    });
                };

                let result = await filterItems(data, search.toLowerCase());
                await setSearchData(result);
            } catch (e) {
                console.log("e2", e);
            }
        }
    };

    return (
        <div >
            {/* <Header user={user.user} /> */}
            <div className="mobile">
                <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
                    <img className=" text-black " src={leftarrow_black} alt="" />
                </div>
            </div>
            <div className="flex">
                <div className="websites">
                    <Sidebar />
                </div>

                <div className="web mx-auto">
                    <div className="heading-presc">
                        <h1 className="font-normal text-2xl mt-4 md:text-center ml-8 sm:text-5xl">
                            My Uploads
                        </h1>
                    </div>

                    <div
                        className="search-presc flex justify-between  mx-auto py-2 rounded-2xl mt-10"
                        style={{ boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)" }}
                    >
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => OnSearch(e.target.value)}
                            className=" text-left py-1 px-6"
                            style={{ alignItems: "center", width: "85%" }}
                            placeholder="Search by Name"
                        />
                        <button className="" style={{ width: "10%" }}>
                            <SearchOutlined />
                        </button>
                    </div>

                    <div>
                        <h1 className="mobile font-normal text-2xl mt-4 md:text-center ml-8 sm:text-5xl">
                            My Uploads
                        </h1>
                    </div>

                    {
                        showSkeleton ?
                            <div className="p-8">
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active />
                            </div> : null
                    }

                    <div className="prescription-grid mx-auto grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2">
                        {searchEnable && search !== ""
                            ? searchData.map((obj) => (
                                <div style={{ gridColumnStart: obj[0] ? "1" : "", gridColumnEnd: obj[0] ? "1" : "" }}><UploadCard key={obj.id} obj={obj} /></div>
                            ))
                            :
                            data.map((obj) =>
                                <div style={{}}><UploadCard key={obj.id} obj={obj} /></div>
                            )}
                    </div>
                    {
                        searchEnable && searchData.length === 0 &&
                        <div className="mx-auto text-center mt-10" style={{ width: "95%", fontSize: "16px" }}>No result found</div>
                    }

                    {data.length === 0 && data !== "" &&
                        <div className="mx-auto text-center mt-10 sm:text-center" style={{ width: "95%", fontSize: "30px" }}>No result found</div>
                    }

                    <div className="h-16"></div>

                </div>

            </div>
        </div>
    )
}))

export default Uploads