import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import Login from "./Pages/Login";
import Onboarding from "./Pages/Onboarding";
import Otp from "./Pages/Otp";
import Otpverification from "./Pages/Otpverification";
import Selection from "./Pages/Selection";
import Doctortype from "./Pages/Doctortype";
import Medicinetype from "./Pages/Patientformpages/Medicinetype";
import Formpatient from "./Pages/Patientformpages/Formpatient";
import Consultationtype from "./Pages/Patientformpages/Consultationtype";
import Engagementtype from "./Pages/Patientformpages/Engagementtype";
import Home from "./Components/Home/Home";
import Email from "./Pages/Email";
import WaitingRoom from "./Pages/WaitingRoom";
import Loading from "./Pages/Loading";
import FoundDoctor from "./Pages/FoundDoctor";
import Ambulance from "./Components/HomeButtons/Ambulance";
import Hospital from "./Components/HomeButtons/Hospital";
import Pharma from "./Components/HomeButtons/Pharma";
import EmailFeedback from "./Components/HomeButtons/emailFeedback";
import Specialization from "./Pages/Patientformpages/specializationPage";
import Doctorchat from "./Pages/Patientformpages/Doctorchat";
import StartChat from "./Components/StartChat";
import Doctorform from "./Pages/DoctorForm";
import DoctorHome from "./Components/DoctorHome/DoctorHome";
import Prescription from "./Pages/Prescription";
import Share from "./Pages/Share";
import Community from "./Pages/Community";
import PatientList from "./Pages/Pateint";
import DoctorProfile from "../src/Components/Profile/DoctorProfile";
import DoctorEditingform from "../src/Components/Profile/DoctorEditingform";
import PatientForm from "./Pages/PatientForm";
import PrescriptionOpen from "./Pages/PrescriptionOpen";
import PrescriptionForm from "./Pages/PrescriptionForm";
import PatientProfile from "./Components/Profile/PatientProfile.jsx";
import ProfileForm from "./Components/Profile/ProfileEditForm";
import SingleCommunity from "./Pages/SingleCommunity";
import DoctorCommunityForm from "./Pages/DoctorCommunityForm";
import DoctorCommunityMyUploads from "./Pages/DoctorCommunityMyUploads";
import CommunitySingleMyUpload from "./Pages/CommunitySingleMyUpload";
import CallEnd from "./Pages/CallEnd";
import Uploads from "./Pages/Uploads";
import Signature from "./Pages/Signature";
import VoiceCall from "./Pages/voiceCall";
import Symptoms from "./Components/Appointment/Symptoms";
import Appointment from "./Components/Appointment/Appointment";
import Screen2 from "./Components/Appointment/Screen2";
import Screen4 from "./Components/Appointment/Screen4";
import Screen5 from "./Components/Appointment/Screen5";
import Screen6 from "./Components/Appointment/Screen6";
import BookDoctor from "./Components/Appointment/BookDoctor";
import AppoinmentForm from "./Components/Appointment/AppoinmentForm";

export function Routes() {
  var path = "";
  if (localStorage.access && localStorage.refresh) {
    if (localStorage.user_type === "1") {
      path = "/home";
    } else if (localStorage.user_type === "2") {
      path = "/doctorhome";
    } else {
      path = "/home";
    }
  } else {
    if (window.matchMedia("(max-width:768px)").matches) {
      path = "/onboarding";
    } else {
      path = "/login";
    }
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={path} />} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/onboarding" component={Onboarding} />
        <AuthRoute exact path="/login" component={Login} />
        <AuthRoute exact path="/otp" component={Otp} />
        <AuthRoute exact path="/otpverification" component={Otpverification} />
        <ProtectedRoute exact path="/selection" component={Selection} />
        {/* <Route exact path="/selection" component={Selection} /> */}
        <ProtectedRoute exact path="/formpatient" component={Formpatient} />
        <ProtectedRoute exact path="/doctortype" component={Doctortype} />
        <ProtectedRoute exact path="/medicinetype" component={Medicinetype} />
        <ProtectedRoute
          exact
          path="/consultationtype"
          component={Consultationtype}
        />
        <ProtectedRoute
          exact
          path="/engagementtype"
          component={Engagementtype}
        />
        <ProtectedRoute exact path="/chat" component={StartChat} />
        <ProtectedRoute exact path="/emailverification" component={Email} />
        <ProtectedRoute exact path="/waiting" component={WaitingRoom} />
        <ProtectedRoute exact path="/loading" component={Loading} />
        <ProtectedRoute exact path="/found" component={FoundDoctor} />
        <ProtectedRoute path="/ambulance" component={Ambulance} />
        <ProtectedRoute exact path="/hospitals" component={Hospital} />
        <ProtectedRoute exact path="/pharma" component={Pharma} />
        <ProtectedRoute
          exact
          path="/specialization"
          component={Specialization}
        />
        <ProtectedRoute exact path="/doctorchat" component={Doctorchat} />
        <ProtectedRoute exact path="/doctorform" component={Doctorform} />
        <ProtectedRoute exact path="/doctorhome" component={DoctorHome} />
        <ProtectedRoute exact path="/prescription" component={Prescription} />
        <ProtectedRoute exact path="/myupload" component={Uploads} />
        <ProtectedRoute exact path="/share" component={Share} />
        <ProtectedRoute exact path="/community" component={Community} />
        <ProtectedRoute exact path="/patientlist" component={PatientList} />
        <ProtectedRoute exact path="/emailfeedback" component={EmailFeedback} />
        <ProtectedRoute exact path="/screen2" component={Screen2} />
        {/* <ProtectedRoute exact path="/screen4" component={Screen4} /> */}
        <ProtectedRoute exact path="/screen4" component={Screen4} />
        <ProtectedRoute exact path="/screen5" component={Screen5} />
        <ProtectedRoute exact path="/screen6" component={Screen6} />
        <ProtectedRoute
          exact
          path="/prescriptionform"
          component={PrescriptionForm}
        />
        <ProtectedRoute exact path="/patientform" component={PatientForm} />
        <ProtectedRoute
          exact
          path="/patientprofile"
          component={PatientProfile}
        />
        <ProtectedRoute
          exact
          path="/patientprofile/edit"
          component={ProfileForm}
        />
        <ProtectedRoute exact path="/doctorprofile" component={DoctorProfile} />
        <ProtectedRoute path="/profileediting" component={DoctorEditingform} />
        <ProtectedRoute
          path="/prescriptioncontents"
          component={PrescriptionOpen}
        />
        <ProtectedRoute
          exact
          path="/singlecommunity"
          component={SingleCommunity}
        />
        <ProtectedRoute
          exact
          path="/doctorCommunityForm"
          component={DoctorCommunityForm}
        />
        <ProtectedRoute
          exact
          path="/doctorMyUpload"
          component={DoctorCommunityMyUploads}
        />
        <ProtectedRoute
          exact
          path="/SingleMyUpload"
          component={CommunitySingleMyUpload}
        />
        <ProtectedRoute exact path="/callend" component={CallEnd} />
        <ProtectedRoute exact path="/sign" component={Signature} />
        <ProtectedRoute exact path="/call" component={VoiceCall} />
        <ProtectedRoute exact path="/test1" component={Symptoms} />
        <ProtectedRoute exact path="/test2" component={BookDoctor} />
        <ProtectedRoute exact path="/test3" component={AppoinmentForm} />

        <ProtectedRoute exact path="/appointment" component={Appointment} />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}
