import React from 'react'
import { inject, observer } from "mobx-react";
import doctor from '../../Assets/doctor.svg'
import dot from '../../Assets/Dot.png'

const BookAppointmentCard = inject("store")(
    observer(({ store, data }) => {
        return (
            <div>
                <div
                    className="mx-auto mt-4"
                    style={{
                        boxShadow: "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
                        borderRadius: "18px 18px 0px 0px",
                        width: "90%"
                    }}>
                    <div className="flex" >
                        <div style={{ width: "30%" }}>
                            <img
                                style={{ borderRadius: "50%", margin: "15% 10%", height: "100px" }}
                                // className="mx-auto mt-10"
                                src={doctor}
                                alt=""
                            />
                        </div>
                        <div style={{ marginTop: "7%", marginLeft: "7%", width: "70%" }}>
                            <div className="flex">
                                <div className="font-medium" style={{ fontSize: "12px" }}>Dr Anamika</div>
                                <div className="font-medium" style={{ fontSize: "12px", marginLeft: "35%" }}>₹ 900</div>
                                <div style={{ fontSize: "9px", marginLeft: "1%", marginTop: "1%" }}>fee</div>
                            </div>
                            <div style={{ fontSize: "10px", color: "#484848", marginTop: "5%" }}>Specialization</div>
                            <div style={{ fontSize: "10px", color: "#484848", marginTop: "3%" }}>15 years of experience</div>
                            <div className="flex" style={{ fontSize: "10px", color: "#484848", marginTop: "3%" }}>
                                <div>M.G Road</div>
                                <div style={{ marginTop: "2%", marginLeft: "5%", marginRight: "5%" }}><img src={dot} alt="" /></div>
                                <div>Appolo Hospital</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mx-auto cursor-pointer"
                    style={{
                        background: store.color,
                        borderRadius: "0px 0px 18px 18px", height: "50px", width: "90%"
                    }}>
                    <div className="text-center font-medium text-sm py-4" style={{ color: "white" }}>Book Appointment</div>
                </div>
            </div>
        );
    })
);

export default BookAppointmentCard
