import React, { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { HostName } from "../../Utils/utils";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import black from "../../Assets/black.svg";
import attach2 from "../../Assets/attach2.svg";
import { inject, observer } from "mobx-react";
import Send_button from "../../Assets/Send_button.svg";
import Prescription_write from "../../Assets/priscriptionwrite.svg";
import Group105 from "../../Assets/Group105.png";
import edit from "../../Assets/edit.svg";
import cross from "../../Assets/cross.svg";
import { Input, Spin, Modal, Form, Button, Select, message } from "antd";
import axios from "axios";
import ImageUploader from "react-images-upload";
import "../../Styles/modal.css";
import "antd/dist/antd.css";
import "../../Styles/form.css";
import Doctorsidebar from "../../Components/Headers/Doctorsidebar";
import "../../Styles/chat.css";

const DoctorChat = inject("store")(
  observer(({ store, otherProp }) => {
    const { state } = useLocation();
    console.log("pateint data", state);
    localStorage.setItem("Patient_name", state.name);
    localStorage.setItem("patient_id", state.user);
    const [msg, setMsg] = useState("");
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(false);
    const [image, setImage] = useState([]);
    const [upload, setUpload] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [ws, setWs] = useState(
      new W3CWebSocket(
        `wss://${HostName}/ws/medhelp/doctor/chat/${state.doctor}/1/${state.id}/?user_token=${localStorage.access}`
      )
    );
    const [isModalVisible, setIsModalVisible] = useState(false);
    const Option = Select.Option;
    const history = useHistory();
    const [error, setError] = useState(false);
    const [add, setAdd] = useState(true);
    const [mg, setMg] = useState("");
    const [medicine, setMedicine] = useState("");
    const [med, setMed] = useState();
    const [days, setDays] = useState(0);
    const [observation, setObservation] = useState([]);
    const [note, setNote] = useState("");
    const [med_food, setMed_food] = useState("");
    const [power, setPower] = useState("");
    const [diagnostics, setDiagnostics] = useState("");
    const [medicineObj, setMedicineObj] = useState([]);
    const [obsData, setObsData] = useState([]);
    const [medData, setMedData] = useState([]);
    const [fp_req, setFp_req] = useState(null);
    const [fp_days, setFp_days] = useState(0);
    const [hosp_req, setHosp_req] = useState(null);
    const [is_morning, setIs_morning] = useState(false);
    const [is_noon, setIs_noon] = useState(false);
    const [is_evening, setIs_evening] = useState(false);
    const [is_night, setIs_night] = useState(false);
    const [input_med, setInput_med] = useState("");
    const [input_obs, setInput_obs] = useState("");
    const [showData, setShowData] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
      setInput_med("");
      setMedicine("");
      setMed("");
      setPower("");
      setMg("");
      setMed_food("");
      setDays(0);
      setIs_morning(false);
      setIs_noon(false);
      setIs_evening(false);
      setIs_night(false);
      setNote("");
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const scroll = () => {
      const element = document.getElementById("doctor_chats");
      if (element) {
        element.scroll({
          top: element.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    };

    const connect = async () => {
      ws.onopen = (e) => {
        console.log(e);
        return true;
      };
      ws.onclose = () => {
        setWs("");
      };
      ws.onerror = (e) => {
        console.log("error", e);
        return false;
      };
    };
    useEffect(() => {
      connect();
      datasavetodb();
    });

    useEffect(() => {
      scroll();
      setUpdate(false);
    }, [update]);

    const datasavetodb = async () => {
      if (connect) {
        ws.onmessage = async (message) => {
          const dataFromServer = JSON.parse(message.data);
          console.log("msgs", dataFromServer);
          if (dataFromServer.ev_type === 0) {
            ws.close();
            setChats([]);
          } else if (dataFromServer.ev_type === 2) {
            setLoading(false);
          } else if (
            dataFromServer.ev_type === 3 ||
            dataFromServer.ev_type === 4
          ) {
            let msgs = await dataFromServer;
            msgs["time"] = await moment(new Date()).format("h:mm a");
            await chats.push(msgs);
            setUpdate(true);

            if (
              dataFromServer.message === "Patient wants to call you!!" &&
              parseInt(localStorage.user_type) === 2
            ) {
              setShowButton(true);
            }
          }
        };
      }
    };
    const sendpicture = async () => {
      var form_data = new FormData();
      form_data.append("file", image[0]);
      form_data.append("doc", state.doctor);
      form_data.append("pid", state.id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.access,
        },
      };

      await axios
        .post(`api/doc/uploadfile/`, form_data, config)
        .then((res) => {
          setUpload(false);
          console.log(res);
        })
        .catch((err) => console.error("err", err));
    };
    const sendMsg = async () => {
      if (msg.length !== 0) {
        let message = JSON.stringify({ msg: msg.trim() });
        ws.send(message);
        setMsg("");
      }
    };

    const initiateCall = async () => {
      let message = JSON.stringify({
        msg: "Doctor has accepted call request.".trim(),
      });
      ws.send(message);
    };

    if (loading) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Loading Chats..."></Spin>
        </div>
      );
    }

    // code for prescription
    const handleSearch = (e) => {
      setObsData([]);
      setInput_med(e.target.value);
      axios
        .get(`api/doc/medicines/?query=${input_med}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setMedData(res.data.data);
        })
        .catch(() => console.log("Medicines not get"));

      setShowData(true);
    };

    const handleSearch2 = (e) => {
      setMedData([]);
      setInput_obs(e.target.value);
      axios
        .get(`api/doc/observation/?query=${input_obs}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          setObsData(res.data.data);
        })
        .catch(() => console.log("Observation not get"));

      setShowData(true);
    };

    const AddMedicine = (obj) => {
      if (
        power === "" ||
        mg === "" ||
        days === "" ||
        medicine === "" ||
        note === "" ||
        med_food === ""
      ) {
        setError(true);
      } else {
        setMedicineObj([...medicineObj, obj]);
        setAdd(false);
      }
    };
    console.log({ medicineObj });

    const refreshMedForm = () => {
      setInput_med("");
      setMedicine("");
      setMed();
      setPower("");
      setMg("");
      setMed_food("");
      setDays(0);
      setIs_morning(false);
      setIs_noon(false);
      setIs_evening(false);
      setIs_night(false);
      setNote("");
      setAdd(true);
      setObservation([]);
      setDiagnostics("");
      setFp_req(null);
      setFp_days(0);
      setHosp_req(null);
    };

    const editItem = async (val) => {
      try {
        let filterItems = (arr, query) => {
          return arr.filter((item) => {
            let hosp_req = item.medicine.indexOf(query) !== -1;
            return hosp_req;
          });
        };
        let deleteItems = (arr, query) => {
          return arr.filter((item) => {
            let hosp_req = item.medicine.indexOf(query) === -1;
            return hosp_req;
          });
        };

        let result = await filterItems(medicineObj, val);

        console.log({ result });

        setInput_med(result[0].medicine);
        setMedicine(result[0].medicine);
        setMed(result[0].med);
        setPower(result[0].power);
        setMg(result[0].mg);
        setMed_food(result[0].med_food);
        setDays(result[0].days);
        setIs_morning(result[0].is_morning);
        setIs_noon(result[0].is_noon);
        setIs_evening(result[0].is_evening);
        setIs_night(result[0].is_night);
        setNote(result[0].note);

        let newObj = deleteItems(medicineObj, val);
        setMedicineObj(newObj);
        setAdd(true);
      } catch (e) {
        console.log("e2", e);
      }
    };

    const handleSubmit = () => {
      if (
        observation === "" ||
        medicine === "" ||
        diagnostics === "" ||
        days === "" ||
        power === "" ||
        note === "" ||
        med_food === "" ||
        mg === ""
      ) {
        setError(true);
      } else {
        const data = {
          obs: observation.toString(),
          condition: "",
          diagnosis: diagnostics,
          fp_req: fp_req,
          fp_days: fp_days,
          hosp_req: hosp_req,
          emergency: false,
          chat: state.id,
          meds: medicineObj,
        };

        console.log(data);

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/pat/prescription/", body, config)
          .then((response) => {
            if (response.data.status) {
              message.success("Prescription Added");
              refreshMedForm();
              setIsModalVisible(false);
            }
            console.log(response);
          })
          .catch((err) => {
            message.error("something went wrong, please try again!!");
            console.log(err);
          });
      }
    };

    return (
      <div>
        <div className="sidebar">
          <Doctorsidebar />
        </div>
        <div className="   " id="doctor_chat_header">
          <div className=" sticky  top-0 ">
            <div
              className=" flex py-2 pl-4  shadow-md  bg-white z-50  "
              style={{ alignItems: " center", height: "100px" }}
            >
              <div onClick={() => history.goBack()}>
                <img className="pl-2 pb-2 pt-4" src={black} alt="" />
              </div>
              <div className="flex pl-8 " style={{ alignItems: " center" }}>
                <img
                  className=" h-10  object-contain rounded-full "
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <div className=" px-4 ">
                  <p className=" font-medium text-sm "> {state.name}</p>
                </div>
                {showButton && (
                  <button
                    onClick={() => {
                      initiateCall();
                      history.push("/call");
                    }}
                    className=" float-right   w-10   bg-black text-white rounded-full "
                  >
                    Call
                  </button>
                )}
              </div>
            </div>
          </div>

          <div
            id="doctor_chats"
            onClick={() => {
              setUpload(false);
            }}
            style={{ height: "80vh", overflow: "scroll" }}
          >
            {chats.map((msg, index) =>
              msg.message === "Doctor has accepted call request." ? (
                ""
              ) : msg.ev_type === 3 ? (
                <div key={index}>
                  <div className=" mx-4">
                    <div className="pt-4 pb-2">
                      <div
                        className=" p-2  text-black  bg-white   rounded-tr-xl rounded-br-xl rounded-bl-xl   "
                        id="message_box_width"
                        style={{
                          marginLeft:
                            msg.sender === parseInt(localStorage.userId)
                              ? "auto"
                              : "none",
                          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                          borderRadius:
                            msg.sender === parseInt(localStorage.userId)
                              ? " 16.2014px 3.24029px 16.2014px 16.2014px"
                              : "3.24029px 16.2014px 16.2014px 16.2014px",
                        }}
                      >
                        {msg.message}

                        <div className="  ">
                          <span
                            style={{ fontSize: "8px" }}
                            className=" flex justify-end    "
                          >
                            {" "}
                            {msg.time}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index}>
                  <div className=" mx-4">
                    <div className="pt-4 pb-2">
                      <div
                        className=" p-2  w-52 text-black  bg-white   rounded-tr-xl rounded-br-xl rounded-bl-xl   "
                        style={{
                          marginLeft:
                            msg.sender === parseInt(localStorage.userId)
                              ? "auto"
                              : "none",
                          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                          borderRadius:
                            msg.sender === parseInt(localStorage.userId)
                              ? " 16.2014px 3.24029px 16.2014px 16.2014px"
                              : "3.24029px 16.2014px 16.2014px 16.2014px",
                        }}
                      >
                        <img
                          src={msg.message}
                          style={{ borderRadius: "20px" }}
                          alt={"Not Available"}
                        />

                        <div className="  ">
                          <span
                            style={{ fontSize: "8px" }}
                            className=" flex justify-end    "
                          >
                            {" "}
                            {msg.time}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className=" h-16 "></div>
          <div id="doctor_chat_inputs" className=" fixed bottom-3 z-50 mx-auto">
            <div className=" flex  justify-between px-4  mx-auto ">
              <div
                className="flex justify-between bg-white mx-auto  "
                id="doctor_input_sender"
                style={{
                  boxShadow:
                    "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",

                  borderRadius: " 20px",
                }}
              >
                {upload ? (
                  <div className="  fixed  top-80 " id="doctor_upload_image">
                    <ImageUploader
                      buttonText={image.length === 0 ? "Select" : "Upload"}
                      onChange={(e) => setImage(e)}
                      imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                      maxFileSize={5242880}
                      singleImage={true}
                      withPreview
                    />
                  </div>
                ) : (
                  <Input
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    className=" pl-3 outline-none h-10  "
                    style={{ borderRadius: " 20px", border: "none" }}
                    placeholder=" Write A message "
                    type="text"
                    onPressEnter={() => sendMsg()}
                  />
                )}

                <img
                  className=" px-4"
                  onClick={() => {
                    if (upload) {
                      sendpicture();
                    } else {
                      sendMsg();
                    }
                  }}
                  src={Send_button}
                  alt=""
                />
              </div>

              <div
                className=" flex "
                style={{ alignItems: "center " }}
                id="docotor_file_sender"
              >
                <img
                  onClick={() => setUpload(!upload)}
                  className=" px-2  "
                  src={attach2}
                  alt=""
                />
                <img
                  className="  px-2"
                  src={Prescription_write}
                  onClick={showModal}
                  alt=""
                />
                <Modal
                  className="h-32"
                  title="Prescription"
                  onCancel={handleCancel}
                  visible={isModalVisible}
                  bodyStyle={{padding:"0",marginLeft:"-13vw"}}
                >
                  <div className="prescription_modal">
                    <div className="text-center px-auto px-4">
                      <h1 className="prescription_header">Prescription</h1>
                      <p
                        style={{ visibility: !error ? "hidden" : "visible" }}
                        className=" text-red-500 text-xs pt-3 "
                      >
                        *Kindly fill the required fields{" "}
                      </p>
                      <Form name="nest-messages">
                        <Input
                          type="text"
                          style={{
                            color: "black",
                            borderRadius: "2px",
                            maxWidth: "95%",
                            marginBottom: "12px",
                            marginTop: "11px",
                            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                            paddingLeft: "12px",
                            paddingright: "12px",
                            fontSize: "12px !important",
                          }}
                          className=" h-8 outline-none px-5  text-black text-xs  font-normal "
                          placeholder={state.name ? state.name : "null"}
                          disabled
                        />

                        <div className="mx-auto">
                          <Input
                            name="input_obs"
                            type="text"
                            style={{
                              color: "black",
                              borderRadius: "2px",
                              maxWidth: "95%",
                              marginBottom: "2px",
                              marginTop: "11px",
                              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                              border:
                                error && observation.length === 0
                                  ? "1px solid red"
                                  : "none",
                              fontSize: "12px !important",
                            }}
                            className=" h-8 outline-none text-black text-xs  font-normal "
                            onChange={(e) => handleSearch2(e)}
                            value={input_obs}
                            autoComplete="off"
                            placeholder="Observation- Type Keyword"
                          />
                          {showData && (
                            <div
                              className="bg-white text-left"
                              style={{
                                color: "black",
                                borderRadius: "2px",
                                maxWidth: "95%",
                                marginBottom: "12px",
                                marginRight: "10px",
                                marginTop: "0px",
                                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                fontSize: "12px !important",
                                position: "absolute",
                                zIndex: "7",
                              }}
                            >
                              {obsData.map((data, index) => (
                                <div
                                  className="my-2 mx-2"
                                  style={{
                                    color: "black",
                                    borderRadius: "2px",
                                    maxWidth: "95%",
                                    marginBottom: "12px",
                                    marginRight: "10px",
                                    fontSize: "12px !important",
                                  }}
                                  key={data.id}
                                  onClick={() => {
                                    setObservation([...observation, data.name]);
                                    setInput_obs("");
                                    setShowData(false);
                                  }}
                                >
                                  {data.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>

                        {observation.length !== 0 && (
                          <div
                            className="mx-auto"
                            style={{
                              maxWidth: "95%",
                              alignItems: "center",
                              display: "grid",
                              gridTemplateColumns: "auto auto auto",
                            }}
                          >
                            {observation.map((data, index) => (
                              <div
                                className="outline-none text-black bg-gray-200 mx-0.5 my-1 flex justify-around"
                                style={{
                                  borderRadius: "2px",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                                key={index}
                                onClick={() => {
                                  let newArray = [...observation];
                                  newArray.splice(index, 1);
                                  setObservation(newArray);
                                }}
                              >
                                {data}
                                <img
                                  className="w-2.5 h-2.5"
                                  src={cross}
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>
                        )}

                        <div className="mx-auto">
                          {medicineObj.map((data, index) => (
                            <div
                              onClick={() => editItem(data.medicine)}
                              key={index}
                              className=" py-4 pl-24 mx-auto flex justify-between"
                              style={{
                                color: "black",
                                borderRadius: "2px",
                                maxWidth: "95%",
                                marginBottom: "20px",
                                marginTop: "11px",
                                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                border: "none",
                                paddingLeft: "12px",
                                paddingright: "12px",
                                fontSize: "12px !important",
                                alignItems: "center",
                              }}
                            >
                              <div>{data.medicine}</div>
                              <div className="-ml-6">
                                {data.power} {data.mg}
                              </div>
                              <div>{data.days} Days</div>
                              <div className="pr-3">
                                <img src={edit} alt="" />
                              </div>
                            </div>
                          ))}
                        </div>

                        {add && (
                          <>
                            {" "}
                            <div
                              className="mx-auto text-left"
                              style={{ maxWidth: "95%" }}
                            >
                              <h1 className="pt-2 text-sm">Add Medicines</h1>
                            </div>
                            <div
                              className="mx-auto"
                              style={{ maxWidth: "95%" }}
                            >
                              <Input
                                name="input_med"
                                type="text"
                                style={{
                                  color: "black",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                  border:
                                    error && input_med.length === 0
                                      ? "1px solid red"
                                      : "none",
                                  fontSize: "12px !important",
                                }}
                                className=" h-8 outline-none text-black text-xs  font-normal "
                                onChange={(e) => handleSearch(e)}
                                value={input_med}
                                placeholder="Search for Medicine"
                                autoComplete="off"
                              />
                              {showData && (
                                <div
                                  className="bg-white text-left"
                                  style={{
                                    color: "black",
                                    borderRadius: "2px",
                                    maxWidth: "95%",
                                    boxShadow:
                                      "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                    fontSize: "12px !important",
                                    position: "absolute",
                                    zIndex: "5",
                                  }}
                                >
                                  {medData.map((data, index) => (
                                    <>
                                      <div
                                        className="my-2 mx-2"
                                        style={{
                                          color: "black",
                                          borderRadius: "2px",
                                          maxWidth: "95%",
                                          marginBottom: "12px",
                                          fontSize: "12px !important",
                                        }}
                                        key={data.id}
                                        onClick={() => {
                                          setMedicine(data.med);
                                          setMed(data.id);
                                          setInput_med(data.med);
                                          setShowData(false);
                                        }}
                                      >
                                        {data.med}
                                        <hr></hr>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )}
                            </div>
                            <div className="mx-auto">
                              <Input
                                name="power"
                                type="text"
                                style={{
                                  color: "black",
                                  borderRadius: "2px",
                                  width: "60%",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                  border:
                                    error && power.length === 0
                                      ? "1px solid red"
                                      : "none",
                                  fontSize: "12px !important",
                                }}
                                className=" h-8 outline-none text-black text-xs  font-normal "
                                onChange={(e) => setPower(e.target.value)}
                                value={power}
                                placeholder="potency"
                                autoComplete="off"
                              />

                              <Select
                                title="mg"
                                style={{
                                  cursor: "pointer",
                                  textAlign: "left",
                                  color: "black",
                                  borderRadius: "2px",
                                  width: "35%",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                  border:
                                    error && mg.length === 0
                                      ? "1px solid red"
                                      : "none",
                                  fontSize: "12px !important",
                                }}
                                onSelect={(e) => setMg(e)}
                                name="mg"
                                value={mg || "Unit"}
                              >
                                <Option value="mg">mg</Option>
                                <Option value="grams">grams</Option>
                                <Option value="ml">ml</Option>
                              </Select>
                            </div>
                            <div
                              className="mx-auto flex"
                              style={{ alignItems: "center", width: "95%" }}
                            >
                              <div
                                className="h-8 flex pl-3 outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "20%",
                                  alignItems: "center",
                                  fontSize: "12px",
                                }}
                              >
                                Days
                              </div>
                              <div className="flex" style={{ width: "80%" }}>
                                <button
                                  style={{
                                    marginLeft: "13px",
                                    marginRight: "13px",
                                  }}
                                  onClick={() => setDays(parseInt(days) - 1)}
                                >
                                  -
                                </button>
                                <Input
                                  name="days"
                                  type="text"
                                  style={{
                                    color: "black",
                                    borderRadius: "2px",
                                    marginBottom: "12px",
                                    marginLeft: "16px",
                                    marginTop: "11px",
                                    boxShadow:
                                      "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                    border:
                                      error && days.length === 0
                                        ? "1px solid red"
                                        : "none",
                                    fontSize: "12px !important",
                                  }}
                                  className=" h-8 outline-none px-5  text-black text-xs text-center font-normal "
                                  onChange={(e) => setDays(e.target.value)}
                                  value={days}
                                />
                                <button
                                  style={{ marginLeft: "13px" }}
                                  onClick={() => setDays(parseInt(days) + 1)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div
                              className="flex justify-between text-sm mx-auto"
                              style={{ fontSize: "11.5px", width: "95%" }}
                            >
                              <div
                                className="h-8 flex outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "45%",
                                }}
                              >
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="radio"
                                  value="1"
                                  name="food"
                                  onChange={(e) => setMed_food(e.target.value)}
                                  className="mr-1 ml-2 "
                                />
                                <p
                                  style={{ paddingTop: "7px" }}
                                  className="pt-1.5"
                                  id="evening_checkbox"
                                >
                                  Before Food
                                </p>
                              </div>

                              <div
                                className="h-8 flex outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "45%",
                                }}
                              >
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="radio"
                                  value="2"
                                  name="food"
                                  onChange={(e) => setMed_food(e.target.value)}
                                  className="mr-1 ml-2"
                                />
                                <p
                                  style={{ paddingTop: "7px" }}
                                  className="pt-1.5"
                                  id="evening_checkbox"
                                >
                                  After Food
                                </p>
                              </div>
                            </div>
                            <div
                              className="flex justify-between text-sm mx-auto"
                              style={{ fontSize: "12px", width: "95%" }}
                            >
                              <div
                                className="h-8 flex outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "45%",
                                }}
                              >
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="checkbox"
                                  value="1"
                                  name="morning"
                                  onChange={() => setIs_morning(!is_morning)}
                                  className="mr-1.5 ml-2 "
                                />
                                <p
                                  style={{ paddingTop: "7px" }}
                                  className="pt-1.5 pr-2"
                                  id="evening_checkbox"
                                >
                                  Morning
                                </p>
                              </div>
                              <div
                                className="h-8 flex outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "45%",
                                }}
                              >
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="Checkbox"
                                  value="2"
                                  name="noon"
                                  onChange={() => setIs_noon(!is_noon)}
                                  className="mr-1 ml-2"
                                />
                                <p
                                  style={{ paddingTop: "7px" }}
                                  className="pt-1.5 pr-1.5"
                                  id="evening_checkbox"
                                >
                                  Noon
                                </p>
                              </div>
                            </div>
                            <div
                              className="flex justify-between text-sm mx-auto"
                              style={{ maxWidth: "95%", fontSize: "12px" }}
                            >
                              <div
                                className="h-8 flex outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "45%",
                                }}
                              >
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="checkbox"
                                  value="1"
                                  name="evening"
                                  onChange={() => setIs_evening(!is_evening)}
                                  className="mr-1.5 ml-2 "
                                />
                                <p
                                  style={{ paddingTop: "7px" }}
                                  className="pt-1.5 pr-2"
                                  id="evening_checkbox"
                                >
                                  Evening
                                </p>
                              </div>
                              <div
                                className="h-8 flex outline-none text-black"
                                style={{
                                  boxShadow:
                                    " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                                  borderRadius: "2px",
                                  marginBottom: "12px",
                                  marginTop: "11px",
                                  width: "45%",
                                }}
                              >
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="Checkbox"
                                  value="2"
                                  name="night"
                                  onChange={() => setIs_night(!is_night)}
                                  className="mr-1 ml-2"
                                />
                                <p
                                  style={{ paddingTop: "7px" }}
                                  className="pt-1.5 pr-1.5"
                                  id="evening_checkbox"
                                >
                                  Night
                                </p>
                              </div>
                            </div>
                            <Input
                              type="text"
                              name="note"
                              style={{
                                color: "black",
                                borderRadius: "2px",
                                maxWidth: "95%",
                                marginBottom: "12px",
                                marginTop: "11px",
                                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                border:
                                  error && note.length === 0
                                    ? "1px solid red"
                                    : "none",
                                paddingLeft: "12px",
                                paddingright: "12px",
                                fontSize: "12px !important",
                              }}
                              className="h-8  outline-none text-black text-xs  font-normal "
                              onChange={(e) => setNote(e.target.value)}
                              value={note}
                              placeholder="Note"
                              autoComplete="off"
                            />
                          </>
                        )}

                        {add ? (
                          <div>
                            <Button
                              onClick={() =>
                                AddMedicine({
                                  dose: `${power} ${mg}`,
                                  power,
                                  mg,
                                  days,
                                  note,
                                  med_food,
                                  is_morning,
                                  is_noon,
                                  is_evening,
                                  is_night,
                                  medicine,
                                  med,
                                  quan: null,
                                  prescription: 3,
                                })
                              }
                              className=""
                              style={{
                                background: store.color,
                                borderRadius: "2px",
                                color: "white",
                                height: "33px",
                                width: "95%",
                                marginTop: "15px",
                              }}
                            >
                              Add
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button
                              onClick={() => refreshMedForm()}
                              className=""
                              style={{
                                background: store.color,
                                borderRadius: "2px",
                                color: "white",
                                height: "33px",
                                width: "95%",
                              }}
                            >
                              Add More...
                            </Button>
                          </div>
                        )}
                      </Form>
                      <div
                        className="h-20 mb-8 mx-auto"
                        style={{
                          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                          borderRadius: "2px",
                          marginTop: "15px",
                          width: "95%",
                          border:
                            error && diagnostics.length === 0
                              ? "1px solid red"
                              : "none",
                        }}
                      >
                        <textarea
                          style={{ width: "100%", borderRadius: "2px" }}
                          className="h-20 px-2"
                          value={diagnostics}
                          name="diagnostic"
                          onChange={(e) => setDiagnostics(e.target.value)}
                          placeholder="Diagnostic"
                        />
                      </div>

                      <div
                        className="mx-auto flex"
                        style={{
                          width: "95%",
                        }}
                      >
                        <div
                          className="h-8 flex pl-3 outline-none text-black mr-4"
                          style={{
                            boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                            borderRadius: "2px",
                            marginBottom: "12px",
                            marginTop: "11px",
                            width: "60%",
                            alignItems: "center",
                            fontSize: "12px",
                            border:
                              error && fp_req === null
                                ? "1px solid red"
                                : "none",
                          }}
                        >
                          Follow up Required
                        </div>
                        <div
                          className="h-8 flex px-4 outline-none text-black mr-1"
                          style={{
                            boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                            borderRadius: "2px",
                            marginBottom: "12px",
                            marginTop: "11px",
                            width: "20%",
                            alignItems: "center",
                            fontSize: "12px",
                            border: fp_req === true ? "1px solid blue" : "none",
                          }}
                          onClick={() => setFp_req(true)}
                        >
                          yes
                        </div>
                        <div
                          className="h-8 flex px-4 outline-none text-black"
                          style={{
                            boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                            borderRadius: "2px",
                            marginBottom: "12px",
                            marginTop: "11px",
                            width: "20%",
                            alignItems: "center",
                            fontSize: "12px",
                            border:
                              fp_req === false ? "1px solid blue" : "none",
                          }}
                          onClick={() => setFp_req(false)}
                        >
                          No
                        </div>
                      </div>
                      {fp_req && (
                        <div
                          className="mx-auto"
                          style={{
                            fontSize: "10px",
                            textAlign: "left",
                            width: "95%",
                          }}
                        >
                          <p>Follow up required in:</p>
                        </div>
                      )}
                      {fp_req && (
                        <div
                          className="mx-auto flex"
                          style={{ maxWidth: "95%", alignItems: "center" }}
                        >
                          <div
                            className="h-8 flex px-3 outline-none text-black"
                            style={{
                              boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                              borderRadius: "2px",
                              marginBottom: "12px",
                              marginTop: "11px",
                              alignItems: "center",
                              fontSize: "12px",
                              width: "20%",
                            }}
                          >
                            Days
                          </div>
                          <div className="flex" style={{ width: "80%" }}>
                            <button
                              style={{
                                marginLeft: "13px",
                                marginRight: "13px",
                              }}
                              onClick={() => setFp_days(parseInt(fp_days) - 1)}
                            >
                              -
                            </button>
                            <Input
                              name="fp_days"
                              type="text"
                              style={{
                                color: "black",
                                borderRadius: "2px",
                                marginBottom: "12px",
                                marginTop: "11px",
                                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                                border:
                                  error && fp_days.length === 0
                                    ? "1px solid red"
                                    : "none",
                                fontSize: "12px !important",
                              }}
                              className=" h-8 outline-none text-black text-xs text-center font-normal "
                              onChange={(e) => setFp_days(e.target.value)}
                              value={fp_days}
                            />
                            <button
                              style={{ marginLeft: "13px" }}
                              onClick={() => setFp_days(parseInt(fp_days) + 1)}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      )}

                      <div
                        className="mx-auto flex"
                        style={{
                          width: "95%",
                        }}
                      >
                        <div
                          className="h-8 flex pl-3 outline-none text-black mr-4"
                          style={{
                            boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                            borderRadius: "2px",
                            marginBottom: "12px",
                            marginTop: "11px",
                            width: "60%",
                            alignItems: "center",
                            fontSize: "12px",
                            border:
                              error && hosp_req === null
                                ? "1px solid red"
                                : "none",
                          }}
                        >
                          Hospitalisation Required
                        </div>
                        <div
                          className="h-8 flex px-4 outline-none text-black mr-1"
                          style={{
                            boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                            borderRadius: "2px",
                            marginBottom: "12px",
                            marginTop: "11px",
                            width: "20%",
                            alignItems: "center",
                            fontSize: "12px",
                            border:
                              hosp_req === true ? "1px solid blue" : "none",
                          }}
                          onClick={() => setHosp_req(true)}
                        >
                          yes
                        </div>
                        <div
                          className="h-8 flex px-4 outline-none text-black"
                          style={{
                            boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                            borderRadius: "2px",
                            marginBottom: "12px",
                            marginTop: "11px",
                            width: "20%",
                            alignItems: "center",
                            fontSize: "12px",
                            border:
                              hosp_req === false ? "1px solid blue" : "none",
                          }}
                          onClick={() => setHosp_req(false)}
                        >
                          No
                        </div>
                      </div>

                      <div
                        className="flex justify-between -ml-4 cursor-pointer "
                        style={{
                          width: "340px",
                          marginBottom: "27px",
                        }}
                      ></div>
                    </div>

                    <div
                      className="mx-auto flex justify-between cursor-pointer "
                      // id="save_a_copy_btn"
                      style={{
                        width: "95%",
                       
                        marginBottom: "27px",
                      }}
                    >
                      <p
                        className="save_a_copy_btn"
                       
                      >
                        Save a copy
                      </p>
                      <img
                        onClick={() => handleSubmit()}
                        className="w-8 mb-1 mr-4 cursor-pointer "
                        id="prescription_next_btn"
                        src={Group105}
                        alt=""
                      />
                    </div>

                    <div className="h-5"></div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default DoctorChat;
