import React from 'react'
// import whiteShare from '../Assets/whiteShare.svg';
import blackShare from '../Assets/blackShare.svg';
import { Colors, HexColors } from '../Utils/colors';
import { inject, observer } from "mobx-react";
import { useHistory } from 'react-router';
const PrescriptionCard = inject("store")(observer(({ store, obj }) => {
    const data = obj;
    const history = useHistory()
    return (
        <div style={{ width: "95%" }} className="mx-auto px-2">
            <div className="rounded-xl h-40 mt-6" style={{ width: "100%", backgroundColor: store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic), alignItems: "center" }}>
            </div>
            <div onClick={() => history.push("/prescriptioncontents", {data:data})} className="rounded-xl h-32 -mt-24" style={{ 
                backgroundColor: "#FFFFFF",
                border: "1px solid #DADADA",
                borderRadius: "0px 12px 12px 12px" }}>
                <img className="pt-2 pl-5" src={blackShare} alt="" />
                <p className="pl-4 pt-12" >{obj ? obj.chat__created_at.substring(0, 10).split("-").reverse().join("-") : "NA"}</p>
            </div>
        </div>
    )
}))

export default PrescriptionCard
