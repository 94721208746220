import React from 'react'
import { inject, observer } from "mobx-react";
import './styles/screen4.css'
import { Button } from "antd";

const Payemnt = inject("store")(
    observer(({ store, data }) => {

        return (
            <div>
                <div>
                    <div style={{
                        fontSize: "20px",
                        lineHeight: "23px",
                        color: "#797979"
                    }}>Billing details</div>
                    <div className="flex mt-4 justify-between">
                        <div style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                        }}>Consultation fee</div>
                        <div style={{
                            fontSize: "16px",
                            lineHeight: "24px"
                        }}>₹ 900</div>
                    </div>
                    <div className="mt-2">
                        <div style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                            marginRight: "10vw"
                        }}>Booking fee</div>
                    </div>
                    <hr style={{ border: "1px solid rgba(0, 0, 0, 0.14)", marginTop: "5%" }}></hr>
                    <div className="flex mt-4 justify-between">
                        <div style={{
                            fontSize: "16px",
                            lineHeight: "24px",
                        }}>Total</div>
                        <div style={{
                            fontSize: "16px",
                            lineHeight: "24px"
                        }}>₹ 900</div>
                    </div>
                    <div>
                        <Button
                            // onClick={() => handleSubmit()}
                            className="next text-xl websites"
                            style={{
                                marginTop: "20%",
                                background: store.color,
                                borderRadius: "9px",
                                color: "white",
                                height: "50px", width: "26.3vw"
                            }}
                        >
                            Pay
                        </Button>
                    </div>
                </div>

            </div>
        );
    })
);

export default Payemnt