export const Colors = {
    allopathic : "linear-gradient(180deg, #929BFF 0%, #717EF0 98.75%)",
    homeopathic : "linear-gradient(180deg, #72BBFF 0%, #63A4FF 100%)",
    ayurvedic : "linear-gradient(165.01deg, #48BFD9 -0.79%, #4ED2AB 158.6%)",
}

export const HexColors = {
    allopathic: "#929BFF",
    homeopathic: "#63A4FF",
    ayurvedic: "#48BFD9",
} 