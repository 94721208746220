import React from "react";
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import Search from "../../Assets/Search.png";
import { inject, observer } from "mobx-react";
import Sidebar from "../Headers/sidebar";
import { useHistory } from "react-router-dom";
import "./styles/screen2.css";
import BookAppointmentCard from "./BookAppointmentCard";
import AppointmentHeader from "../Headers/WebHeader";

const Screen2 = inject("store")(
  observer(({ store, data }) => {
    const history = useHistory();

    return (
      <div className="overflowX-hidden">
        <div className="mobile">
          <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
            <img className=" text-black " src={leftarrow_black} alt="" />
          </div>
        </div>
        <div className="webView">
          <div className="websites">
            <Sidebar />
          </div>
          <div className="web webView2">
            <div>
              <AppointmentHeader />
            </div>
            <div
              className="search-appoint flex justify-between mx-auto py-2 rounded-2xl mt-10"
              style={{ boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)" }}
            >
              <input
                type="text"
                // value={search}
                // onChange={(e) => OnSearch(e.target.value)}
                className=" text-left py-1 px-6 search"
                style={{ alignItems: "center", width: "85%" }}
                placeholder="Search Doctor"
              />
              <button className="" style={{ width: "10%" }}>
                <img style={{ height: "60%" }} src={Search} alt="" />
              </button>
            </div>
            <div
              className="mobile font-medium text-lg leading-7"
              style={{ margin: "5% 9%" }}
            >
              Results
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-2 grid-cols-1">
              <BookAppointmentCard />
              <BookAppointmentCard />
              <BookAppointmentCard />
              <BookAppointmentCard />
            </div>
            <div className="h-10"></div>
          </div>
        </div>
      </div>
    );
  })
);

export default Screen2;
