import React, { useState, useEffect } from "react";
// import searchIcon from "../Assets/searchIcon.svg";
import Search from "../../Assets/Search.png";
import Sidebar from "../Headers/sidebar";
import WebHeader from '../Headers/WebHeader'
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import { useLocation } from 'react-router-dom'
// import Header from "../Components/Header";
import { inject, observer } from "mobx-react";
import InfoCards from "./InfoCards";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Skeleton } from "antd";
import "./style/Homebuttons.css";
import '../../Styles/placeholder.css'

const Ambulance = inject("store")(
  observer(({ store, otherProp }) => {
    const { state } = useLocation();
    const { user } = state;
    console.log({ user });
    const history = useHistory();
    //const patient = user;
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [search, setSearch] = useState("");
    const [searchEnable, setSearchEnable] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    useEffect(() => {
      axios
        .get("api/doc/ambulance/?pincode=110059", {
          // pharma to hospital
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          setData(res.data.data);
          setShowSkeleton(false);
        });
    }, []);

    const OnSearch = async (search) => {
      setSearch(search);
      setSearchEnable(true);
      if (search !== "") {
        try {
          let filterItems = (arr, query) => {
            return arr.filter((item) => {
              let hospital = item.name.toLowerCase().indexOf(query) !== -1;
              return hospital;
            });
          };

          let result = await filterItems(data, search.toLowerCase());
          await setSearchData(result);
        } catch (e) {
          console.log("e2", e);
        }
      }
    };

    return (
      <div className="overflowX-hidden">
        <div className="mobile">
          <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
            <img className=" text-black " src={leftarrow_black} alt="" />
          </div>
        </div>
        <div className="webView">
          <div className="websites">
            <Sidebar />
          </div>
          <div className="web">
            <div>
              <WebHeader />
            </div>
            <div className="ambulance-text">Ambulance near you</div>
            <div
              className="search-appoint flex justify-between mx-auto py-2 rounded-2xl mt-10"
              style={{ boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)" }}
            >

              <input
                style={{ borderRadius: "17px" }}
                type="text"
                value={search}
                onChange={(e) => OnSearch(e.target.value)}
                className=" pl-4 outline-none search"
                placeholder="Search Ambulance"
              />
              <button className="" style={{ width: "10%" }}>
                <img style={{ height: "60%" }} src={Search} alt="" />
              </button>
            </div>

            {
              showSkeleton ?
                <div className="p-8">
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </div> : null
            }

            <div className="lg:flex items-center justify-center">
              {searchEnable && search !== ""
                ? searchData.map((data, index) => (
                  <InfoCards key={index} data={data} />
                ))
                : data.map((data, index) => <InfoCards key={index} data={data} />)}
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default Ambulance;
