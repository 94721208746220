import { inject, observer } from "mobx-react";
import React from "react";
import calllIcon from "../../Assets/callIcon.svg";
import { Colors, HexColors } from "../../Utils/colors";

const InfoCards = inject("store")(
  observer(({ store, data }) => {
    return (
      <div className="info-cards">
        <div>
          <div className=" pb-0 pt-4 px-10 ">
            <h2 className=" font-semibold " style={{ color: "#585555", fontSize: "15px" }}>
              {data.name}
            </h2>
            <div style={{ color: "#6A6464", fontSize: "12px" }}>{data.address}</div>
            <div
              className=" flex  justify-between pt-2 "
              style={{ alignItems: "center" }}
            >
              <div style={{ color: "#9D9D9D", fontSize: "12px" }}>Contact : {data.mobile} </div>

              <a href={`tel:${data.mobile}`}>
                <div
                  style={{
                    alignItems: " center",
                    backgroundColor:  store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic) 
                  }}
                  className=" w-16 flex h-7 justify-center rounded-lg xl:w-20 xl:h-8"
                >
                  <div><img src={calllIcon} alt=""/></div>
                  <div className=" font-semibold text-white call">Call</div>
                </div>
              </a>
            </div>
          </div>

          <hr
            className=" mt-4 mr-5 ml-8 "
            style={{ border: "1px solid rgba(146, 155, 255, 0.3)" }}
          />
        </div>
      </div>
    );

  })
);
export default InfoCards;
