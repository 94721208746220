import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, ...rest }) => {
  var path = "";
  if (localStorage.user_type === "1") {
    path = "/home";
  } else if (localStorage.user_type === "2") {
    path = "/doctorhome";
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          localStorage.access === undefined &&
          localStorage.refresh === undefined
        ) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: path,
              }}
            />
          );
        }
      }}
    />
  );
};

export default AuthRoute;
