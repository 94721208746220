import React from "react";
import { useHistory } from "react-router-dom";
import leftarrow_black from "../Assets/leftarrow_black.svg";
import Patient from "../Assets/Patient2.png";
import Doctor from "../Assets/Doctor.png";
import Volunteer from "../Assets/Volunteer.png";
import "../Styles/foundDoctor.css";

function Selection() {
  const history = useHistory();
  return (
    <div className="p-4">
      <div onClick={() => history.goBack()} className="pt-5 ">
        <img className=" text-black " src={leftarrow_black} alt="" />
      </div>
      {/* <WebHeader /> */}
      <div className="mt-12 font-medium md:text-center text-3xl choose-head">Choose</div>
      <div className="comm-div flex">
        <div
          onClick={() => {
            localStorage.setItem("user_type", 1);
            history.push("/medicinetype");
          }}
          className="selection-div flex"
        >
          <div style={{ width: "60%" }}>
            <img className="selection-images" src={Patient} alt="" />
          </div>
          <p className="comm-text">Patient</p>
        </div>
        <div
          onClick={() => {
            localStorage.setItem("user_type", 2);
            history.push("/doctorform");
          }}
          className="selection-div flex"
        >
          <div style={{ width: "55%" }}>
            <img className="selection-images" src={Doctor} alt="" />
          </div>
          <p className="comm-text comm-text-doctor">
            Doctor/
            <br />
            Paramedic
          </p>
        </div>
        <div
          onClick={() => {
            localStorage.setItem("user_type", 3);
            history.push("/doctorhome");
          }}
          className="selection-div flex"
        >
          <div style={{ width: "58%" }}>
            <img className="selection-images" src={Volunteer} alt="" />
          </div>
          <p className="comm-text">
            Volunteer
          </p>
        </div>
      </div>
    </div>
  );
}

export default Selection;
