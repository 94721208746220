import React from "react";
import { inject, observer } from "mobx-react";
// import { Colors } from "../../Utils/colors";
import Sidebar from "../Headers/sidebar";
import WebHeader from "../Headers/WebHeader";
import "./styles/appoinment_form.css";
import avatar from "../../Assets/form/avatar.svg";
import wp_pin from "../../Assets/form/wp_pin.svg";
import calendar from "../../Assets/form/calendar.svg";
import { DatePicker, Input } from "antd";
import leftarrow_black from "../../Assets/leftarrow_black.svg";

const AppoinmentForm = inject("store")(
  observer(({ store, user }) => {
    return (
      <div className="main_appoinment_div">
        <div className="seidebar">
          <Sidebar />
        </div>
        <div className="main_appoinment_from">
          <WebHeader />
          <img src={leftarrow_black} alt="" className="left_arrow_back"/>
          <h1 className="appoinment_from_heading">
            kindly enter the following details
          </h1>
          <form className="appoinment_form">
            <div className="first_form_section">
              <div
                className="flex mx-auto my-3"
                id="name_input"
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={avatar} alt="" />
                </div>
                <Input
                  name="wp"
                  style={{
                    color: "black",
                    width: "22.25rem",

                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  maxLength="30"
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  placeholder="Patient Name"
                />
              </div>
              <div className="radio_btn">
                <div
                  className=" h-8 flex outline-none px-2 my-2 text-black"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <input
                    style={{ marginTop: "10px" }}
                    type="radio"
                    value="2"
                    name="gender"
                    className=""
                  />
                  <p id="radio_para">
                    Female
                  </p>
                </div>
                <div
                  className=" h-8 flex outline-none px-2 my-2 text-black"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                    // marginRight: "13px",
                    // marginLeft: "8px",
                  }}
                >
                  <input
                    style={{ marginTop: "10px" }}
                    type="radio"
                    value="2"
                    name="gender"
                    // className="mr-1 ml-2"
                  />
                  <p id="radio_para">
                    Male
                  </p>
                </div>
                <div
                  className=" h-8 flex outline-none px-2 my-2 text-black"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                    // marginRight: "13px",
                    // marginLeft: "8px",
                  }}
                >
                  <input
                    style={{ marginTop: "10px" }}
                    type="radio"
                    value="2"
                    name="gender"
                    // className="mr-1 ml-2"
                  />
                  <p id="radio_para">
                    Others
                  </p>
                </div>
              </div>
            </div>
            <div className="second_form_section">
              <div
                style={{ display: "flex", alignItems: "center", gap: "1vw" }}
                id="state_wp"
              >
                <div
                  className="flex mx-auto my-3"
                  id="appoinment_pin"
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={wp_pin} alt="" />
                  </div>
                  <Input
                    name="wp"
                    id="appoinment_state"
                    maxLength="30"
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    placeholder="State"
                  />
                </div>
                <div
                  className="flex mx-auto my-3"
                  
                  id="appoinmet_state"
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={avatar} alt="" />
                  </div>
                  <Input
                    name="wp"
                    style={{
                      color: "black",
                      width: "18.25rem",

                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    maxLength="30"
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    placeholder="Pincode"
                  />
                </div>
              </div>

              <div
                className="flex mx-auto my-3"
                id="appoinment_date"
                
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                    marginLeft:"-1vw"
                  }}
                >
                  <img className=" mx-auto my-2" src={calendar} alt="" />
                </div>
                <DatePicker
                  className="mx-auto h-8 outline-none px-5  text-black"
                  dateFormat="dd/MM/yyyy"
                  type="number"
                  disabledDate={(d) => !d || d.isAfter(new Date())}
                  bordered={false}
                  
                  style={{
                    color: "black",
                    width: "22.25rem",
                    
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  
                  placeholder={"Date of Appoinment"}
                />
              </div>
            </div>
          </form>
          <button className="appoinment_next_btn">NEXT</button>
        </div>
      </div>
    );
  })
);

export default AppoinmentForm;
