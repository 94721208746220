import React from "react";
import Sidebar from "../Headers/sidebar";
import search_icon from "../../Assets/search_icon.svg";
import symptoms from "../../Assets/symptoms.svg";
import Reactangle from "../../Assets/Rectangle.svg";
import "./styles/symptom.css";

const SymptomSearch = () => {
  return (
    <div className="main_div">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content_div">
        <div className="under_heder">
          <div className="search_div">
            <input className="search" placeholder="Search Keywords" />
            <img src={search_icon} alt="" className="search_icon" />
          </div>
          <div className="keywords">
            <p className="plus">+</p>
            <p className="key">Type Keywords</p>
            <p className="key">Lorem</p>
          </div>
        </div>
        <div className="symptom_second_main_div">
          <div className="search_by_symptoms">
            <p className="hading1">Search by Symptoms</p>
            <div className="symptoms_icons">
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
              <img src={symptoms} alt="" className="symptoms" />
            </div>
          </div>
          <div className="search_by_speciality">
            <p className="hading2">Search by Speciality</p>
            <div className="serach_speciality">
              <div className="box_div">
                <div className="category">
                  <p className="related">Torso Related</p>
                  <p id="view">View More</p>
                </div>
                <div className="card_div">
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />

                </div>
              </div>
            </div>
            <div className="serach_speciality">
              <div className="box_div">
                <div className="category">
                  <p className="related">Nerve Related</p>
                  <p id="view">View More</p>
                </div>
                <div className="card_div">
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                </div>
              </div>
            </div>
            <div className="serach_speciality">
              <div className="box_div">
                <div className="category">
                  <p className="related">Nerve Related</p>
                  <p id="view">View More</p>
                </div>
                <div className="card_div">
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                </div>
              </div>
            </div>
            <div className="serach_speciality">
              <div className="box_div">
                <div className="category">
                  <p className="related">Nerve Related</p>
                  <p id="view">View More</p>
                </div>
                <div className="card_div">
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                  <img src={Reactangle} alt="" className="card_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SymptomSearch;
