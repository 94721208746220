import React from "react";
import "./styles/bookdoctor.css";
import Sidebar from "../Headers/sidebar";
import WebHeader from "../Headers/WebHeader";
import search_icon from "../../Assets/search_icon.svg";
import DoctorCard from "../Headers/DoctorCard";
import { Calendar } from "antd";
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import { inject, observer } from "mobx-react";
import { Colors, } from "../../Utils/colors";

const BookDoctor = inject("store")(
  observer(({ store, user }) => {
    return (
      <div className="main_book_doctor">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="book_doctor_div">
          <WebHeader />
          <img src={leftarrow_black} id="goback_btn" alt="" />
          <div className="booking_div">
            <div className="bookdoctor_under_heder">
              <div className="search_div">
                <input className="search" placeholder="Search Keywords" />
                <img src={search_icon} alt="" className="search_icon" />
              </div>
              <div className="keywords">
                <p className="plus">+</p>
                <p className="key">Type Keywords</p>
                <p className="key">Lorem</p>
              </div>
            </div>
          </div>
          <div className="doctor_booking_details">
            <div className="appoinment">
              <DoctorCard />
              <button className="next_btn">NEXT</button>
            </div>
            <div
              className="calender"
              style={{
                backgroundColor:
                  store.color === Colors.allopathic
                    ? "#F5F5FF"
                    : store.color === Colors.homeopathic
                    ? "#EFF6FF"
                    : "#EFFCFF",
              }}
            >
              <Calendar fullscreen={false}  />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default BookDoctor;
