import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import PrescriptionCard from "../Components/PrescriptionCard";
import { useHistory } from "react-router-dom";
import leftarrow_black from "../Assets/leftarrow_black.svg";
import { inject, observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Skeleton } from "antd";
import Sidebar from "../Components/Headers/sidebar";
import "../Styles/prescription.css";

const Prescription = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const { state } = useLocation();
    const { user } = state;
    console.log({ user });
    const [data, setData] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [searchData, setSearchData] = useState([]);
    const [search, setSearch] = useState("");
    const [searchEnable, setSearchEnable] = useState(false);

    useEffect(() => {
      axios
        .get("api/pat/prescription-list/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.data) {
            setData(res.data.data);
            setShowSkeleton(false);
          }
        })
        .catch(() => {
          console.log("prescription not get");
        });
    }, []);

    console.log(data);

    const OnSearch = async (search) => {
      setSearch(search);
      setSearchEnable(true);
      if (search !== "") {
        try {
          let filterItems = (arr, query) => {
            return arr.filter((item) => {
              let hospital =
                item.chat__created_at
                  .substring(0, 10)
                  .split("-")
                  .reverse()
                  .join("-")
                  .indexOf(query) !== -1;
              return hospital;
            });
          };

          let result = await filterItems(data, search);
          await setSearchData(result);
        } catch (e) {
          console.log("e2", e);
        }
      }
    };

    console.log(searchData);

    return (
      <div>
        {/* <Header user={user} /> */}
        <div className="mobile">
          <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
            <img className=" text-black " src={leftarrow_black} alt="" />
          </div>
        </div>
        <div className="flex">
          <div className="websites">
            <Sidebar />
          </div>

          <div className="web mx-auto">
            <div className="heading-presc">
              <h1 className="font-normal text-2xl mt-4 md:text-center ml-8 sm:text-5xl">
                My Prescriptions
              </h1>
            </div>

            <div
              className="search-presc flex justify-between  mx-auto py-2 rounded-2xl mt-10"
              style={{ boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)" }}
            >
              <input
                type="text"
                value={search}
                onChange={(e) => OnSearch(e.target.value)}
                className=" text-left py-1 px-6"
                style={{ alignItems: "center", width: "85%" }}
                placeholder="Search by Name"
              />
              <button className="" style={{ width: "10%" }}>
                <SearchOutlined />
              </button>
            </div>

            <div>
              <h1 className="mobile font-normal text-2xl mt-4 md:text-center ml-8 sm:text-5xl">
                My Prescriptions
              </h1>
            </div>
            {showSkeleton ? (
              <div className="p-8">
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div>
            ) : null}

            <div className="prescription-grid mx-auto grid md:grid-cols-4 lg:grid-cols-4 grid-cols-2">
              {searchEnable && search !== ""
                ? searchData.map((obj) => (
                    <div
                      style={{
                        gridColumnStart: obj[0] ? "1" : "",
                        gridColumnEnd: obj[0] ? "1" : "",
                      }}
                    >
                      <PrescriptionCard key={obj.id} obj={obj} />
                    </div>
                  ))
                : data.map((obj) => (
                    <div
                      style={{
                        gridColumnStart: obj[0] ? "1" : "",
                        gridColumnEnd: obj[0] ? "1" : "",
                      }}
                    >
                      <PrescriptionCard key={obj.id} obj={obj} />
                    </div>
                  ))}
            </div>
            {searchEnable && searchData.length === 0 && (
              <div
                className="mx-auto text-center mt-10"
                style={{ width: "95%", fontSize: "16px" }}
              >
                No result found
              </div>
            )}

            {data.length === 0 && data !== "" && (
              <div
                className="mx-auto text-center mt-10 sm:text-center"
                style={{ width: "95%", fontSize: "30px" }}
              >
                No result found
              </div>
            )}
            <div className="h-24"></div>
          </div>
        </div>
      </div>
    );
  })
);

export default Prescription;
