import React from 'react'
import "./style/home.css";
import { Colors, HexColors } from "../../Utils/colors";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { message } from "antd";

const BookDoctorCard = inject("store")(
    observer(({ store, data }) => {
        const history = useHistory();
    return (
        <div className="md:flex mt-16 justify-around Book">
        <div
          className="flex mx-3 mt-16"
          style={{
            borderRadius: "10px",
            alignItems: "center",
            boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.03)",
          }}
        >
          <div className="w-20 h-16 m-1" style={{ borderRadius: "10px" }}>
            <svg
              style={{ marginTop: "-71px", marginLeft: "-26px" }}
              className="-ml-6"
              width="152"
              height="164"
              viewBox="0 0 152 164"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.6414 37.811C36.7534 30.6251 43.949 21.5394 48.4768 16.8912C49.278 16.0687 50.1647 15.3238 51.2008 14.8289C53.8305 13.5728 56.8748 13.2033 59.4888 13.2316C62.6864 13.2663 66.0215 14.1126 68.3463 16.3081C70.5495 18.3888 73.6663 21.9765 77.8405 27.8954C88.3373 42.7796 73.7511 45.3046 71.3343 46.0323C69.4009 46.6144 58.3019 46.845 52.994 46.8876C47.492 47.1442 36.5186 45.6881 36.6414 37.811Z"
                fill="#030406"
                stroke="#050505"
              />
              <path
                d="M113.431 93.2839C114.653 91.6411 117.249 87.903 117.848 86.0931C118.597 83.8308 119.344 80.8149 120.541 81.2999C121.739 81.7849 122.499 84.7976 121.354 87.903C123.255 85.5495 128.163 80.1752 128.605 79.1547C129.046 78.1342 131.481 76.6661 131.575 79.3256C131.869 78.6452 132.786 77.6296 134.101 79.0098C134.713 79.6516 134.665 80.6649 134.335 81.7038C135.012 81.5578 136.323 81.7445 136.15 83.6598C136.072 84.5267 135.593 85.6153 134.928 86.7463C135.943 86.8096 137.692 87.4687 136.562 89.5989C135.149 92.2616 128.206 99.7681 118.593 102.848C117.559 101.297 116.409 99.631 115.613 98.1552C114.464 96.0251 113.825 94.2344 113.431 93.2839Z"
                fill="#FFC9C8"
              />
              <path
                d="M131.575 79.3256C131.481 76.6661 129.046 78.1342 128.605 79.1547C128.163 80.1752 123.255 85.5495 121.354 87.903C122.499 84.7976 121.739 81.7849 120.541 81.2999C119.344 80.8149 118.597 83.8308 117.848 86.0931C117.249 87.903 114.653 91.6411 113.431 93.2839C113.825 94.2344 114.464 96.0251 115.613 98.1552C116.409 99.631 117.559 101.297 118.593 102.848C128.206 99.7681 135.149 92.2616 136.562 89.5989C137.692 87.4687 135.943 86.8096 134.928 86.7463M131.575 79.3256C131.62 80.5962 130.635 82.3014 129.533 83.7684M131.575 79.3256C131.869 78.6452 132.786 77.6296 134.101 79.0098C134.713 79.6516 134.665 80.6649 134.335 81.7038M129.533 83.7684C128.79 84.7577 127.993 85.6388 127.423 86.2054L129.533 83.7684ZM129.998 87.973C130.564 87.5107 132.002 85.7079 132.651 84.8643C133.203 84.1279 133.955 82.8989 134.335 81.7038M134.335 81.7038C135.012 81.5578 136.323 81.7445 136.15 83.6598C136.072 84.5267 135.593 85.6153 134.928 86.7463M133.4 89.0282C132.508 90.235 131.565 91.349 130.849 92.138C129.399 93.7369 131.945 90.731 133.4 89.0282ZM133.4 89.0282C133.95 88.2847 134.481 87.5059 134.928 86.7463"
                stroke="#FF9290"
              />
              <path
                d="M68.9188 66.9762L46.2627 64.9418L49.8811 139.272L69.0753 139.319L68.9188 66.9762Z"
                fill="white"
              />
              <path
                d="M25.9459 140.122L27.0722 127.974L21.6197 127.941L16.1657 127.155L11.3301 125.393L7.59965 122.209L6.08435 118.445L6.12014 114.499L6.99739 110.198L8.27341 105.806L13.2164 95.7331L20.3305 85.2563L28.1104 75.1326L37.2672 66.2915L48.5594 60.992L49.8436 80.8926L51.6145 100.349L54.7576 139.351L25.9459 140.122Z"
                fill={
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                }
                stroke="#14BFFA"
              />
              <path
                d="M121.314 90.3408C121.436 91.8773 121.52 95.2874 120.885 96.6363"
                stroke="#FF9290"
              />
              <path
                d="M126.538 95.0315C126.856 94.6192 127.14 93.3672 125.734 91.6581C124.329 89.9491 123.139 89.3256 122.719 89.2275"
                stroke="#FF9290"
              />
              <path
                d="M63.7205 61.3579C66.6029 61.8836 72.7321 63.2889 74.1891 64.7042C76.0544 66.296 82.4459 70.1387 90.4114 84.3508C96.7838 95.7205 103.689 95.3598 106.346 93.7582L112.81 90.4639C114.25 93.5195 118.054 100.613 121.751 104.541C118.081 108.716 110.841 122.739 89.1046 115.472C89.1278 115.766 89.2114 116.668 89.3354 117.977C89.5624 120.372 89.9249 124.128 90.3012 128.007C90.6876 131.991 91.0885 136.105 91.3719 139.005C87.3903 139.042 81.3611 139.097 75.8973 139.145C69.9659 139.197 64.7009 139.24 63.4457 139.243C63.7933 136.015 63.7737 85.9747 63.7205 61.3579Z"
                fill={
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                }
              />
              <path
                d="M26.3788 132.509C26.0904 135.207 25.8715 138.29 25.7686 140.078C29.5263 140.018 35.063 139.928 40.3262 139.84C40.4346 138.135 40.5093 134.691 39.9416 134.551C39.232 134.375 40.9149 133.662 35.6855 134.25C31.5019 134.72 27.7379 133.285 26.3788 132.509Z"
                fill={
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                }
              />
              <path
                d="M84.5863 96.465C85.8568 101.989 88.5852 114.025 89.3354 117.977M89.3354 117.977C89.2114 116.668 89.1278 115.766 89.1046 115.472C110.841 122.739 118.081 108.716 121.751 104.541C118.054 100.613 114.25 93.5195 112.81 90.4639L106.346 93.7582C103.689 95.3598 96.7838 95.7205 90.4114 84.3508C82.4459 70.1387 76.0544 66.296 74.1891 64.7042C72.7321 63.2889 66.6029 61.8836 63.7205 61.3579C63.7737 85.9747 63.7933 136.015 63.4457 139.243C64.7009 139.24 69.9659 139.197 75.8973 139.145M89.3354 117.977C89.5624 120.372 89.9249 124.128 90.3012 128.007M48.8247 60.6809C49.0337 68.4526 49.6829 84.4301 50.6087 86.1657C51.9911 103.643 54.783 138.792 54.8911 139.572C53.3579 139.615 47.0662 139.726 40.3262 139.84M27.0283 128.151C26.7904 129.111 26.5695 130.726 26.3788 132.509M26.3788 132.509C26.0904 135.207 25.8715 138.29 25.7686 140.078C29.5263 140.018 35.063 139.928 40.3262 139.84M26.3788 132.509C27.7379 133.285 31.5019 134.72 35.6855 134.25C40.9149 133.662 39.232 134.375 39.9416 134.551C40.5093 134.691 40.4346 138.135 40.3262 139.84M75.8973 139.145C81.3611 139.097 87.3903 139.042 91.3719 139.005C91.0885 136.105 90.6876 131.991 90.3012 128.007M75.8973 139.145C75.6736 137.514 75.4388 134.155 76.289 133.763C77.3518 133.273 83.5578 133.304 84.1779 133.081C84.798 132.858 88.8312 132.539 89.8924 131.295C90.3108 129.606 90.3392 128.4 90.3012 128.007"
                stroke="#00A5E9"
              />
              <rect
                x="47.0967"
                y="24.2795"
                width="23.3983"
                height="18.6425"
                transform="rotate(-3.35279 47.0967 24.2795)"
                fill="#FFB4B4"
              />
              <path
                d="M43.918 107.857L46.3073 105.813L48.5632 103.547L49.9336 101.815L50.8206 102.035L51.4424 102.565L51.2243 104.206L50.5635 106.114L54.9026 103.799L56.8509 102.775L60.1272 100.951L61.369 101.258L61.7694 101.923L61.6392 103.208L63.9 103.203L64.0123 103.89L63.8153 105.065L62.0022 107.064L63.7307 106.927L64.3524 107.458L64.0448 108.7L62.0103 110.832L63.9163 110.739L64.1848 111.935L62.2838 114.289L56.4847 117.936L49.8848 120.256L46.3399 120.884L43.3696 120.713L43.1336 114.064L43.918 107.857Z"
                fill="#FFC9C8"
              />
              <path
                d="M61.1825 103.531C61.9649 103.159 63.9996 102.496 64.109 103.879C64.2183 105.262 61.6784 107.356 60.3948 108.231C58.9783 109.135 56.0742 110.927 55.7904 110.856"
                stroke="#EA8989"
              />
              <path
                d="M61.6042 107.362C62.3481 107.04 64.2874 106.475 64.4135 107.72C64.5397 108.966 62.1384 110.811 60.922 111.578C59.5787 112.37 56.8239 113.937 56.5508 113.869"
                stroke="#EA8989"
              />
              <path
                d="M61.7719 111.13C62.437 110.81 64.1662 110.238 64.2565 111.422C64.3468 112.607 62.1858 114.405 61.094 115.157C59.8893 115.934 51.3812 121.815 43.3556 120.768"
                stroke="#EA8989"
              />
              <path
                d="M43.8603 108.09C45.4678 106.668 48.8241 103.558 49.3892 102.492C49.9543 101.427 50.8953 101.987 51.2951 102.4C51.3711 103.36 51.2841 105.485 50.3284 106.303C52.7372 105.092 57.2944 102.505 59.272 101.363C60.4128 100.704 63.0838 101.177 61.1828 103.531C58.1294 105.975 55.3853 107.932 53.9677 108.334"
                stroke="#EA8989"
              />
              <path
                d="M48.6032 60.8145C45.5031 62.1806 38.7986 65.2774 36.7804 66.7357C34.7621 68.194 30.0118 73.0302 27.8889 75.266C22.1994 81.7649 10.1173 97.6015 7.30484 108.956C6.42595 112.504 1.9257 122.31 14.214 126.672C24.0447 130.161 38.2828 126.044 44.173 123.548C43.4422 120.417 42.3768 112.86 43.9614 107.679C41.685 107.908 33.438 108.527 28.9808 107.345M26.9368 106.475C27.4418 106.838 28.1446 107.123 28.9808 107.345M28.9808 107.345C29.1838 106.019 29.7222 103.136 30.2522 102.212M39.085 66.5534L42.4021 83.5682C42.7873 84.0402 44.0362 84.8768 45.9502 84.4471C47.8643 84.0174 45.6866 85.5116 44.3584 86.3123C44.0633 86.4903 43.6687 87.1206 44.4512 88.2182"
                stroke="#00A5E9"
              />
              <path
                d="M65.7861 103.3C64.4466 98.8258 63.3964 88.7745 69.9119 84.363C76.4275 79.9516 77.9956 91.7631 77.9653 98.2203"
                stroke="#4F45BD"
              />
              <path
                d="M44.4053 89.6566C42.9258 81.9467 41.5514 65.4507 47.8899 61.146C54.2283 56.8414 61.3794 60.6588 64.1626 63.1056C66.3837 65.2877 70.8055 72.4711 70.7235 83.7483"
                stroke="#4F45BD"
              />
              <path
                d="M48.8252 60.6809L51.5091 51.3662L63.8414 55.5507C63.8322 58.1218 63.7884 63.8224 63.6868 66.0568C58.666 72.9474 51.8141 69.3923 49.0157 66.7535L48.8252 60.6809Z"
                fill="#FFB4B4"
              />
              <path
                d="M63.415 63.3532C56.3538 61.4535 51.5569 52.947 50.041 48.9312C51.8775 50.8924 56.207 55.0528 58.8333 56.0046C61.4596 56.9564 63.2663 56.0985 63.8414 55.5505C63.8604 57.5009 63.8017 61.792 63.415 63.3532Z"
                fill="#FF8D8B"
              />
              <ellipse
                cx="59.937"
                cy="42.154"
                rx="11.6972"
                ry="14.63"
                transform="rotate(9.27277 59.937 42.154)"
                fill="#FFB4B4"
              />
              <ellipse
                cx="44.4157"
                cy="92.1634"
                rx="1.82769"
                ry="3.65538"
                transform="rotate(13.9123 44.4157 92.1634)"
                fill="#4F45BD"
              />
              <ellipse
                cx="65.6288"
                cy="102.139"
                rx="0.913846"
                ry="1.82769"
                transform="rotate(-20.577 65.6288 102.139)"
                fill="#4F45BD"
              />
              <ellipse
                cx="77.6762"
                cy="97.5917"
                rx="0.913846"
                ry="1.82769"
                transform="rotate(0.377143 77.6762 97.5917)"
                fill="#4F45BD"
              />
              <ellipse
                cx="43.9727"
                cy="92.4303"
                rx="1.09662"
                ry="1.82769"
                transform="rotate(13.9123 43.9727 92.4303)"
                fill="white"
              />
              <circle
                cx="60.8708"
                cy="34.8559"
                r="0.731077"
                transform="rotate(13.9123 60.8708 34.8559)"
                fill="#FF0000"
              />
              <path
                d="M60.5276 40.0432C60.3946 40.073 60.2893 40.549 60.9312 42.2144C61.7336 44.296 62.798 44.5597 63.0649 45.0024C63.3319 45.4451 62.8907 46.4656 61.3836 46.4689C60.178 46.4715 59.3438 46.0891 59.0774 45.8976"
                stroke="#FF8D8B"
                stroke-linecap="round"
              />
              <path
                d="M57.0879 48.606C57.4291 49.2554 58.4043 50.589 59.5749 50.7283C60.7454 50.8676 61.599 50.6648 61.8795 50.546"
                stroke="#FF8D8B"
                stroke-linecap="round"
              />
              <circle
                cx="58.5856"
                cy="12.4476"
                r="6.21415"
                transform="rotate(13.9123 58.5856 12.4476)"
                fill="black"
              />
              <path
                d="M53.1934 37.6748C53.7182 38.4324 55.2585 39.6927 57.2211 38.6725"
                stroke="black"
                stroke-opacity="0.2"
                stroke-linecap="round"
              />
              <path
                d="M64 39.0229C64.8515 39.4841 66.9315 39.9642 68.4395 38.196"
                stroke="black"
                stroke-opacity="0.2"
                stroke-linecap="round"
              />
              <path
                d="M58.6947 26.5201C58.2522 31.0709 51.2021 35.3117 45.9538 38.086C43.6319 39.3133 40.7309 38.3644 39.5328 36.0274C38.761 34.5219 38.857 32.718 39.7842 31.303L43.8561 25.0889C45.5599 22.4889 47.9263 20.3903 50.7114 19.0098L53.3232 17.7151C57.0452 15.8701 61.3353 15.531 65.301 16.7684C68.8772 17.8842 71.9915 20.1843 73.9028 23.4062C77.5588 29.5695 81.6435 37.8438 76.9917 37.7195C72.0297 37.5868 64.8911 30.2972 61.338 25.8042C60.6174 24.893 59.0098 25.1768 58.7305 26.3044C58.7129 26.3754 58.7018 26.4474 58.6947 26.5201Z"
                fill="#030406"
                stroke="#030406"
              />
              <path
                d="M60.5576 22.7275C60.9938 26.2876 63.0895 33.3342 67.9827 33.0399C72.8759 32.7456 72.2687 33.4739 71.3535 33.8749"
                stroke="#030406"
              />
              <circle
                cx="47.4727"
                cy="39.0686"
                r="2.19323"
                transform="rotate(13.9123 47.4727 39.0686)"
                fill="#FFB4B4"
              />
              <circle
                cx="71.9375"
                cy="37.5971"
                r="2.19323"
                transform="rotate(13.9123 71.9375 37.5971)"
                fill="#FFB4B4"
              />
              <path
                d="M47.4969 41.3374C47.6885 40.5679 48.1348 42.0381 48.1065 44.9943C48.0885 46.8785 46.0044 46.9368 46.1965 45.0624C46.4573 42.5169 47.1846 42.5915 47.4969 41.3374Z"
                stroke="#717EF0"
              />
              <path
                d="M71.8621 40.1763C71.7971 39.386 72.6909 40.6358 73.6112 43.4452C74.1978 45.2358 72.2422 45.9588 71.8237 44.1216C71.2553 41.6267 71.9681 41.4643 71.8621 40.1763Z"
                stroke="#717EF0"
              />
              <path
                d="M47.7797 37.8271C48.312 37.9589 48.5958 39.0817 48.2048 39.3367"
                stroke="#FF8D8B"
                stroke-linecap="round"
              />
              <path
                d="M71.5188 38.282C71.1104 37.916 71.3861 36.7912 71.851 36.7492"
                stroke="#FF8D8B"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div
            className="flex justify-around text-sm ml-7"
            style={{ borderRadius: "5px", alignItems: "center" }}
          >
            <div
              className="pl-2 text-patient"
              onClick={() => {
                if (localStorage.access && localStorage.refresh) {
                  history.push("/formpatient");
                } else {
                  history.push("/login");
                  message.warning("Please login");
                }
              }}
              style={{ color: "rgba(0, 0, 0, 1)" }}
            >
              {localStorage.user_type === 2
                ? "Chat with patients"
                : "Book/Request a doctor"}
            </div>
            <svg
              style={{ paddingTop: "-12px" }}
              className="ml-2 w-6 arrow"
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke={
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                }
                stroke-width="2"
              />
            </svg>
          </div>
        </div>
      </div>
    );
})
);

export default BookDoctorCard
