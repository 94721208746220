import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Blackarrow from "../Assets/Blackarrow.png";
import Single from "../Assets/Single.png";
import Sidebar from "../Components/Headers/sidebar";
import Doctorsidebar from "../Components/Headers/Doctorsidebar";

function SingleCommunity() {
  const history = useHistory();
  const { state } = useLocation();
  const { blog } = state;
  console.log({ blog });

  return (
    <div className="flex">
      <div className="websites">
        {localStorage.user_type === "1" ? <Sidebar /> : <Doctorsidebar />}
      </div>
      <div className="web mx-auto">
        <div className="mobile mt-4 pt-5 pl-5">
          <img
            onClick={() => history.goBack()}
            className="cursor-pointer text-black "
            src={Blackarrow}
            alt=""
          />
          <p className="text-2xl -mt-5 pb-8 text-center sm:text-center sm:text-5xl">
            Community
          </p>
        </div>
        <p className=" websites mx-auto text-2xl mt-5 pb-8 sm:text-4xl">
          Community
        </p>
        <div
          className="mobile flex pl-2 mt-3 mx-auto"
          style={{ alignItems: "center", width: "80%" }}
        >
          <img
            className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
          <div className="flex bottom-2 ml-2 text-sm">
            <p>{blog.writer_name}</p>
            <div className="ml-2" style={{ color: "#A7A7A7" }}>
              {blog.created_at.substring(0, 10).split("-").reverse().join("-")}
            </div>
          </div>
        </div>
        <div
          className="flex community-div mx-auto mt-6"
          style={{ width: "80%" }}
        >
          <div>
            <img
              className="blog-img"
              style={{ width: "100%", borderRadius: "5px" }}
              src={blog.image !== null ? blog.image : Single}
              alt=" "
            />
          </div>
          <div className="content-box mx-auto px-2" style={{ width: "100%" }}>
            <p className="blog-title font-semibold text-lg mt-4 md:text-center md:text-3xl md:mb-4">
              {blog.title}
            </p>
            <p className="blog-content mt-2" style={{ color: "black" }}>
              {blog.content}
            </p>
          </div>
        </div>
        <hr className="mx-auto mt-4" style={{ width: "85%" }}></hr>
        <div className="h-10"></div>
      </div>
    </div>
  );
}

export default SingleCommunity;
