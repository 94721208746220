import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { DatePicker, Form, Input, Button, Spin } from "antd";
import "antd/dist/antd.css";
import { inject, observer } from "mobx-react";
import axios from "axios";
import moment from "moment";
import ImageUploader from "react-images-upload";
import "../../Styles/form.css";
import { Colors, HexColors } from "../../Utils/colors";
import { message, Modal } from "antd";
import editform from "../../Assets/editform.svg";
import avatar from "../../Assets/form/avatar.svg";
import phone from "../../Assets/form/phone.svg";
import calendar from "../../Assets/form/calendar.svg";
import wp_add from "../../Assets/form/wp_add.svg";
import wp_pin from "../../Assets/form/wp_pin.svg";
import email1 from "../../Assets/form/email.svg";
import whatsapp1 from "../../Assets/form/whatsapp.svg";
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import "./style/patient_editing_style.css";
import SideBar from "../Headers/sidebar";

const Profileform = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const { state } = useLocation();
    const info = state;
    const [error, setError] = useState(false); // set Error should be handled in handle submit
    const d = new Date();
    const currYear = d.getFullYear();
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [gender, setGender] = useState("1");
    const [alternateNo, setAlternateNo] = useState("");
    const [dob, setDob] = useState(""); // login with 9805461307
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (info) {
        setName(info.data.name);
        setMobile(info.data.mobile.substr(2, 12));
        setWhatsapp(info.data.whatsapp);
        setEmail(info.data.email);
        setAddress(info.data.address);
        setPincode(info.data.pincode);
        info.data.gender && setGender(info.data.gender.toString());
        setAlternateNo(info.data.alt_mobile);
        setDob(info.data.dob);
      }
    }, [info]);
    console.log({ info });

    const checkPincode = async (val) => {
      setPincode(val);
      if (val.length === 6) {
        let res = await axios.get(
          `https://api.postalpincode.in/pincode/${val}`
        );
        let Message = await res.data[0].Message;
        if (res && Message === "No records found") {
          setPincode("");
          message.error("Oops!! Wrong Pincode");
        }
      }
    };

    const handleSubmit = async () => {
      if (
        name === "" ||
        mobile.length < 10 ||
        whatsapp.length < 10 ||
        pincode === "" ||
        email === "" ||
        address === "" ||
        alternateNo.length < 10 ||
        dob === "" ||
        gender === ""
      ) {
        setError(true);
      } else {
        const data = {
          name,
          whatsapp,
          address,
          alt_mobile: alternateNo,
          dob: moment(dob).format().substr(0, 10),
          email,
          gender: parseInt(gender),
          mobile: `+91${mobile}`,
          pincode,
        };
        const body = JSON.stringify(data);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        await axios
          .post("api/auth/user/", body, config)
          .then((res) => {
            history.goBack();
            message.success("Profile Updated");
          })
          .catch((err) => {
            message.error("Check Details!!!");
            console.log(err);
          });
      }
    };

    const updateProfile = async (image) => {
      setLoading(true);
      var form_data = new FormData();
      form_data.append("profile_pic", image[0]);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.access,
        },
      };
      await axios
        .post("api/auth/user/", form_data, config)
        .then((res) => {
          console.log(res);
          message.success("Profile Picture Updated");
          setVisible(false);
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (loading) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Uploading...."></Spin>
        </div>
      );
    }

    return (
      <div className="profile_edit">
        <div className="edit_sidebar">
          <SideBar />
        </div>
        <div className="flex justify-start" style={{ alignItems: "center" }}>
          <div
            onClick={() => history.goBack()}
            className="mt-4 pt-5 pl-5 go_back"
          >
            <img className=" text-black " src={leftarrow_black} alt="" />
          </div>
          <h2
            className=" text-base pl-5 font-medium md:text-center md:text-2xl edit_heading"
            style={{ marginTop: "2.35rem" }}
          >
            Edit Profile
          </h2>
        </div>
        <div
          className=" flex justify-center pt-1 mb-5 "
          id="patient_edit_profile"
        >
          <img
            style={{
              border: `4px solid ${
                store.color === Colors.allopathic
                  ? HexColors.allopathic
                  : store.color === Colors.homeopathic
                  ? HexColors.homeopathic
                  : HexColors.ayurvedic
              }`,
            }}
            className="edit_patient_profile_pic"
            src={
              info.data.profile_pic
                ? info.data.profile_pic
                : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
            }
            alt="profile"
          />
          <img
            onClick={() => setVisible(true)}
            className="mt-20"
            id="profile_pic_upload"
            src={editform}
            alt=""
          />
        </div>
        <Modal
          visible={visible}
          footer={null}
          onCancel={() => setVisible(false)}
          bodyStyle={{ width: "90%", margin: "0", padding: "0" }}
        >
          <div
            className="upload_image"
            style={{
              margin: "0",
              padding: "0",
              position: "absolute",
              top: "0",
              height: "30vh",
            }}
          >
            <ImageUploader
              buttonText={"Select"}
              onChange={(e) => updateProfile(e)}
              label="Choose Your Profile Picture"
              fileSizeError="File is Too Big"
              fileTypeError="This type of file is not supported"
              imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
              maxFileSize={5242880}
              singleImage={true}
            />
          </div>
        </Modal>
        <div className="text-center -mt-3">
          <Form
            name="nest-messages"
            className="grid md:grid-cols-2 grid-cols-1"
            id="patient_edit_from"
          >
            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={avatar} alt="" />
              </div>
              <Input
                name="name"
                type="text"
                style={{
                  color: "black",
                  borderRadius: "2px",
                  width: "19.25rem",
                  border: error && name.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(event) => {
                  if (
                    event.target.value.match(/^[A-Za-z]+$/) ||
                    event.target.value === ""
                  ) {
                    setName(event.target.value);
                  }
                }}
                value={name}
                placeholder="Patient Name"
                autoFocus
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={phone} alt="" />
              </div>
              <Input
                name="mobile"
                type="number"
                style={{
                  color: "black",
                  width: "19.25rem",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="h-8 mx-auto outline-none text-black text-xs  font-normal "
                onChange={(e) =>
                  e.target.value.length > 10 ? "" : setMobile(e.target.value)
                }
                value={mobile}
                placeholder="Mobile Number "
                maxLength={10}
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={whatsapp1} alt="" />
              </div>
              <Input
                required
                name="whatsapp"
                type="number"
                style={{
                  color: "black",
                  width: "19.25rem",
                  border:
                    error && whatsapp.length < 10 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) =>
                  e.target.value.length > 10 ? "" : setWhatsapp(e.target.value)
                }
                value={whatsapp}
                placeholder="Whatsapp Number "
                maxLength={10}
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={email1} alt="" />
              </div>
              <Input
                name="email"
                style={{
                  color: "black",
                  width: "19.25rem",
                  border:
                    error && email.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email id "
                type="email"
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={calendar} alt="" />
              </div>
              <DatePicker
                className="mx-auto h-8 outline-none px-5  text-black"
                dateFormat="dd/MM/yyyy"
                type="number"
                disabledDate={(d) => !d || d.isAfter(new Date())}
                style={{
                  color: "black",
                  width: "19.25rem",
                  border: error && dob.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                selected={dob}
                onSelect={(date) => {
                  setDob(date.toString());
                }}
                placeholder={dob && dob.length ? dob : "DOB"}
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={calendar} alt="" />
              </div>
              <Input
                name="age"
                style={{
                  color: "black",
                  width: "19.25rem",
                  border: "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                placeholder={
                  dob
                    ? `${
                        currYear - parseInt(moment(dob).format().substr(0, 4))
                      }`
                    : "Age"
                }
                maxLength={2}
                disabled={true}
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={wp_add} alt="" />
              </div>
              <Input
                name="address"
                style={{
                  color: "black",
                  width: "19.25rem",
                  border:
                    error && address.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                placeholder="Address"
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={wp_pin} alt="" />
              </div>
              <Input
                style={{
                  color: "black",
                  width: "19.25rem",
                  border:
                    error && pincode.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="h-8  mx-auto outline-none pl-2  text-black text-xs  font-normal "
                onChange={(e) => checkPincode(e.target.value)}
                value={pincode}
                placeholder="Pin Code"
                maxLength={6}
              />
            </div>

            <div
              className="flex justify-around text-sm mx-auto"
              style={{ width: "18.25rem", fontSize: "12px" }}
            >
              <div
                className="h-8 flex outline-none px-2 text-black mr-3"
                style={{
                  boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                  marginRight: "13px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  checked={gender === "1"}
                  type="radio"
                  value="1"
                  name="gender"
                  onChange={(e) => setGender(e.target.value)}
                  className="mr-1.5 ml-2 "
                />
                <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                  Male
                </p>
              </div>
              <div
                className=" h-8 flex outline-none px-2 my-2 text-black"
                style={{
                  boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                  marginRight: "13px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="radio"
                  checked={gender === "2"}
                  value="2"
                  name="gender"
                  onChange={(e) => setGender(e.target.value)}
                  className="mr-1 ml-2"
                />
                <p
                  style={{ paddingTop: "7px" }}
                  className="pt-1.5 pr-1.5 pl-px"
                >
                  Female
                </p>
              </div>
              <div
                className="h-8 flex outline-none px-2 my-2 text-black pr-1 "
                style={{
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="radio"
                  checked={gender === "3"}
                  value="3"
                  onChange={(e) => setGender(e.target.value)}
                  name="gender"
                  className="mr-1.5 ml-2"
                />
                <p style={{ paddingTop: "7px" }} className="pr-1.5">
                  Others
                </p>
              </div>
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "21.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={phone} alt="" />
              </div>
              <Input
                name="alternateNo"
                type="number"
                style={{
                  color: "black",
                  width: "19.25rem",
                  border:
                    error && alternateNo.length < 10 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) =>
                  e.target.value.length > 10
                    ? ""
                    : setAlternateNo(e.target.value)
                }
                value={alternateNo}
                placeholder="Alternate Number(Care giver) "
                maxLength={10}
              />
            </div>
            <Button
              onClick={() => handleSubmit()}
              className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl lg:mb-8"
              id="submit_btn"
              style={{
                background: store.color,
                borderRadius: "2px",
                color: "white",
              }}
            >
              <p className="btn_text">{info ? "Save" : "Next"}</p>
            </Button>
          </Form>
        </div>

        <div className="h-28"></div>
        {/* <Button
          onClick={() => handleSubmit()}
          className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl"
          style={{
            background: store.color,
            borderRadius: "2px",
            color: "white",
            height: "70px",
          }}
        >
          <p style={{ fontSize: "20px" }}>{info ? "Save" : "Next"}</p>
        </Button> */}
      </div>
    );
  })
);

export default Profileform;
