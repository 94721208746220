import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Button, message, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { Colors, HexColors } from "../Utils/colors";
import "../Styles/form.css";
import leftarrow_black from "../Assets/leftarrow_black.svg";
import Doctorsidebar from "../Components/Headers/Doctorsidebar";
import Sidebar from "../Components/Headers/sidebar";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import cloud from "../Assets/cloud.svg";
import "../Styles/placeholder.css";

const DoctorCommunityForm = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const { blog } = state;
    const [error, setError] = useState(false);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [image, setImage] = useState([]);
    const [url, setUrl] = useState(null);

    useEffect(() => {
      if (blog.title && blog.content) {
        setTitle(blog.title);
        setContent(blog.content);
        setUrl(blog.image);
      }
    }, [blog]);

    const onImageChange = (event) => {
      setImage(event.target.files[0]);
      if (event.target.files && event.target.files[0]) {
        setUrl(URL.createObjectURL(event.target.files[0]));
      }
    };

    console.log({ blog });

    const handleSubmit = async () => {
      if (title === "" || content === "" || image.length === 0) {
        setError(true);
      } else if (blog.id) {
        setLoading(true);
        var form_Data = new FormData();
        form_Data.append("id", blog.id);
        form_Data.append("title", title);
        form_Data.append("content", content);
        form_Data.append("image", image);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        await axios
          .post("api/vol/community/", form_Data, config)
          .then((response) => {
            if (response.data.status) {
              setLoading(false);
              message.success("Edit successful");
              history.goBack();
            }
            console.log(response);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        setLoading(true);
        var form_data = new FormData();
        form_data.append("title", title);
        form_data.append("content", content);
        form_data.append("image", image);
        console.log({ title });
        console.log({ content });
        console.log({ form_data });

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        await axios
          .post("api/vol/community/", form_data, config)
          .then((response) => {
            if (response.data.status) {
              setLoading(false);
              message.success("Upload successful");
              history.goBack();
            }
            console.log(response);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    };
    console.log({ image });

    return loading ? (
      <div
        style={{
          margin: 0,
          padding: 0,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "#ececec",
        }}
      >
        <Spin tip="Uploading..."></Spin>
      </div>
    ) : (
      <div className="flex">
        <div className="websites">
          {localStorage.user_type === "1" ? <Sidebar /> : <Doctorsidebar />}
        </div>
        <div className="web">
          {/* for mobile */}
          <div className="mobile mt-4 pt-5 pl-5">
            <img
              onClick={() => history.goBack()}
              className="cursor-pointer text-black "
              src={leftarrow_black}
              alt=""
            />
            <p
              style={{ width: "fit-content" }}
              className="text-center text-2xl -mt-6 font-semibold ml-10 pb-8 sm:text-4xl"
            >
              Community Blog
            </p>
          </div>
          <div>
            <h2
              style={{ width: "fit-content" }}
              className=" websites mx-auto font-medium text-2xl my-5 text-4xl"
            >
              Community Blog
            </h2>
            <div className="text-center -mt-3s sm:mx-28">
              <p
                style={{
                  visibility: !error ? "hidden" : "visible",
                  color: "red",
                }}
                className="text-xs pt-3 md:text-center "
              >
                *Kindly fill the required fields{" "}
              </p>
              <Form name="nest-messages">
                <div
                  className="h-44 mb-5 mx-auto py-12 relative"
                  style={{
                    borderRadius: "5px",
                    marginTop: "15px",
                    background: "#C4C4C4",
                    backgroundImage: url ? `url(${url})` : "",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "88%",
                    border:
                      error && image.length === 0 ? "1px solid red" : "none",
                  }}
                >
                  {url === null && (
                    <>
                      <input type="image" src={cloud} alt="" />
                      <input
                        style={{ opacity: "0", width: "100%" }}
                        onChange={onImageChange}
                        type="file"
                        className="bg-red-600 absolute top-0 h-44 mx-auto"
                        accept="image/*"
                        src={cloud}
                      />
                      <p style={{ fontSize: "16px" }}>Upload a Cover Image</p>
                    </>
                  )}
                </div>

                <div
                  className="mb-8 mx-auto pt-2 pb-1 px-0.5"
                  style={{
                    marginTop: "5px",
                    width: "88%",
                    height: "426px",
                    background: "#FFFFFF",
                    boxSizing: "border-box",
                    borderRadius: "16.3014px",
                    overflow: "hidden",
                    border:
                      error && (title.length === 0 || content.length === 0)
                        ? "1px solid red"
                        : "1px solid #929BFF",
                  }}
                >
                  <TextareaAutosize
                    value={title}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    className="pl-3 pt-3 title text-lg px-2 md:text-xl outline-none border-none"
                    style={{
                      width: "100%",
                      fontSize: "24px",
                    }}
                    aria-label="minimum height"
                    minRows={2}
                    placeholder="Title"
                  />
                  <hr style={{ border: "3px solid black" }}></hr>
                  <TextareaAutosize
                    value={content}
                    name="content"
                    onChange={(e) => setContent(e.target.value)}
                    className="text-md px-2 -mt-2 nd:text-lg pl-3 outline-none border-none"
                    style={{ width: "100%" }}
                    aria-label="minimum height"
                    minRows={17}
                    placeholder="Start Writing"
                  />
                </div>
              </Form>
            </div>
            <div className="h-10"></div>
            <Button
              className="communityform-button"
              style={{
                background:
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                    ? HexColors.homeopathic
                    : HexColors.ayurvedic,
                color: "white",
                height: "70px",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              {blog.id ? "Edit" : "Publish"}
            </Button>
          </div>
        </div>
      </div>
    );
  })
);

export default DoctorCommunityForm;
