import React, { useState, useEffect } from 'react'
import Crosss from '../Assets/Crosss.svg';
import Whatsapp from '../Assets/Whatsapp.png'
import { useHistory, useLocation } from 'react-router-dom'

function Share() {
    const { state } = useLocation();
    const history = useHistory();
    const [url, setUrl] = useState("");

    console.log(state.obj);

    useEffect(() => {
        setUrl("whatsapp://send?text=" + state.obj.file);
    }, [state])

    return (
        <div >
            <img onClick={() => history.goBack()} className="pl-8 pt-10" src={Crosss} alt="" />
            <div className="mx-6 m-2 mt-6" >
                <img className="" src={state.obj.file} alt="" />
                {/* <div className="h-24 ml-1 -mt-3" style={{ background: "linear-gradient(180deg, #929BFF 0%, #717EF0 98.75%)", borderRadius: "0px 15px 15px 15px" }}>
                </div> */}
            </div>
            <div style={{ borderRadius: "20px 20px 0px 0px", height: "10rem" }} className=" bg-white mx-auto w-full fixed bottom-0 px-6" >
                <div className="flex ">

                    <p className="font-medium text-lg pl-2 pt-8" style={{ color: "rgba(0, 0, 0, 0.58)" }}>
                        Share To Whatsapp:-
                    </p>
                    <a href={url} data-action="share/whatsapp/share" target="_blank" rel="noopener noreferrer">
                        <img className="pl-6 pt-8 cursur-pointer" src={Whatsapp} alt="" />
                    </a>

                </div>
            </div>

        </div>
    )
}

export default Share
