import React from 'react'
import { inject, observer } from 'mobx-react';
import { Colors, HexColors } from '../../Utils/colors';


const PatientHomeScreenCards = inject("store")(observer(({ store, image, title, text, doctor }) => {

    return (
        <div style={{
            boxShadow: " 4px 4px 4px rgba(0, 0, 0, 0.04)",
            paddingBottom: "0.5rem", paddingTop: "0.5rem",
            borderColor: `${store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)}`,
            borderRadius: "16.3px"
        }}
            className="patient-card-home cursor-pointer border px-4 my-5 xl:w-5/12 rounded-2xl flex items-center justify-between mx-auto ">

            <div className=" sm:w-1/2  w-8/12    " >
                <div className="sm:text-md text-md font-normal text-gray-200 " style={{ paddingBottom: "2px" }} >{title}</div>
                <div className="text-home sm:text-xl font-normal  text-md  " style={{ paddingBottom: "10px" }} >{text}</div>
                <div className=" text-gray-400 text-xs  " style={{ paddingBottom: "2px" }}>{doctor}</div>
            </div>
            <div>
                <img className="image-home sm:h-40 md:h-40 xl:h-30  " src={image} alt="" />
            </div>

        </div>
    )
}));
export default PatientHomeScreenCards

