import React from 'react'
import { useHistory } from 'react-router-dom'
import blackleftarrow from '../Assets/blackleftarrow.svg'
import callAgain from '../Assets/callAgain.svg'
import circle from '../Assets/circle.svg'

function CallEnd() {
    const history = useHistory();
    return (
        <div className="mx-auto" style={{ alignItems: "center", height:"70%" }}>
            <img onClick={() => history.goBack()} className="pt-10 pl-8 " src={blackleftarrow} alt="" />
            <div className="mt-24 mx-auto text-center" style={{ alignItems: "center", maxWidth:"75%" }} >
                <img className="mx-auto mt-6" src={circle} alt="" />
                <h1 className="mt-4" style={{fontSize:"24px",fontWeight: "500"}}>Call rejected</h1>
                <p style={{fontSize:"18px",fontWeight: "normal"}}>Ram Prasath</p>
            </div>
            <div 
             className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl mb-8">
                <div
                className="flex mx-auto text-sm"
                style={{alignItems:"center", width:"60%",
                background: "#FFFFFF",
                    border: "1px solid #E9E9E9",
                    boxSizing: "border-box",
                    borderRadius: "20px",
                    fontWeight: "500"}}>
                <img className="mr-2" src={callAgain} alt="" />
                <p className="mx-auto">Slide to Call Again</p>
                </div>
            </div>
        </div>
    )
}

export default CallEnd
