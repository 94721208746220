import React from 'react'
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import Search from "../../Assets/Search.png";
import { inject, observer } from "mobx-react";
import Sidebar from "../Headers/sidebar";
import { useHistory } from "react-router-dom";
import WebHeader from '../Headers/WebHeader'
import './styles/screen4.css'
import { Button } from "antd";
import AppointmentTime from './AppointmentTime'
import DoctorCard from './DoctorCard';
import { Colors } from "../../Utils/colors";

const Screen4 = inject("store")(
    observer(({ store, data }) => {
        const history = useHistory();

        return (
            <div className="overflowX-hidden">
                <div className="mobile">
                    <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
                        <img className=" text-black " src={leftarrow_black} alt="" />
                    </div>
                </div>
                <div className="webView">
                    <div className="websites">
                        <Sidebar />
                    </div>
                    <div className="web webView2">
                        <div>
                            <WebHeader />
                        </div>
                        <div
                            className="search-appoint flex justify-between mx-auto py-2 rounded-2xl mt-10"
                            style={{ boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)" }}
                        >
                            <input
                                type="text"
                                // value={search}
                                // onChange={(e) => OnSearch(e.target.value)}
                                className=" text-left py-1 px-6 search"
                                style={{ alignItems: "center", width: "85%" }}
                                placeholder="Search Doctor"
                            />
                            <button className="" style={{ width: "10%" }}>
                                <img style={{ height: "60%" }} src={Search} alt="" />
                            </button>
                        </div>
                        <div className="card">
                            <div className="web-appoint">
                                <div className="doctor-card">
                                    <DoctorCard />
                                    <div><Button
                                        // onClick={() => handleSubmit()}
                                        className="next-web text-xl websites"
                                        style={{
                                            background: store.color,
                                            borderRadius: "9px",
                                            color: "white",
                                            height: "50px",
                                        }}
                                    >
                                        Next
                                    </Button></div>
                                </div>
                                <div className="appointment-time" style={{
                                    backgroundColor:
                                        store.color === Colors.allopathic
                                            ? "#F5F5FF"
                                            : store.color === Colors.homeopathic
                                                ? "#EFF6FF"
                                                : "#EFFCFF", boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.04)",
                                    borderRadius: "16.3014px"
                                }}>
                                    <div className="appointment-time-heading">Choose the time slot</div>

                                    <div>
                                        <AppointmentTime />
                                        <AppointmentTime />
                                        <AppointmentTime />
                                        <AppointmentTime />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Button
                                    // onClick={() => handleSubmit()}
                                    className="next text-xl mobile"

                                    style={{
                                        background: store.color,
                                        borderRadius: "9px",
                                        color: "white",
                                        height: "50px",
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default Screen4