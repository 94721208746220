import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import deleteIcon from "../Assets/deleteIcon.svg";
import EditIcon from "../Assets/EditIcon.svg";
import { message, Popconfirm } from "antd";
import "../Styles/placeholder.css";
import axios from "axios";
import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import { useHistory } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";

const PatientList = inject("store")(
  observer(({ store, otherProps }) => {
    const history = useHistory();
    const [patientList, setPatientList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [deleteData, setDeleteData] = useState({});
    const [selfAdded, setSelfAdded] = useState(false);
    useEffect(() => {
      if (localStorage.access && localStorage.refresh) {
        axios
          .get("api/pat/patient-list/", {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.access,
            },
          })
          .then((res) => {
            console.log(res.data.data);
            setPatientList(res.data.data);
            if (res.data.data && res.data.data[0].ftype === 1) {
              setSelfAdded(true);
            }
          });
      }
    }, []);

    const deletePatient = (data) => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.access,
        },
      };

      const dataa = { id: data.id };
      const body = JSON.stringify(dataa);
      axios
        .post("api/pat/delete/", body, config)
        .then((response) => {
          console.log(response);
          setVisible(false);
          const newpatientList = patientList.filter(
            (item) => item.id !== data.id
          );
          setPatientList(newpatientList);
          message.success("Patient Deleted successfully");
        })
        .catch((err) => {
          console.log(err);
          message.error("Something went wrong");
        });
    };

    return (
      <div>
        <div className=" max-w-lg mx-auto ">
          <div onClick={() => history.goBack()}>
            <div className="pl-6 pb-2 pt-8">
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 15L2 8L9 1"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
          <div className="mx-auto" style={{ width: "95%" }}>
            <h1
              className=" font-medium text-base py-3"
              style={{
                marginLeft: "36px",
                marginBottom: "24px",
                fontSize: "24px",
                marginTop: "10px",
              }}
            >
              Select user{" "}
            </h1>
            {patientList.map((data, index) => (
              <div key={index} className=" mx-auto ">
                <SwipeableList className=" text-center">
                  <div className=" flex justify-center ">
                    <SwipeableListItem
                      swipeLeft={{
                        content: (
                          <div
                            style={{ height: "135px" }}
                            className="  pt-14 px-6  bg-indigo-400  "
                          >
                            <img className=" mx-auto " src={EditIcon} alt="" />
                          </div>
                        ),
                        action: () =>
                          history.push("/patientform", { data: data }),
                      }}
                      swipeRight={{
                        content: (
                          <div
                            style={{ height: "135px" }}
                            className="  pt-16 px-6 bg-red-600  "
                          >
                            <img
                              className=" mx-auto "
                              src={deleteIcon}
                              alt=""
                            />
                          </div>
                        ),
                        action: () => {
                          setVisible(true);
                          setDeleteData(data);
                        },
                      }}
                    >
                      {deleteData.id === data.id && (
                        <Popconfirm
                          title={`Patient ${deleteData.name} will be deleted!!`}
                          visible={visible}
                          placement="top"
                          icon={
                            <QuestionCircleOutlined style={{ color: "red" }} />
                          }
                          onConfirm={() => deletePatient(deleteData)}
                          onCancel={() => setVisible(false)}
                          okText="Delete"
                          cancelText="Cancel"
                        ></Popconfirm>
                      )}
                      <div
                        className="pt-4 mx-auto"
                        style={{
                          width: "80%",
                          height: "135px",
                          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                          borderRadius: "2px",
                          marginBottom: "23px",
                        }}
                      >
                        <p
                          onClick={() =>
                            history.push("/consultationtype", { id: data.id })
                          }
                          className="font-base pt-10 text-center"
                          style={{ color: "black", fontSize: "16px" }}
                        >
                          {data.name}
                        </p>
                      </div>
                    </SwipeableListItem>
                  </div>
                </SwipeableList>
              </div>
            ))}
            <div
              onClick={() =>
                history.push("/patientform", { selfAdded: selfAdded })
              }
              className="py-4 mx-auto  "
              style={{
                width: "80%",
                height: "135px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
              }}
            >
              <h2
                className=" font-base pt-10 text-center "
                style={{ color: "black" }}
              >
                + Add User
              </h2>
            </div>
          </div>
        </div>
        <div className="h-4"></div>
      </div>
    );
  })
);

export default PatientList;
