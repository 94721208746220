import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Colors, HexColors } from '../../Utils/colors';
import Sidebar from "../Headers/sidebar";
import WebHeader from '../Headers/WebHeader'
import { inject, observer } from "mobx-react";
import attach2 from "../../Assets/attach2.svg";
import cross from "../../Assets/cross.png";
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import "../../Styles/placeholder.css";
import "./style/Homebuttons.css";
import { message, Button, Select } from "antd";

const EmailFeedback = inject("store")(
    observer(({ store, otherProp }) => {
        const history = useHistory();
        const [emailFeedback, setEmailFeedback] = useState("");
        const [fileName, setFileName] = useState("");
        const [selectedFile, setSelectedFile] = useState("");

        const { Option } = Select;

        function onChange(value) {
            console.log(`selected ${value}`);
        }

        function onBlur() {
            console.log('blur');
        }

        function onFocus() {
            console.log('focus');
        }

        // function onSearch(val) {
        //     console.log('search:', val);
        // }

        console.log(selectedFile, fileName)

        const handleSubmit = () => {

            const form_data = new FormData();

            form_data.append("text", emailFeedback);

            if (selectedFile) {
                form_data.append("att", selectedFile ? selectedFile : "");
            }

            form_data.append("user", localStorage.userId);

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.access,
                },
            };

            axios
                .post("api/vol/sendmail/", form_data, config)
                .then((response) => {
                    if (response.data.status) {
                        console.log(response);
                        message.success("Email sent successfully")
                        history.push("/home")
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        return (
            <div className="overflowX-hidden">
                <div className="mobile">
                    <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
                        <img className=" text-black " src={leftarrow_black} alt="" />
                    </div>
                </div>
                <div className="webView">
                    <div className="websites">
                        <Sidebar />
                    </div>
                    <div className="web">
                        <div>
                            <WebHeader />
                        </div>
                        <div className="ambulance-text">Mail Us</div>

                        <div className="outline-none border-none ">
                            <Select className=" border-none outline-none placeholder search-mail"
                                showSearch
                                style={{
                                    paddingTop: "5px", paddingBottom: "5px",
                                    boxShadow: "0px 3.48531px 3.48531px rgba(51, 51, 51, 0.04), 0px 3.48531px 13.9413px rgba(51, 51, 51, 0.08)",
                                    borderRadius: "6px",
                                    border: "none",
                                    outline: "none", borderColor: "transparent"

                                }}
                                placeholder="Select the mail category"
                                optionFilterProp="children"
                                onChange={onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            >
                                <Option value="query" style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px" }}>Query</Option>
                                <Option value="feedback" style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px" }}>Feedback</Option>
                                <Option value="suggestion" style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px" }}>Suggestion</Option>
                                <Option value="issue" style={{ textAlign: "center", paddingTop: "12px", paddingBottom: "12px" }}>Issue</Option>
                            </Select>
                        </div>

                        <div className="textarea-email">
                            <textarea
                                type="text"
                                className="text-sm mr-1 text-left outline-none border-none"
                                style=
                                {{
                                    marginTop: "17px",
                                    paddingTop: "20px",
                                    paddingLeft: "5%",
                                    paddingRight: "5%",
                                    width: "85%",
                                    height: "301px",
                                    marginLeft: "-3px",
                                    border: "1px solid", borderColor: `${store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)}`, borderRadius: " 16.3014px"
                                }}
                                value={emailFeedback}
                                onChange={(e) => { setEmailFeedback(e.target.value) }}
                                placeholder="Write to us your queries, doubts or feedback. We value pur users and appreciate your feedback!"
                            />
                        </div>
                        {fileName
                            ?
                            <div
                                className="pt-6 attachment-email"
                                style={{
                                    background: store.color, 
                                    border: "1px solid #929BFF", 
                                    borderRadius: "12px", 
                                    color: "white",  }}>
                                <img
                                    onClick={(e) => {
                                        setFileName("")
                                        setSelectedFile("")
                                    }}
                                    src={cross}
                                    alt=""
                                    className="float-right mr-3 -mt-3" />
                                <div className="-mt-3 ml-5 truncate" style={{ float: "left", width: "190px" }}>{fileName}</div>
                            </div>
                            :
                            null
                        }
                        <div className="web-email">

                            <div className="attach-email" style=
                                {{
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
                                    marginTop: "2vh",
                                    borderRadius: "3px"
                                }}>
                                <label htmlFor="files" style={{ cursor: "pointer", marginLeft: "10px" }}>
                                    <img src={attach2} alt="" /><div className="text-center" style={{ marginTop: "-20px", width: "329px" }}>Attach documents</div>
                                </label>
                                <input id="files" onChange={(e) => {
                                    setFileName(e.target.files[0].name)
                                    setSelectedFile(e.target.files[0])
                                }}
                                    style={{ visibility: "hidden" }}
                                    type="file"

                                />
                            </div>

                            <div>
                                <Button
                                    onClick={handleSubmit}
                                    className="send-email md:fixed text-xl"
                                    style={{
                                        background: store.color,
                                        borderRadius: "2px",
                                        color: "white",
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })
);

export default EmailFeedback;