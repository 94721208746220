import React, { useState, useEffect } from "react";
import Lottie from "react-lottie-player";
import loading from "../Components/Loading.json";
// import blackleftarrow from "../Assets/blackleftarrow.svg";
import { useHistory, useLocation } from "react-router-dom";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { HostName } from "../Utils/utils";

function Loading() {
  const history = useHistory();
  const { state } = useLocation();
  const { id, spec_type } = state;
  const [ws, setWs] = useState(
    new W3CWebSocket(
      `wss://${HostName}/ws/medhelp/waiting/${spec_type}/${id}/?user_token=${localStorage.access}`
    )
  );
  useEffect(() => {
    connect();
    // eslint-disable-next-line
  }, []);

  const connect = async () => {
    ws.onopen = (e) => {
      console.log(e);
      return true;
    };
    ws.onclose = (e) => {
      console.error("error", e);
    };
    ws.onmessage = (e) => {
      console.log(e);
      const dataFromServer = JSON.parse(e.data);
      console.log(dataFromServer);
      if (id === dataFromServer.message.patient) {
        ws.close();
        setWs("");
        history.push("/found", {
          doctor: dataFromServer.message.doctor,
          id: id,
        });

      }

    };
  };

  return (
    <div className=" container text-center mx-w-sm mx-auto">
      <div onClick={() => history.goBack()}>
        <div className="pl-6 pb-2 pt-8">
          <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 15L2 8L9 1" stroke="black" stroke-width="2" stroke-linecap="round" />
          </svg>
        </div>
      </div>
      <div className="md:grid md:place-items-center md:h-screen md:w-full mt-8 ">
        <div className="text-center mx-auto max-w-sm">
          <Lottie loop animationData={loading} play style={{ color: "red" }} />
          <div className="mt-12 text-center text-2xl mb-20 mx-12 font-medium">
            Looking for available Doctors!
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
