/* eslint-disable no-lone-blocks */
import React, { Component } from 'react'
import Group140 from "../Assets/Group140.png"
import Group138 from "../Assets/Group138.png"
import Group139 from "../Assets/Group139.png"
import rightarrow from "../Assets/rightarrow.svg"
import "../Styles/Onboarding.css"
import Group103 from "../Assets/Group103.png"
import Group104 from "../Assets/Group104.png"
import Group105 from "../Assets/Group105.png"



export class Onboarding extends Component {

  constructor(props) {
    super(props);



    this.switchImage = this.switchImage.bind(this)
    this.switchIcon = this.switchIcon.bind(this)

    this.state = {
      index: 0,
      imgList: [`${Group138}`, `${Group139}`, `${Group140}`],
      iconList: [`${Group103}`, `${Group104}`, `${Group105}`],
      title: [
        "Loren Ipsum", "Loren ipsum2", "Loren ipsum3"
      ],
      content: [
        "Google Images is a way to visually discover information on the wadges , and AMP results.",
        "Google Images efwefis a way to visually discover information on the wadges, and AMP results.",
        "Google Images fwfw is a way to visually discover information on the wadges, and AMP results."
      ]
    };
  }



  switchImage() {
    if (this.state.index + 1 === this.state.imgList.length) {
      this.setState({
        index: 0
      });
    }
    if (this.state.index >= 2) {
      this.props.history.push('/login')
    }
    else {
      this.setState({
        index: this.state.index + 1
      });
    }

  }

  switchIcon() {
    if (this.state.index + 1 === this.state.iconList.length) {
      this.setState({
        index: 0
      });
    }
    if (this.state.index >= 2) {
      this.props.history.push('/login')
    }
    else {
      this.setState({
        index: this.state.index + 1
      });
    }
  }

  render() {
    return (
      
     <div className=" container " >
      <div className="  md:max-w-sm max-w-lg   background      mx-auto "onClick={this.switchImage && this.switchIcon} >
            <div className=" mx-auto   " style={{width:"90vw"}} >
             <img className="  " src={this.state.imgList[this.state.index]} alt="" />
           </div> 
         
         
         
         <div style={{zIndex:"+10"}} className="  md:max-w-sm max-w-lg  md:bottom-40 fixed bottom-5 " >
            <div>
              <h1 className="text-center   mb-1 text-2xl   font-medium">{this.state.title[this.state.index]}</h1>
              <p className="  px-8     text-center     " >{this.state.content[this.state.index]}</p>
            </div>

            <div className=" flex justify-between px-5 pt-5    " >
               
            <div className="flex    cursor-pointer " style={{ color: "rgba(113, 126, 240, 1)",
 alignItems: " center", visibility: this.state.index === 2 ? "hidden" : null }}  >
                  <p className="pr-3" onClick={() => this.props.history.push('/login')} >Skip</p>
                  <img src={rightarrow} alt="" />
                </div>

                <div>
                <img src={this.state.iconList[this.state.index]} className=" w-8 mb-1  cursor-pointer " onClick={this.switchImage && this.switchIcon} alt="" />
                </div>
            </div>
       </div>
    </div>
</div>
            
          //  <div className=" max-w-md mx-auto  " > 
          //      <div >
          //        <img  className=" relative "  src={onBack} alt="" />
          //        <div className=" absolute   w-72 top-3 ">

          //        <img className="   " src={this.state.imgList[this.state.index]} alt="" />
          //      </div>
          //      </div>
          //  </div>
      
      // <div className=" xl:block  md:grid md:place-items-center md:h-screen " onClick={this.switchImage && this.switchIcon} >
      //   <div className=" max-w-lg md:max-w-sm background    md:h-full    mx-auto pt-4   " >

      //     <div className=" mx-auto   md:w-80 pt-4  w-64   " >
      //       <img className="  " src={this.state.imgList[this.state.index]} alt="" />
      //     </div>

      //   </div>
      //   <div className=" max-w-lg md:max-w-sm mx-auto" >
      //     <div className=" md:static fixed  bottom-2" >
      //       <div className=" py-16 " >
      //         <p className="   text-center py-3 text-2xl  font-medium " >{this.state.title[this.state.index]} </p>
      //         <p className="      text-center  px-5     " >{this.state.content[this.state.index]}</p>
      //       </div>
      //       <div className='  mx-auto flex justify-between  px-3 ' >
      //         <div className="flex    cursor-pointer " style={{ alignItems: " center", visibility: this.state.index === 2 ? "hidden" : null }}  >
      //           <p onClick={() => this.props.history.push('/login')}  >Skip</p>
      //           <img className=" px-2" src={rightarrow} alt="" />
      //         </div>
      //         <div>
      //           <img src={this.state.iconList[this.state.index]} className=" w-10  cursor-pointer " onClick={this.switchImage && this.switchIcon} alt="" />
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}

export default Onboarding
