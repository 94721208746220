import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DatePicker, Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import { inject, observer } from "mobx-react";
import axios from "axios";
import moment from "moment";
import "../Styles/form.css";
import { message } from "antd";
import avatar from "../Assets/form/avatar.svg";
import phone from "../Assets/form/phone.svg";
import calendar from "../Assets/form/calendar.svg";
import wp_add from "../Assets/form/wp_add.svg";
import wp_pin from "../Assets/form/wp_pin.svg";
import email1 from "../Assets/form/email.svg";
import whatsapp1 from "../Assets/form/whatsapp.svg";
// import leftarrow_black from "../Assets/leftarrow_black.svg";

const Patientform = inject("store")(
  observer(({ store, otherProp }) => {
    // const Option = Select.Option;
    const history = useHistory();
    const { state } = useLocation();
    const [error, setError] = useState(false);
    const d = new Date();
    const currYear = d.getFullYear();
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState("");
    const [gender, setGender] = useState("1");
    const [alternateNo, setAlternateNo] = useState("");
    const [relation, setRelation] = useState("");
    const [dob, setDob] = useState(""); // login with 9805461307
    const [editMode, setEditMode] = useState(false);
    const [disabledSelf, setDisabledSelf] = useState(false);
    let emailcheck = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    useEffect(() => {
      if (state) {
        const { data, selfAdded } = state;
        if (data) {
          console.log(data);
          setEditMode(true);
          setId(data.id);
          setName(data.name);
          setMobile(data.mobile_no);
          setWhatsapp(data.whatsapp_no);
          setEmail(data.emailid);
          setAddress(data.address);
          setPincode(data.pincode);
          data.gender && setGender(data.gender.toString());
          setAlternateNo(data.alter_no);
          setDob(data.dob);
          data.ftype && setRelation(data.ftype.toString());
          console.log("pat", data);
        }
        if (selfAdded) {
          console.log("self", selfAdded);
          setDisabledSelf(true);
        }
      }
    }, [state]);
    const getData = async () => {
      await axios
        .get("api/auth/user/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          res.data.data.name && setName(res.data.data.name);
          res.data.data.mobile && setMobile(res.data.data.mobile);
          res.data.data.whatsapp && setWhatsapp(res.data.data.whatsapp);
          res.data.data.email && setEmail(res.data.data.email);
          res.data.data.address && setAddress(res.data.data.address);
          res.data.data.pincode && setPincode(res.data.data.pincode);
          res.data.data.gender && setGender(res.data.data.gender.toString());
          res.data.data.alt_mobile && setAlternateNo(res.data.data.alt_mobile);
          res.data.data.dob && setDob(res.data.data.dob);
        });
    };

    const checkPincode = async (val) => {
      setPincode(val);
      if (val.length === 6) {
        let res = await axios.get(
          `https://api.postalpincode.in/pincode/${val}`
        );
        let Message = await res.data[0].Message;
        if (res && Message === "No records found") {
          setPincode("");
          message.error("Oops!! Wrong Pincode");
        }
      }
    };

    const handleSubmit = () => {
      if (
        relation === "" ||
        name === "" ||
        mobile.length < 10 ||
        whatsapp.length < 10 ||
        pincode === "" ||
        email === "" ||
        address === "" ||
        alternateNo.length < 10 ||
        dob === "" ||
        gender === "" ||
        !email.trim().match(emailcheck)
      ) {
        setError(true);
      } else {
        const data = {
          id: editMode ? id : "",
          name: name,
          mobile_no: mobile,
          whatsapp_no: whatsapp,
          emailid: email,
          ftype: parseInt(relation),
          address: address,
          pincode: pincode,
          dob: dob.length === 10 ? dob : moment(dob).format().substr(0, 10), //2021-09-09
          age: currYear - parseInt(moment(dob).format().substr(0, 4)),
          gender: parseInt(gender),
          alter_no: alternateNo,
        };
        console.log({ data });
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/pat/patients/", body, config)
          .then((response) => {
            editMode
              ? history.goBack()
              : history.push("/consultationtype", {
                  id: response.data.data.id,
                });
            editMode
              ? message.success("Patient Details Updated")
              : message.success("Patient Added");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    const handleRelation = (val) => {
      if (val === "1") {
        setRelation(val);
        getData();
      } else if (val === "3") {
        setRelation("3");
        setId("");
        setName("");
        setMobile("");
        setWhatsapp("");
        setEmail("");
        setAddress("");
        setPincode("");
        setGender("");
        setAlternateNo("");
        setDob("");
      }
    };

    return (
      <div>
        <div onClick={() => history.goBack()}>
          <div className="pl-6 pb-2 pt-8">
            <svg
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 15L2 8L9 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        <h1
          className="pt-4 font-medium md:text-center md:text-2xl md:mb-6"
          style={{ paddingLeft: "3.7rem" }}
        >
          Kindly Enter the following details
        </h1>
        <p
          style={{
            visibility: !error ? "hidden" : "visible",
            paddingLeft: "3.7rem",
          }}
          className=" text-red-500 text-xs pt-3 pb-2 "
        >
          *Kindly fill the required fields{" "}
        </p>
        <div className="text-center -mt-3">
          <Form
            name="nest-messages"
            className="grid md:grid-cols-2 grid-cols-1"
          >
            <div
              className="flex justify-around text-sm mx-auto"
              style={{ width: "18.25rem", fontSize: "12px" }}
            >
              {!disabledSelf && (
                <div
                  className="h-8 flex outline-none px-2 text-black mr-3"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "12px",
                    marginTop: "11px",
                    marginRight: "13px",
                  }}
                >
                  <input
                    style={{ marginTop: "10px" }}
                    checked={relation === "1"}
                    type="radio"
                    value={"1"}
                    onChange={(e) => handleRelation(e.target.value)}
                    className="mr-1.5 ml-2 "
                    disabled={editMode}
                  />
                  <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                    Self
                  </p>
                </div>
              )}
              <div
                className=" h-8 flex outline-none px-2 my-2 text-black"
                style={{
                  boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                  marginRight: "13px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="radio"
                  checked={relation === "3"}
                  value={"3"}
                  onChange={(e) => handleRelation(e.target.value)}
                  className="mr-1 ml-2"
                  disabled={editMode}
                />
                <p
                  style={{ paddingTop: "7px" }}
                  className="pt-1.5 pr-1.5 pl-px"
                >
                  Relatives
                </p>
              </div>
            </div>
            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={avatar} alt="" />
              </div>
              <Input
                name="name"
                type="text"
                // onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                // pattern="/^[a-zA-z]$/"
                // pattern="[a-zA-Z]*"
                style={{
                  color: "black",
                  borderRadius: "2px",
                  width: "16.25rem",
                  border: error && name.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(event) => {
                  if (
                    event.target.value.match(/^[A-Za-z]+$/) ||
                    event.target.value === ""
                  ) {
                    setName(event.target.value);
                  }
                }}
                value={name}
                placeholder="Patient Name"
                autoFocus
              />
            </div>
            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={phone} alt="" />
              </div>
              <Input
                name="mobile"
                type="number"
                style={{
                  color: "black",
                  width: "16.25rem",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="h-8 mx-auto outline-none text-black text-xs  font-normal "
                onChange={(e) =>
                  e.target.value.length > 10 ? "" : setMobile(e.target.value)
                }
                value={mobile}
                placeholder="Mobile Number "
                maxLength={10}
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={whatsapp1} alt="" />
              </div>
              <Input
                required
                name="whatsapp"
                type="number"
                style={{
                  color: "black",
                  width: "16.25rem",
                  border:
                    error && whatsapp.length < 10 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) =>
                  e.target.value.length > 10 ? "" : setWhatsapp(e.target.value)
                }
                value={whatsapp}
                placeholder="Whatsapp Number "
                maxLength={10}
              />
            </div>
            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    email.length === 0 && error
                      ? "1px solid red"
                      : email
                      ? !email.trim().match(emailcheck)
                        ? "1px solid red"
                        : "none"
                      : "none",
                }}
              >
                <img className=" mx-auto my-2" src={email1} alt="" />
              </div>
              <Input
                name="email"
                style={{
                  color: "black",
                  width: "16.25rem",
                  border:
                    email.length === 0 && error
                      ? "1px solid red"
                      : email
                      ? !email.trim().match(emailcheck)
                        ? "1px solid red"
                        : "none"
                      : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Email id "
                type="email"
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={calendar} alt="" />
              </div>
              <DatePicker
                className="mx-auto h-8 outline-none px-5  text-black"
                dateFormat="dd/MM/yyyy"
                type="number"
                disabledDate={(d) => !d || d.isAfter(new Date())}
                style={{
                  color: "black",
                  width: "16.25rem",
                  border: error && dob.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                selected={dob}
                onSelect={(date) => {
                  setDob(date.toString());
                }}
                placeholder={dob.length === 10 ? dob : "DOB"}
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={calendar} alt="" />
              </div>
              <Input
                name="age"
                style={{
                  color: "black",
                  width: "16.25rem",
                  border: error && dob.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                placeholder={
                  dob.length
                    ? `${
                        currYear - parseInt(moment(dob).format().substr(0, 4))
                      }`
                    : "Age"
                }
                maxLength={2}
                disabled
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={wp_add} alt="" />
              </div>
              <Input
                name="address"
                style={{
                  color: "black",
                  width: "16.25rem",
                  border:
                    error && address.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                placeholder="Address"
              />
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={wp_pin} alt="" />
              </div>
              <Input
                style={{
                  color: "black",
                  width: "16.25rem",
                  border:
                    error && pincode.length === 0 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="h-8  mx-auto outline-none pl-2  text-black text-xs  font-normal "
                onChange={(e) => checkPincode(e.target.value)}
                value={pincode}
                placeholder="Pin Code"
                maxLength={6}
              />
            </div>

            <div
              className="flex justify-around text-sm mx-auto"
              style={{ width: "18.25rem", fontSize: "12px" }}
            >
              <div
                className="h-8 flex outline-none px-2 text-black mr-3"
                style={{
                  boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                  marginRight: "13px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  checked={gender === "1"}
                  type="radio"
                  value="1"
                  name="gender"
                  onChange={(e) => setGender(e.target.value)}
                  className="mr-1.5 ml-2 "
                />
                <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                  Male
                </p>
              </div>
              <div
                className=" h-8 flex outline-none px-2 my-2 text-black"
                style={{
                  boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                  marginRight: "13px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="radio"
                  checked={gender === "2"}
                  value="2"
                  name="gender"
                  onChange={(e) => setGender(e.target.value)}
                  className="mr-1 ml-2"
                />
                <p
                  style={{ paddingTop: "7px" }}
                  className="pt-1.5 pr-1.5 pl-px"
                >
                  Female
                </p>
              </div>
              <div
                className="h-8 flex outline-none px-2 my-2 text-black pr-1 "
                style={{
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="radio"
                  checked={gender === "3"}
                  value="3"
                  onChange={(e) => setGender(e.target.value)}
                  name="gender"
                  className="mr-1.5 ml-2"
                />
                <p style={{ paddingTop: "7px" }} className="pr-1.5">
                  Others
                </p>
              </div>
            </div>

            <div
              className="flex mx-auto my-3"
              style={{
                width: "18.25rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div
                className="bg-blue-400 h-8"
                style={{
                  width: "2rem",
                  background: "#EEEEEE",
                  borderRadius: "2px 0px 0px 2px",
                  border:
                    error && mobile.length < 10 ? "1px solid red" : "none",
                }}
              >
                <img className=" mx-auto my-2" src={phone} alt="" />
              </div>
              <Input
                name="alternateNo"
                type="number"
                style={{
                  color: "black",
                  width: "16.25rem",
                  border:
                    error && alternateNo.length < 10 ? "1px solid red" : "none",
                  paddingLeft: "12px",
                  paddingright: "12px",
                  fontSize: "12px !important",
                }}
                className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                onChange={(e) =>
                  e.target.value.length > 10
                    ? ""
                    : setAlternateNo(e.target.value)
                }
                value={alternateNo}
                placeholder="Alternate Number(Care giver) "
                maxLength={10}
              />
            </div>
          </Form>
        </div>

        <div className="h-28"></div>
        <Button
          onClick={() => handleSubmit()}
          className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl"
          style={{
            background: store.color,
            borderRadius: "2px",
            color: "white",
            height: "70px",
          }}
        >
          <p style={{ fontSize: "20px" }}>{editMode ? "Save" : "Next"}</p>
        </Button>
      </div>
    );
  })
);

export default Patientform;
