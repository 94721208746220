import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {message } from "antd";
// import Blackarrow from '../../Assets/Blackarrow.png'
import { Colors} from "../../Utils/colors";
import axios from 'axios';

const Consultationtype = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const { state } = useLocation();
    const key = 'updatable';
    const { id } = state;
    console.log(id);
    console.log(state.id);

    const handleCondition = async(isCovid)=>{
      console.log({isCovid});
      if(localStorage.user_type==="2"){
        let data = {
          covid_status: isCovid?2:1,
        };
    
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };
        const body = JSON.stringify(data);
        axios
          .post("/api/pat/getpatient/", body, config)
          .then((response) => {
            console.log(response);
            history.push("/doctorchat", {
              doctor: response.data.data.doc,
              id: response.data.data.id,
              name: response.data.data.name,
              user: response.data.data.user,
            });
          })
          .catch((err) => {
            message.error({ content: "Seems Like Patient not available Please Try Again", key, duration: 1 });
            console.log(err);
          });
      }else{
        if(localStorage.Specialization==="1"||localStorage.Specialization==="2"){
          let docType =
            store.color === Colors.allopathic
              ? 1
              : store.color === Colors.homeopathic
                ? 2
                : 3;
          // console.log(state.isCovid, docType, specialization, localStorage.userId);
          const data = {
            user: state.id,
            requirement_type: localStorage.Specialization,
            doc_field: docType,
            is_covid: isCovid,
          };
          console.log(data);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.access,
            },
          };
    
          const body = JSON.stringify(data);
    
          axios
            .post("api/pat/schedule/", body, config)
            .then((response) => {
              console.log(response);
              history.push("/loading", {
                id: response.data.data.id,
                spec_type: response.data.data.requirement_type,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        else{
          history.push("/specialization", { isCovid: isCovid, id: id })
        }
      }
    }

    return (
      <div className=" max-w-lg mx-auto text-center p-4">
          <div onClick={() => history.goBack()}>
            <div className="pl-2 pb-2 pt-4">
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 15L2 8L9 1" stroke="black" stroke-width="2" stroke-linecap="round" />
              </svg>
            </div>
          </div>
        <div className="pt-20">
          <div className="text-left mx-8 pb-10">
            <h1 className="font-medium text-2xl">
              Consultation type
            </h1>
          </div>

          <div
            onClick={() =>
              {
              handleCondition(true);}
            }
            className="text-black mx-8 my-4 p-12 text-base"
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
              borderRadius: "2px"
            }}
          >
            Covid
          </div>
          <div
            onClick={() =>
              {
              handleCondition(false);}
            }
            className="text-black mx-8 my-4 p-12 text-base"
            style={{
              background: "#FFFFFF",
              boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
              borderRadius: "2px"
            }}
          >
            Non-covid
          </div>
        </div>
      </div>
    );
  })
);

export default Consultationtype;
