import React, { useState, useEffect } from "react";
import red_button from "../Assets/endButton.png";
import green_call from "../Assets/answerButton.png";
import SendBirdCall from "sendbird-calls";
// import "../Styles/voicecall.css"
import unmute from "../Assets/mute.png";
import mute from "../Assets/unmute.png";
import cameraoff from "../Assets/offCamera.png";
import cameraon from "../Assets/onCamera.png";
import blue_back from "../Assets/blue_back.svg";
import Modal from "antd/lib/modal/Modal";

const VoiceCall = () => {
  const [callObject, setCallObject] = useState("");
  const [onIncoming, setonIncoming] = useState(false);
  const [outGoingCall, setOutGoingCall] = useState(false);
  const [cameraEnable, setCameraEnable] = useState(true);
  const [micEnable, setMicEnable] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    if (seconds < 60) {
      setTimeout(() => setSeconds(seconds + 1), 1000);
    } else {
      setSeconds(0);
      setMinutes(minutes + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  useEffect(() => {
    const authenticate = async () => {
      const authOption = {
        userId: localStorage.userId,
        accessToken: localStorage.sb_access,
      };

      await SendBirdCall.authenticate(authOption, (result, error) => {
        if (error) {
          // Handle authentication failure.
          console.log(error);
        } else {
          // The user has been successfully authenticated and is connected to Sendbird server.
          console.log("connected");
        }
      });

      // Establishing websocket connection.
      await SendBirdCall.connectWebSocket()
        .then(
          /* Succeeded to connect */ (res) => {
            console.log(res);
            if (parseInt(localStorage.user_type) === 2) {
              calling();
            }
          }
        )
        .catch(/* Failed to connect */ (e) => console.log(e));

      await SendBirdCall.addListener(localStorage.userId, {
        onRinging: (call) => {
          console.log("ringing", call);
          setonIncoming(true);
          call.onEstablished = (call) => {
            console.log(call);
          };

          call.onConnected = (call) => {
            console.log(call);
            setonIncoming(false);
          };

          call.onEnded = (call) => {
            console.log(call);
            setonIncoming(false);
          };

          call.onRemoteAudioSettingsChanged = (call) => {
            console.log(call);
          };

          call.onRemoteVideoSettingsChanged = (call) => {
            console.log(call);
          };
          setCallObject(call);
        },
      });
    };
    authenticate();
  }, []);

  const receivehandler = () => {
    const acceptParams = {
      callOption: {
        localMediaView: document.getElementById("local_video_element_id"),
        remoteMediaView: document.getElementById("remote_video_element_id"),
        audioEnabled: true,
        videoEnabled: true,
      },
    };
    callObject.accept(acceptParams);
  };

  const callEndhandler = () => {
    callObject.end();
  };

  const cameraToggle = () => {
    console.log("off");
    if (cameraEnable) {
      callObject.stopVideo();
    } else {
      callObject.startVideo();
    }
  };

  const micToggle = () => {
    console.log("off");
    if (micEnable) {
      callObject.muteMicrophone();
    } else {
      callObject.unmuteMicrophone();
    }
  };

  const calling = () => {
    console.log("calling", localStorage.patient_id);
    const dialParams = {
      userId: localStorage.patient_id,
      isVideoCall: true,
      callOption: {
        localMediaView: document.getElementById("local_video_element_id"),
        remoteMediaView: document.getElementById("remote_video_element_id"),
        audioEnabled: true,
        videoEnabled: true,
      },
    };
    const call = SendBirdCall.dial(dialParams, (call, error) => {
      if (error) {
        // Dialing failed.
        console.log(error);
        setOutGoingCall(false);
      }
      console.log("dialing");
      setOutGoingCall(true);
      // Dialing succeeded.
    });

    call.onEstablished = (call) => {
      console.log(call);
    };

    call.onConnected = (call) => {
      console.log(call);
      setOutGoingCall(false);
    };

    call.onEnded = (call) => {
      console.log(call);
      setOutGoingCall(false);
    };

    call.onRemoteAudioSettingsChanged = (call) => {
      console.log(call);
    };

    call.onRemoteVideoSettingsChanged = (call) => {
      console.log(call);
    };
    setCallObject(call);
  };

  return (
    <div className="audio_video_div">
      <div>
        <div>
          <div>
            <video id="remote_video_element_id" autoPlay></video>
          </div>
          <div>
            <video id="local_video_element_id" autoPlay></video>
          </div>

          <div className="  ">
            <div
              className=" fixed "
              style={{ height: "10vh", background: "#fff", bottom: "1px" }}
            >
              <img className=" rounded-b-3xl " src={blue_back} alt="" />
              <div className=" flex justify-between absolute  w-full  top-2 px-5 text-white  ">
                <div>
                  <p className=" font-medium text-2xl  ">
                    {localStorage.user_type === "1"
                      ? `Dr. ${localStorage.doc_name}`
                      : localStorage.Patient_name}
                  </p>
                  
                </div>
                <p>
                  {minutes < 10 ? "0" + minutes : minutes} {" : "}{" "}
                  {seconds < 10 ? "0" + seconds : seconds}
                </p>
              </div>
            </div>
            <div className=" bottom-10  absolute w-full flex justify-evenly ">
              <div>
                {micEnable ? (
                  <img
                    src={mute}
                    alt=""
                    onClick={() => {
                      setMicEnable(!micEnable);
                      micToggle();
                      console.log(micEnable);
                    }}
                  />
                ) : (
                  <img
                    src={unmute}
                    alt=""
                    onClick={() => {
                      setMicEnable(!micEnable);
                      micToggle();
                      console.log(micEnable);
                    }}
                  />
                )}
              </div>
              {cameraEnable ? (
                <img
                  src={cameraon}
                  alt=""
                  onClick={() => {
                    setCameraEnable(!cameraEnable);
                    cameraToggle();
                    console.log(cameraEnable);
                  }}
                />
              ) : (
                <img
                  src={cameraoff}
                  style={{ height: "8.5vh" }}
                  alt=""
                  onClick={() => {
                    setCameraEnable(!cameraEnable);
                    cameraToggle();
                    console.log(cameraEnable);
                  }}
                />
              )}
              <img onClick={() => callEndhandler()} src={red_button} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="h-32"
        style={{
          position: "fixed",
          marginTop: "-15vh",
          height: "105vh",
          marginLeft: "-1vw",
        }}
        onCancel={() => setOutGoingCall(false)}
        visible={outGoingCall}
        closable={false}
      >
        <div className=" grid place-items-center w-full h-screen ">
          <div className=" text-center  ">
            <div className=" flex justify-center ripple  ">
              <img
                className=" rounded-full h-48 object-contain "
                src="https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                alt=""
              />
            </div>
            <div className="  ">
              <p className="  font-medium py-2">Calling</p>
              <p className=" font-medium  text-xl py-3 ">
                {localStorage.Patient_name ? localStorage.Patient_name : ""}
              </p>
            </div>
            <div
              onClick={() => {
                setOutGoingCall(false);
                callEndhandler();
              }}
              className=" flex justify-center pt-12 "
            >
              <img src={red_button} alt="" />
            </div>
          </div>
        </div>
      </Modal>
      {/* incoming  */}
      <Modal
        className="h-32"
        style={{
          position: "fixed",
          marginTop: "-15vh",
          height: "105vh",
          marginLeft: "-1vw",
        }}
        onCancel={() => setonIncoming(false)}
        visible={onIncoming}
        closable={false}
      >
        <div className=" grid place-items-center w-full h-screen ">
          <div className=" text-center rounded-full ">
            <div className=" flex justify-center ripple  ">
              <img
                className=" rounded-full h-48 object-contain "
                src="https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                alt=""
              />
            </div>
            <div className="  ">
              <p className="  font-medium py-2 text-2xl ">Incoming Call</p>
              <p className=" font-medium  text-xl py-3 ">
                {localStorage.doc_name ? `Dr. ${localStorage.doc_name}` : ""}
              </p>
            </div>
            <div className=" flex justify-center pt-6 ">
              <img
                onClick={() => {
                  setonIncoming(false);
                  receivehandler();
                }}
                className=" pr-2 "
                src={green_call}
                alt=""
              />
              <img
                className=" pl-2"
                onClick={() => {
                  setonIncoming(false);
                  callEndhandler();
                }}
                src={red_button}
                alt=""
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VoiceCall;
