import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "../Styles/otp.css";
import leftarrow from "../Assets/leftarrow.svg";
import { Input, message } from "antd";

function Otp() {
  const [num, setNum] = useState("");
  const [previousNum, setPreviousNum] = useState("");
  const [error, seterror] = useState(false);
  const history = useHistory();
  const key = "updatable";

  const handleSubmit = async () => {
    if (num.length === 10) {
      if (previousNum !== num) {
        setPreviousNum(num);
        const data = {
          mobile: num,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/auth/mobile-otp/", body, config)
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              localStorage.setItem("rid", res.data.data.rid);
              message.success("Otp Is sent to your mobile number");
              history.push("/otpverification", { num: num });
            } else {
              console.log("wrong input");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      message.error({
        content: "Please enter a valid phone number",
        key,
        duration: 1,
      });
      if (num.length < 10 || num.length > 10) {
        seterror(true);
      }
    }
  };

  const handleChange = (e) => {
    if (Number(e.target.value) || e.target.value === "") {
      setNum(e.target.value);
    }
  };

  return (
    <div className="login-container">
      <div className=" cursor-pointer pt-8 pl-8  ">
        <img onClick={() => history.goBack()} src={leftarrow} alt="" className="leftarrow"/>
      </div>
      <div className="otp_box">
        <div className="   max-w-md  mx-auto text-center">
          <div className=" text-center pt-14 ">
            <h1 className=" text-2xl font-medium  ">
              Enter Your mobile <br /> number
            </h1>
            <p className=" text-xs pt-14 px-14">
              We will send you an{" "}
              <span className=" font-medium ">One Time Password </span> <br />{" "}
              to this mobile number{" "}
            </p>
          </div>
          <div
            className="   flex justify-center rounded-tl-md rounded-bl-md rounded-r-md  mt-4   mx-auto"
            style={{ alignItems: "center", height: "50px" }}
          >
            <div
              style={{
                background: "#8F99FF",
                height: "50px",
                borderRadius: "6px 0px 0px 6px",
              }}
              className="  px-4  text-center  text-white font-semibold pt-4"
            >
              +91
            </div>
            <Input
              type="tel"
              autoFocus
              value={num}
              onPressEnter={() => handleSubmit()}
              onChange={(e) => {
                handleChange(e);
              }}
              maxLength="10"
              style={{
                height: "50px",
                borderRadius: "0px 6px 6px 0px",
                border: "1px solid #000000",
                borderLeft: "none",
                maxWidth: "300px",
              }}
              className=" w-72 pl-2 outline-none "
            />
          </div>

          <p
            style={{ visibility: !error ? "hidden" : "visible" }}
            className=" text-red-500 text-xs pt-2 "
          >
            *Please Enter Valid Phone number
          </p>

          <button
            onClick={() => handleSubmit()}
            style={{
              bottom: "20vh",
              color: "#8F99FF",
              boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
              width: "284px",
              height: "49px",
              borderRadius: "10px",
            }}
            className=" text-base  mt-9 bg-white  font-semibold   "
            id="get_otp_btn"
          >
            Get OTP
          </button>
        </div>
      </div>
    </div>
  );
}

export default Otp;
