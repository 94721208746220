import React, { useState, useEffect } from "react";
// import Header from '../Components/Header';
import { SearchOutlined } from "@ant-design/icons";
import CommunityCard from "../Components/CommunityCard";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Sidebar from "../Components/Headers/sidebar";
// import DoctorHeader from '../Components/DoctorHeader';
import axios from "axios";
import { Skeleton } from "antd";
import { Colors, HexColors } from "../Utils/colors";
import leftarrow_black from "../Assets/leftarrow_black.svg";
import Doctorsidebar from "../Components/Headers/Doctorsidebar";

const Community = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const [blogs, setBlogs] = useState([]);
    const [myblogs, setMyBlogs] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const { state } = useLocation();
    const user = state;
    const [searchData, setSearchData] = useState([]);
    const [search, setSearch] = useState("");
    const [searchEnable, setSearchEnable] = useState(false);

    useEffect(() => {
      axios
        .get("api/vol/community-list/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          if (res.data.data) {
            setBlogs(res.data.data);
            setShowSkeleton(false);
          }
        })
        .catch(() => {
          console.log("Blogs not get");
        });

      axios
        .get("api/vol/my-community/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log("my blogs", res);
          if (res.data.data) {
            setMyBlogs(res.data.data);
          }
        })
        .catch(() => {
          console.log("My Blogs not get");
        });
    }, []);

    const OnSearch = async (search) => {
      setSearch(search);
      setSearchEnable(true);
      if (search !== "") {
        try {
          let filterItems = (arr, query) => {
            return arr.filter((item) => {
              let community = item.title.toLowerCase().indexOf(query) !== -1;
              return community;
            });
          };

          let result = await filterItems(blogs, search.toLowerCase());
          await setSearchData(result);
        } catch (e) {
          console.log("e2", e);
        }
      }
    };

    return (
      <div className="flex">
        <div className="websites">
          {localStorage.user_type === "1" ? <Sidebar /> : <Doctorsidebar />}
        </div>
        <div className="web">
          {/* for mobile */}
          <div className="mobile mt-4 pt-5 pl-5">
            <img
              onClick={() => history.goBack()}
              className="cursor-pointer text-black "
              src={leftarrow_black}
              alt=""
            />
            <p className="text-2xl -mt-5 pb-8 text-center sm:text-center sm:text-5xl">
              Community
            </p>
          </div>
          {/* for website */}
          <p className=" websites mx-auto text-2xl mt-5 pb-8 sm:text-4xl">
            Community
          </p>
          <div className="sm:mx-20">
            <div
              className="rounded-2xl mx-auto md:max-w-2xl"
              style={{ width: "95%" }}
            >
              {localStorage.user_type === "2" && (
                <div
                  className=" flex  justify-between  mx-auto py-6 rounded-2xl px-4 mb-2"
                  style={{
                    boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)",
                    alignItems: "center",
                    width: "95%",
                  }}
                  onClick={() =>
                    history.push("/doctorCommunityForm", { blog: {} })
                  }
                >
                  <div className="px-2" style={{ width: "95%" }}>
                    <h1 className="text-lg">
                      {user.user ? user.user.user_data.name : "Hello"}
                    </h1>
                    <p>Are you ready to publish your first community blog?</p>
                  </div>
                  <div className="">
                    <svg
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.5 -6.33815e-07C22.5081 -9.83862e-07 29 6.49187 29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 -2.83769e-07 22.5081 -6.33815e-07 14.5C-9.83862e-07 6.49187 6.49187 -2.83769e-07 14.5 -6.33815e-07ZM14.5 26.825C21.3069 26.825 26.825 21.3069 26.825 14.5C26.825 7.69309 21.3069 2.175 14.5 2.175C7.69309 2.175 2.175 7.69309 2.175 14.5C2.175 21.3069 7.69309 26.825 14.5 26.825Z"
                        stroke={
                          store.color === Colors.allopathic
                            ? HexColors.allopathic
                            : store.color === Colors.homeopathic
                            ? HexColors.homeopathic
                            : HexColors.ayurvedic
                        }
                        fill={
                          store.color === Colors.allopathic
                            ? HexColors.allopathic
                            : store.color === Colors.homeopathic
                            ? HexColors.homeopathic
                            : HexColors.ayurvedic
                        }
                      />
                      <path
                        d="M13 9L19 15L13 21"
                        stroke={
                          store.color === Colors.allopathic
                            ? HexColors.allopathic
                            : store.color === Colors.homeopathic
                            ? HexColors.homeopathic
                            : HexColors.ayurvedic
                        }
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div
                className="flex  justify-between mb-3  mx-auto py-2 rounded-2xl"
                style={{
                  boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.03)",
                  width: "95%",
                }}
              >
                <input
                  type="text"
                  value={search}
                  onChange={(e) => OnSearch(e.target.value)}
                  className=" text-left py-1 px-6 search"
                  style={{ alignItems: "center", width: "90%" }}
                  placeholder="Search / Type keywords"
                />
                <button className="mr-3" style={{ width: "10%" }}>
                  <SearchOutlined />
                </button>
              </div>
            </div>
            {showSkeleton ? (
              <div className="p-8">
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </div>
            ) : null}
            {/* <p className="text-xl mt-3 ml-10 sm:text-3xl">Featured</p> */}
            <div
              className="grid lg:grid-cols-2 grid-cols-1 mx-auto"
              style={{ alignItems: "center", width: "100%" }}
            >
              {searchEnable && search !== ""
                ? searchData.map((blog) => (
                    <CommunityCard key={blog.id} data={blog} />
                  ))
                : blogs.map((blog) => (
                    <CommunityCard key={blog.id} data={blog} />
                  ))}
            </div>
            {searchData.length === 0 && search !== "" && (
              <div
                className="mx-auto text-center mt-10 sm:text-center"
                style={{ width: "95%", fontSize: "16px" }}
              >
                No result found
              </div>
            )}

            {localStorage.user_type === "2" && (
              <>
                <p className="text-xl mt-3 ml-10 sm:text-3xl">My Uploads</p>
                {showSkeleton ? (
                  <div className="p-8">
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                  </div>
                ) : null}{" "}
                {myblogs.length === 0 && (
                  <div
                    className="mx-auto text-center mt-10 sm:text-center"
                    style={{ width: "95%", fontSize: "16px" }}
                  >
                    No result found
                  </div>
                )}
                <div
                  className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mx-auto  "
                  style={{ alignItems: "center", width: "100%" }}
                >
                  {myblogs.map((blog) => (
                    <>
                      <CommunityCard key={blog.id} data={blog} />
                    </>
                  ))}
                </div>
              </>
            )}
            <div className="h-5"></div>
          </div>
        </div>
      </div>
    );
  })
);

export default Community;
