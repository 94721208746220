import { action, makeAutoObservable } from "mobx";

class Data {
  color = localStorage.color
    ? localStorage.color
    : "linear-gradient(180deg, #929BFF 0%, #717EF0 98.75%)";
  user = { name: "", pic: "" };

  constructor() {
    makeAutoObservable(this);
    action(this.updateColor);
    action(this.setUser);
  }

  updateColor(color) {
    this.color = color;
    localStorage.setItem("color", color);
  }
  setUser(name, pic) {
    this.user.name = name;
    this.user.pic = pic;
  }
}

export default Data;
