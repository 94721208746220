import React from "react";
import { useHistory } from "react-router-dom";
import Allopathy from "../../Assets/Allopathy.png";
import Ayurvedic from "../../Assets/Ayurvedic.png";
import Homeopathy from "../../Assets/Homeopathy.png";
import { inject, observer } from "mobx-react";
import Blackarrow from "../../Assets/Blackarrow.png";
import { Colors } from "../../Utils/colors";
import "../../Styles/foundDoctor.css";

const Medicinetype = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    return (
      <div className=" max-w-lg mx-auto text-center p-4">
        <div onClick={() => history.goBack()}>
          <img className="pl-6 pb-2 pt-8" src={Blackarrow} alt="" id="web_go_back"/>
        </div>
        <div className="pt-20">
          <div className="text-left mx-8 pb-6">
            <h1 className="font-semibold md:text-2xl text-2xl" id="web_typeofmedicine">
              Type of Medicine
            </h1>
          </div>
          <div id="main_medicinetype_div">
            <div
              onClick={() => {
                history.push("/home");
                store.updateColor(Colors.allopathic);
                localStorage.setItem("Specialization", 3);
              }}
              className="flex"
              id="medicine_div_1"
              style={{
                //   marginLeft: "30px",
                //   padding: "40px",
                boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
              }}
            >
              <div className="font-medium text-base float-left my-auto" id="allopathy_text">
                Allopathy
              </div>
              <img
                className="float-right allopathy_image"
                style={{
                  height: "111px",
                  // marginTop: "-12%",
                  // marginLeft: "24%",
                }}
                src={Allopathy}
                alt=""
              />
            </div>
            <div
              onClick={() => {
                history.push("/home");
                store.updateColor(Colors.homeopathic);
                localStorage.setItem("Specialization", "2");
              }}
              className="flex"
              id="medicine_div_2"
              style={{
                marginTop: "15px",
                //   marginLeft: "30px",
                //   padding: "40px",
                boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
              }}
            >
              <img
              className="homeopathy_image"
                src={Homeopathy}
                style={{ height: "111px", marginTop: "-20px" }}
                alt=""
              />
              <div
                className="font-medium text-base float-right my-auto"
                id="homeopathy_text"
              >
                {" "}
                Homeopathy
              </div>
            </div>
            <div
              onClick={() => {
                history.push("/home");
                store.updateColor(Colors.ayurvedic);
                localStorage.setItem("Specialization", "1");
              }}
              className="flex"
              id="medicine_div"
              style={{
                marginTop: "15px",
                //   marginLeft: "30px",
                //   padding: "40px",
                boxShadow: "0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
              }}
            >
              <p className="font-medium text-base float-left my-auto" id="ayurvedic_text">
                Ayurvedic
              </p>
              <img
                className="float-right"
                id="medicine_image"
                //   style={{
                //     height: "87.58px",
                //     marginTop: "-12%",
                //     marginLeft: "30%",
                //   }}
                src={Ayurvedic}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default Medicinetype;
