import React from "react";
import "./style/patient_style.css";
// import Right_arrowblack from "../Assets/Right_arrowblack.svg"

function PatientProfileContents({title,content}){
    
    return (
        
             <div className="   mx-auto my-5  "  id="contents" >
            <div  className=" flex rounded-xl  justify-between " id="content_context" style={{alignItems:"center",boxShadow:"0px 4px 42px rgba(0, 0, 0, 0.07)"}} >
                <div className=" py-5 " >
                    <p className="font-normal   text-lg">{title}</p>
                    <p className="font-normal text-xs" >{content}</p>
                </div>
                {/* <div>
                    <img src={Right_arrowblack} alt="" />
                </div> */}
            </div>
        </div>
        
    )
}

export default PatientProfileContents
