import { inject, observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Spin } from "antd";
//import leftarrow_black from "../../Assets/leftarrow_black.svg";
import PatientProfileContents from "./PatientProfileContents";
import { Colors, HexColors } from "../../Utils/colors";
import Edit2 from "../../Assets/Edit2.svg";
import moment from "moment";
import axios from "axios";
import "./style/patient_style.css";
import SideBar from "../Headers/sidebar";
import editform from "../../Assets/editform.svg";

const PatientProfile = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();

    const currYear = new Date().getFullYear();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible1, setVisible1] = useState(false);

    useEffect(() => {
      getData();
    }, []);

    const getData = async () => {
      await axios
        .get("api/auth/user/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setData(res.data.data);
          }
          setLoading(false);
        });
    };

    if (loading) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      );
    }

    return (
      <div className="flex">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="patient_details">
          {/* <div className="mt-4 pt-5 pl-5">
            <img
              onClick={() => history.goBack()}
              className=" text-black "
              src={leftarrow_black}
              alt=""
            />
          </div> */}
          <div
            className=" flex justify-center"
            style={{
              alignItems: "center",
              marginTop: "-12%",
              marginLeft: "-24px",
            }}
          >
            <div className=" px-4   ">
              <img
                src={editform}
                alt=""
                className="go_edit"
                onClick={() =>
                  history.push("/patientprofile/edit", { data: data })
                }
              />
              <img
                onClick={() => setVisible1(true)}
                style={{
                  border: `6px solid ${
                    store.color === Colors.allopathic
                      ? HexColors.allopathic
                      : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                  }`,
                }}
                className=" patient_profile_pic "
                src={
                  data.profile_pic
                    ? data.profile_pic
                    : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                }
                alt=""
              />
            </div>

            <Modal
              visible={visible1}
              footer={null}
              mask={true}
              closable={false}
              onCancel={() => setVisible1(false)}
              bodyStyle={{ width: "90%", margin: "0", padding: "0" }}
            >
              <div
                style={{
                  width: "92%",
                  margin: "0",
                  padding: "0",
                  position: "absolute",
                  top: "0",
                  left: "9px",
                }}
              >
                <img
                  className="modal_img"
                  style={{ width: "100%", height: "60%" }}
                  src={
                    data.profile_pic
                      ? data.profile_pic
                      : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                  }
                  alt="view profile"
                />
              </div>
            </Modal>

            <div
              style={{ paddingTop: "28px" }}
              className="patient_display_name"
            >
              <p
                className="truncate"
                style={{ fontSize: "29px", fontWeight: "500", width: "150px" }}
              >
                <br />
                {data.name ? data.name : "NA"}
              </p>
              <hr
                className="   "
                style={{ width: "100%", border: "1px solid #000000" }}
              />
              <p className=" text-sm font-normal " style={{ marginTop: "5px" }}>
                {data.dob
                  ? currYear - parseInt(moment(data.dob).format().substr(0, 4))
                  : "NA"}{" "}
                years old
              </p>
              <p className=" text-xs font-normal "></p>
              <div
                onClick={() =>
                  history.push("/patientprofile/edit", { data: data })
                }
                className="flex mt-2 py-1 px-2"
                id="edit_profile"
                style={{
                  boxShadow: "0px 0px 1px 2px rgba(0, 0, 0, 0.03)",
                  borderRadius: "6px",
                }}
              >
                <img src={Edit2} alt="" style={{ height: "15px" }} />
                <p
                  style={{
                    fontSize: "12px",
                    color: "#747474",
                    marginLeft: "10px",
                  }}
                >
                  Edit details
                </p>
              </div>
            </div>
          </div>
          <p className="profile_heading">My Profile</p>
          <div className="patient_info">
            <div className="mt-12">
              <PatientProfileContents
                title="Email"
                content={data.email ? data.email : "NA"}
              />
              <PatientProfileContents
                title="Address"
                content={data.address ? data.address : "NA"}
              />
              <PatientProfileContents
                title=" Contact"
                content={data.mobile ? data.mobile : "NA"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default PatientProfile;
