import React from "react";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { DatePicker, Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../../Styles/form.css";
import { Colors, HexColors } from "../../Utils/colors";
import moment from "moment";
import avatar from "../../Assets/form/avatar.svg"
import phone from "../../Assets/form/phone.svg"
import calendar from "../../Assets/form/calendar.svg"
import wp_add from "../../Assets/form/wp_add.svg"
import wp_pin from "../../Assets/form/wp_pin.svg"
import email1 from "../../Assets/form/email.svg"
import whatsapp1 from "../../Assets/form/whatsapp.svg"

const Formpatient = inject("store")(
  observer(({ store, otherProp }) => {
    // var mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const history = useHistory();
    const [error, setError] = useState(false);
    const d = new Date();
    const currYear = d.getFullYear();

    const [patientData, setPatientData] = useState({
      patientName: "",
      mobile: "",
      whatsapp: "",
      email: "",
      address: "",
      pincode: "",
      gender: "1",
      alternateNo: "",
    });
    //https://medtest.utkarshh.in/api/auth/user/
    const {
      patientName,
      mobile,
      whatsapp,
      email,
      address,
      pincode,
      gender,
      alternateNo,
    } = patientData;
    const [dob, setDob] = useState("");
    //   console.log(email.match(mailFormat));
    const onChange = (e) =>
      setPatientData({ ...patientData, [e.target.name]: e.target.value });

    const handleSubmit = () => {
      if (
        patientData.patientName === "" ||
        patientData.mobile.length < 10 ||
        patientData.whatsapp.length < 10 ||
        patientData.pincode === "" ||
        patientData.email === "" ||
        patientData.address === "" ||
        patientData.alternateNo.length < 10 ||
        dob === "" ||
        gender === ""
      ) {
        setError(true);
      } else {
        const data = {
          name: patientData.patientName,
          mobile_no: patientData.mobile,
          whatsapp_no: patientData.whatsapp,
          emailid: patientData.email,
          address: patientData.address,
          pincode: patientData.pincode,
          dob: moment(dob).format().substr(0, 10), //2021-09-09
          age: currYear - parseInt(moment(dob).format().substr(0, 4)),
          gender: parseInt(patientData.gender),
          alter_no: patientData.alternateNo,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/pat/patients/", body, config)
          .then((response) => {
            console.log(response);

            history.push("/consultationtype", { id: response.data.data.id });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    return (
      <>
        <div className="">
            <div onClick={() => history.goBack()}>
              <div className="pl-6 pb-2 pt-8">
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 15L2 8L9 1" stroke="black" stroke-width="2" stroke-linecap="round" />
                </svg>
              </div>
            </div>
          <h1 className="pt-4 font-medium md:text-center md:text-2xl md:mb-6" style={{paddingLeft:"3.7rem"}}>
            Kindly Enter the following details
          </h1>

          <p
            style={{ visibility: !error ? "hidden" : "visible", paddingLeft: "3.7rem" }}
            className=" text-red-500 text-xs pt-3 pb-2"
          >
            *Kindly fill the required fields{" "}
          </p>

          <div className="text-center -mt-3">
            <Form name="nest-messages" className="grid md:grid-cols-2 grid-cols-1">

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={avatar} alt="" />
                </div>
                <Input
                  name="patientName"
                  type="text"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && patientName.length === 0
                        ? "1px solid red"
                        : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => onChange(e)}
                  value={patientName}
                  placeholder="Patient Name"
                  autoFocus
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={phone} alt="" />
                </div>
                <Input
                  name="mobile"
                  type="number"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && mobile.length < 10 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none text-black text-xs  font-normal "
                  onChange={(e) => e.target.value.length > 10 ? "" : onChange(e)}
                  value={mobile}
                  placeholder="Mobile Number "
                  maxLength={10}
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={whatsapp1} alt="" />
                </div>
                <Input
                  required
                  name="whatsapp"
                  type="tel"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && whatsapp.length < 10 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => onChange(e)}
                  value={whatsapp}
                  placeholder="Whatsapp Number "
                  maxLength={10}
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={email1} alt="" />
                </div>
                <Input
                  name="email"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && email.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => onChange(e)}
                  value={email}
                  placeholder="Email id "
                  type="email"
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={calendar} alt="" />
                </div>
                <DatePicker
                  className="mx-auto h-8 outline-none px-5  text-black"
                  dateFormat="dd/MM/yyyy"
                  type=" number"
                  disabledDate={(d) => !d || d.isAfter(new Date())}
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border: error && dob.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  selected={dob}
                  onSelect={(date) => {
                    setDob(date.toString());
                  }}
                  placeholder="DOB"
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={calendar} alt="" />
                </div>
                <Input
                  name="age"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border: "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  placeholder={
                    dob.length
                      ? `${currYear - parseInt(moment(dob).format().substr(0, 4))
                      }`
                      : "Age"
                  }
                  maxLength={2}
                  disabled
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={wp_add} alt="" />
                </div>
                <Input
                  name="address"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && address.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => onChange(e)}
                  value={address}
                  placeholder="Address"
                />
              </div>

              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={wp_pin} alt="" />
                </div>
                <Input
                  type="tel"
                  name="pincode"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && pincode.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8  outline-none pl-2  text-black text-xs  font-normal "
                  onChange={(e) => onChange(e)}
                  value={pincode}
                  placeholder="Pin Code"
                  maxLength={6}
                />
              </div>

              <div
                className="flex justify-around text-sm mx-auto"
                style={{ width: "18.25rem", fontSize: "12px" }}
              >
                <div
                  className="h-8 flex outline-none px-2 text-black mr-3"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                    marginRight: "13px",
                  }}
                >
                  <input
                    checked={gender === "1"}
                    style={{ marginTop: "10px" }}
                    type="radio"
                    value="1"
                    name="gender"
                    onChange={(e) => onChange(e)}
                    className="mr-1.5 ml-2 "
                  />
                  <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                    Male
                  </p>
                </div>
                <div
                  className=" h-8 flex outline-none px-2 my-2 text-black"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "12px",
                    marginTop: "11px",
                    marginRight: "13px",
                  }}
                >
                  <input
                    style={{ marginTop: "10px" }}
                    type="radio"
                    value="2"
                    name="gender"
                    onChange={(e) => onChange(e)}
                    className="mr-1 ml-2"
                  />
                  <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-1.5 pl-px">
                    Female
                  </p>
                </div>
                <div
                  className="h-8 flex outline-none px-2 my-2 text-black pr-1"
                  style={{
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "12px",
                    marginTop: "11px",
                  }}
                >
                  <input
                    style={{ marginTop: "10px" }}
                    type="radio"
                    value="3"
                    onChange={(e) => onChange(e)}
                    name="gender"
                    className="mr-1.5 ml-2"
                  />
                  <p style={{ paddingTop: "7px", paddingRight: "0.5rem" }}>
                    Others
                  </p>
                </div>
              </div>


              <div className="flex mx-auto my-3" style={{
                width: "18.25rem", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)", borderRadius: "2px", marginBottom: "11px", marginTop: "12px"
              }}>
                <div className="bg-blue-400 h-8" style={{ width: "2rem", background: "#EEEEEE", borderRadius: "2px 0px 0px 2px" }}>
                  <img className=" mx-auto my-2" src={phone} alt="" />
                </div>
                <Input
                  name="alternateNo"
                  type="number"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && alternateNo.length < 10 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => e.target.value.length > 10 ? "" : onChange(e)}
                  value={alternateNo}
                  placeholder="Alternate Number(Care giver)"
                  maxLength={10}
                />
              </div>
            </Form>
          </div>
          <div className="h-28"></div>
          <Button
            onClick={() => handleSubmit()}
            className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl"
            style={{
              background: store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic),
              borderRadius: "2px",
              color: "white",
              height: "70px",
            }}
          >
            Next
          </Button>
        </div>
      </>
    );
  })
);

export default Formpatient;
