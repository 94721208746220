import React from "react";
import { inject, observer } from "mobx-react";
import { Colors, HexColors } from '../../Utils/colors';
import { useHistory } from "react-router-dom";
import './style/Doctorhome.css'

const NextPatientCard = inject("store")(observer(({ store, isCovid }) => {
  const history = useHistory();

  return (
    <div>
      <div className="md:flex mt-10 justify-around" onClick={() => history.push("/consultationtype", { id: "0" })}>
        <div className="flex mx-3 mt-12" style={{ borderRadius: "10px", alignItems: "center", boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.03)" }}>
          <div className="width w-20 h-16 m-1" style={{ borderRadius: "10px" }}>
            <div className="girl">
              <svg style={{ marginTop: "-62px" }} className="-ml-6" width="152" height="164" viewBox="0 0 152 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M36.6423 37.811C36.7543 30.6251 43.95 21.5394 48.4778 16.8912C49.279 16.0687 50.1657 15.3238 51.2018 14.8289C53.8315 13.5728 56.8758 13.2033 59.4898 13.2316C62.6874 13.2663 66.0224 14.1126 68.3473 16.3081C70.5505 18.3888 73.6673 21.9765 77.8415 27.8954C88.3383 42.7796 73.752 45.3046 71.3353 46.0323C69.4019 46.6144 58.3028 46.845 52.995 46.8876C47.4929 47.1442 36.5195 45.6881 36.6423 37.811Z" fill="#030406" stroke="#050505" />
                <path d="M113.431 93.2841C114.653 91.6413 117.249 87.9033 117.848 86.0934C118.597 83.831 119.344 80.8151 120.541 81.3001C121.739 81.7851 122.499 84.7978 121.354 87.9033C123.255 85.5497 128.163 80.1754 128.605 79.1549C129.046 78.1344 131.481 76.6663 131.575 79.3258C131.869 78.6455 132.786 77.6299 134.101 79.01C134.713 79.6519 134.665 80.6652 134.335 81.7041C135.012 81.558 136.323 81.7447 136.15 83.6601C136.072 84.527 135.593 85.6156 134.928 86.7465C135.943 86.8098 137.692 87.4689 136.562 89.5991C135.149 92.2619 128.206 99.7684 118.593 102.848C117.559 101.297 116.409 99.6313 115.613 98.1555C114.464 96.0253 113.825 94.2347 113.431 93.2841Z" fill="#FFC9C8" />
                <path d="M131.575 79.3258C131.481 76.6663 129.046 78.1344 128.605 79.1549C128.163 80.1754 123.255 85.5497 121.354 87.9033C122.499 84.7978 121.739 81.7851 120.541 81.3001C119.344 80.8151 118.597 83.831 117.848 86.0934C117.249 87.9033 114.653 91.6413 113.431 93.2841C113.825 94.2347 114.464 96.0253 115.613 98.1555C116.409 99.6313 117.559 101.297 118.593 102.848C128.206 99.7684 135.149 92.2619 136.562 89.5991C137.692 87.4689 135.943 86.8098 134.928 86.7465M131.575 79.3258C131.62 80.5965 130.635 82.3016 129.533 83.7687M131.575 79.3258C131.869 78.6455 132.786 77.6299 134.101 79.01C134.713 79.6519 134.665 80.6652 134.335 81.7041M129.533 83.7687C128.79 84.758 127.993 85.6391 127.423 86.2057L129.533 83.7687ZM129.998 87.9732C130.564 87.511 132.002 85.7081 132.651 84.8645C133.203 84.1282 133.955 82.8992 134.335 81.7041M134.335 81.7041C135.012 81.558 136.323 81.7447 136.15 83.6601C136.072 84.527 135.593 85.6156 134.928 86.7465M133.4 89.0285C132.508 90.2353 131.565 91.3493 130.849 92.1382C129.399 93.7372 131.945 90.7313 133.4 89.0285ZM133.4 89.0285C133.95 88.2849 134.481 87.5062 134.928 86.7465" stroke="#FF9290" />
                <path d="M68.9198 66.9764L46.2637 64.942L49.8821 139.272L69.0763 139.32L68.9198 66.9764Z" fill="white" />
                <path d="M25.9469 140.122L27.0732 127.974L21.6207 127.941L16.1667 127.155L11.3311 125.393L7.60063 122.209L6.08532 118.445L6.12112 114.499L6.99837 110.198L8.27438 105.806L13.2173 95.7331L20.3315 85.2563L28.1114 75.1326L37.2682 66.2915L48.5604 60.992L49.8446 80.8926L51.6155 100.349L54.7585 139.351L25.9469 140.122Z" fill={store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)} stroke="#14BFFA" />
                <path d="M121.314 90.3408C121.436 91.8773 121.52 95.2874 120.885 96.6363" stroke="#FF9290" />
                <path d="M126.539 95.0315C126.857 94.6192 127.141 93.3672 125.735 91.6581C124.33 89.9491 123.14 89.3256 122.72 89.2275" stroke="#FF9290" />
                <path d="M63.721 61.3582C66.6034 61.8839 72.7326 63.2891 74.1896 64.7045C76.0549 66.2962 82.4464 70.1389 90.4119 84.351C96.7843 95.7207 103.69 95.36 106.346 93.7585L112.811 90.4642C114.251 93.5197 118.055 100.613 121.751 104.541C118.081 108.716 110.842 122.739 89.1051 115.472C89.1283 115.766 89.2119 116.668 89.3359 117.977C89.5629 120.372 89.9254 124.129 90.3017 128.008C90.6881 131.992 91.089 136.105 91.3724 139.006C87.3908 139.043 81.3615 139.097 75.8978 139.145C69.9664 139.197 64.7014 139.241 63.4462 139.243C63.7938 136.015 63.7742 85.9749 63.721 61.3582Z" fill={store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)} />
                <path d="M26.3793 132.51C26.0909 135.207 25.872 138.29 25.7691 140.078C29.5268 140.018 35.0635 139.928 40.3267 139.84C40.435 138.136 40.5098 134.692 39.9421 134.551C39.2325 134.375 40.9154 133.662 35.686 134.25C31.5024 134.72 27.7384 133.286 26.3793 132.51Z" fill={store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)} />
                <path d="M84.5868 96.4652C85.8573 101.989 88.5857 114.026 89.3359 117.977M89.3359 117.977C89.2119 116.668 89.1283 115.766 89.1051 115.472C110.842 122.739 118.081 108.716 121.751 104.541C118.055 100.613 114.251 93.5197 112.811 90.4642L106.346 93.7585C103.69 95.36 96.7843 95.7207 90.4119 84.351C82.4464 70.1389 76.0549 66.2962 74.1896 64.7045C72.7326 63.2891 66.6034 61.8839 63.721 61.3582C63.7742 85.9749 63.7938 136.015 63.4462 139.243C64.7014 139.241 69.9664 139.197 75.8978 139.145M89.3359 117.977C89.5629 120.372 89.9254 124.129 90.3017 128.008M48.8252 60.6811C49.0341 68.4529 49.6834 84.4303 50.6092 86.166C51.9916 103.643 54.7835 138.792 54.8916 139.572C53.3584 139.615 47.0667 139.726 40.3267 139.84M27.0288 128.151C26.7909 129.112 26.5699 130.726 26.3793 132.51M26.3793 132.51C26.0909 135.207 25.872 138.29 25.7691 140.078C29.5268 140.018 35.0635 139.928 40.3267 139.84M26.3793 132.51C27.7384 133.286 31.5024 134.72 35.686 134.25C40.9154 133.662 39.2325 134.375 39.9421 134.551C40.5098 134.692 40.435 138.136 40.3267 139.84M75.8978 139.145C81.3615 139.097 87.3908 139.043 91.3724 139.006C91.089 136.105 90.6881 131.992 90.3017 128.008M75.8978 139.145C75.6741 137.515 75.4392 134.155 76.2895 133.763C77.3523 133.273 83.5583 133.304 84.1784 133.081C84.7985 132.858 88.8317 132.539 89.8929 131.296C90.3113 129.607 90.3397 128.4 90.3017 128.008" stroke="#00A5E9" />
                <rect x="47.0972" y="24.2793" width="23.3983" height="18.6425" transform="rotate(-3.35279 47.0972 24.2793)" fill="#FFB4B4" />
                <path d="M43.9185 107.857L46.3078 105.813L48.5636 103.547L49.9341 101.815L50.8211 102.035L51.4429 102.566L51.2248 104.206L50.564 106.114L54.9031 103.799L56.8513 102.776L60.1277 100.951L61.3695 101.259L61.7699 101.923L61.6397 103.209L63.9004 103.204L64.0127 103.891L63.8158 105.066L62.0027 107.064L63.7312 106.928L64.3529 107.458L64.0453 108.7L62.0108 110.832L63.9167 110.739L64.1853 111.936L62.2843 114.289L56.4852 117.937L49.8853 120.256L46.3404 120.884L43.3701 120.713L43.134 114.065L43.9185 107.857Z" fill="#FFC9C8" />
                <path d="M61.183 103.531C61.9654 103.16 64.0001 102.497 64.1094 103.879C64.2188 105.262 61.6789 107.357 60.3953 108.232C58.9788 109.136 56.0747 110.927 55.7909 110.857" stroke="#EA8989" />
                <path d="M61.6042 107.362C62.3481 107.041 64.2874 106.475 64.4135 107.721C64.5397 108.967 62.1384 110.812 60.922 111.579C59.5787 112.371 56.8239 113.937 56.5508 113.87" stroke="#EA8989" />
                <path d="M61.7728 111.13C62.4379 110.811 64.1672 110.239 64.2575 111.423C64.3477 112.607 62.1868 114.406 61.095 115.157C59.8903 115.934 51.3822 121.815 43.3565 120.769" stroke="#EA8989" />
                <path d="M43.8608 108.09C45.4683 106.668 48.8246 103.558 49.3897 102.493C49.9548 101.428 50.8958 101.987 51.2956 102.4C51.3716 103.36 51.2846 105.485 50.3288 106.303C52.7377 105.092 57.2949 102.505 59.2724 101.363C60.4133 100.704 63.0843 101.178 61.1832 103.531C58.1299 105.976 55.3858 107.932 53.9681 108.334" stroke="#EA8989" />
                <path d="M48.6036 60.814C45.5036 62.1801 38.7991 65.2769 36.7808 66.7352C34.7626 68.1936 30.0123 73.0297 27.8893 75.2655C22.1999 81.7645 10.1178 97.601 7.30532 108.955C6.42644 112.503 1.92619 122.309 14.2145 126.671C24.0452 130.161 38.2833 126.043 44.1735 123.548C43.4427 120.417 42.3773 112.86 43.9619 107.679C41.6855 107.908 33.4385 108.527 28.9813 107.345M26.9373 106.475C27.4423 106.838 28.1451 107.123 28.9813 107.345M28.9813 107.345C29.1843 106.018 29.7227 103.135 30.2527 102.212M39.0855 66.5529L42.4026 83.5677C42.7878 84.0397 44.0367 84.8763 45.9507 84.4466C47.8648 84.0169 45.687 85.5111 44.3589 86.3119C44.0638 86.4898 43.6692 87.1201 44.4517 88.2178" stroke="#00A5E9" />
                <path d="M65.7866 103.3C64.447 98.8256 63.3969 88.7742 69.9124 84.3628C76.428 79.9513 77.9961 91.7629 77.9657 98.2201" stroke="#4F45BD" />
                <path d="M44.4058 89.6566C42.9263 81.9467 41.5519 65.4507 47.8904 61.146C54.2288 56.8414 61.3799 60.6588 64.1631 63.1056C66.3842 65.2877 70.806 72.4711 70.724 83.7483" stroke="#4F45BD" />
                <path d="M48.8252 60.6809L51.5091 51.3662L63.8414 55.5507C63.8322 58.1218 63.7884 63.8224 63.6868 66.0568C58.666 72.9474 51.8141 69.3923 49.0157 66.7535L48.8252 60.6809Z" fill="#FFB4B4" />
                <path d="M63.415 63.3537C56.3538 61.454 51.5569 52.9474 50.041 48.9316C51.8775 50.8929 56.207 55.0533 58.8333 56.0051C61.4596 56.9569 63.2663 56.099 63.8414 55.551C63.8604 57.5014 63.8017 61.7925 63.415 63.3537Z" fill="#FF8D8B" />
                <ellipse cx="59.937" cy="42.1537" rx="11.6972" ry="14.63" transform="rotate(9.27277 59.937 42.1537)" fill="#FFB4B4" />
                <ellipse cx="44.4162" cy="92.1634" rx="1.82769" ry="3.65538" transform="rotate(13.9123 44.4162 92.1634)" fill="#4F45BD" />
                <ellipse cx="65.6293" cy="102.139" rx="0.913846" ry="1.82769" transform="rotate(-20.577 65.6293 102.139)" fill="#4F45BD" />
                <ellipse cx="77.6772" cy="97.5915" rx="0.913846" ry="1.82769" transform="rotate(0.377143 77.6772 97.5915)" fill="#4F45BD" />
                <ellipse cx="43.9736" cy="92.4303" rx="1.09662" ry="1.82769" transform="rotate(13.9123 43.9736 92.4303)" fill="white" />
                <circle cx="60.8713" cy="34.8561" r="0.731077" transform="rotate(13.9123 60.8713 34.8561)" fill="#FF0000" />
                <path d="M60.5281 40.0429C60.3951 40.0727 60.2898 40.5488 60.9317 42.2141C61.7341 44.2958 62.7985 44.5594 63.0654 45.0022C63.3323 45.4449 62.8912 46.4653 61.3841 46.4686C60.1785 46.4712 59.3443 46.0889 59.0779 45.8974" stroke="#FF8D8B" stroke-linecap="round" />
                <path d="M57.0884 48.6055C57.4296 49.2549 58.4048 50.5885 59.5753 50.7278C60.7459 50.8672 61.5995 50.6644 61.88 50.5455" stroke="#FF8D8B" stroke-linecap="round" />
                <circle cx="58.5856" cy="12.4478" r="6.21415" transform="rotate(13.9123 58.5856 12.4478)" fill="black" />
                <path d="M53.1943 37.6748C53.7192 38.4324 55.2594 39.6927 57.2221 38.6725" stroke="black" stroke-opacity="0.2" stroke-linecap="round" />
                <path d="M64 39.0234C64.8515 39.4845 66.9315 39.9647 68.4395 38.1965" stroke="black" stroke-opacity="0.2" stroke-linecap="round" />
                <path d="M58.6952 26.5204C58.2527 31.0711 51.2026 35.3119 45.9542 38.0862C43.6324 39.3136 40.7314 38.3646 39.5333 36.0276C38.7615 34.5222 38.8575 32.7183 39.7847 31.3033L43.8566 25.0891C45.5603 22.4891 47.9268 20.3906 50.7119 19.01L53.3237 17.7153C57.0457 15.8703 61.3358 15.5312 65.3015 16.7686C68.8777 17.8844 71.992 20.1845 73.9033 23.4065C77.5593 29.5698 81.644 37.8441 76.9922 37.7197C72.0302 37.587 64.8916 30.2974 61.3384 25.8045C60.6179 24.8933 59.0102 25.1771 58.7309 26.3047C58.7134 26.3756 58.7023 26.4476 58.6952 26.5204Z" fill="#030406" stroke="#030406" />
                <path d="M60.5586 22.7275C60.9948 26.2876 63.0905 33.3342 67.9837 33.0399C72.8769 32.7456 72.2697 33.4739 71.3545 33.8749" stroke="#030406" />
                <circle cx="47.4727" cy="39.0683" r="2.19323" transform="rotate(13.9123 47.4727 39.0683)" fill="#FFB4B4" />
                <circle cx="71.9385" cy="37.5967" r="2.19323" transform="rotate(13.9123 71.9385 37.5967)" fill="#FFB4B4" />
                <path d="M47.4974 41.3376C47.689 40.5681 48.1353 42.0384 48.107 44.9945C48.089 46.8787 46.0048 46.9371 46.197 45.0626C46.4578 42.5172 47.1851 42.5917 47.4974 41.3376Z" stroke="#717EF0" />
                <path d="M71.8631 40.1766C71.7981 39.3862 72.6919 40.636 73.6122 43.4454C74.1988 45.236 72.2432 45.959 71.8246 44.1218C71.2563 41.627 71.9691 41.4646 71.8631 40.1766Z" stroke="#717EF0" />
                <path d="M47.7802 37.8273C48.3125 37.9591 48.5963 39.0819 48.2052 39.3369" stroke="#FF8D8B" stroke-linecap="round" />
                <path d="M71.5193 38.2822C71.1109 37.9162 71.3866 36.7914 71.8515 36.7495" stroke="#FF8D8B" stroke-linecap="round" />
              </svg>
            </div>
          </div>
          <div className="flex justify-around text-sm ml-5" style={{ borderRadius: "5px", alignItems: "center" }}>
            <div
              className="pl-3 space2"
              style={{ color: "rgba(0, 0, 0, 1)", marginRight: "1px", marginLeft: "6px" }}><p className="text text-sm pt-3">Ready for your next consulatation?</p>
              <div className="text" style={{ lineHeight: "14px", fontSize: "10px", paddingBottom: "10px", color: store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic) }}>We will connect you with your next patient</div>
            </div>
            <svg style={{ width: "69px", height: "30px" }} className="mb-3 mt-3 mr-1 space" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14" cy="14" r="14" fill={store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)} />
              <path d="M11 8L17 14L11 20" stroke="white" stroke-width="2" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}));

export default NextPatientCard;
