import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import leftarrow_black from "../Assets/leftarrow_black.svg"
import { Form, Button, message, Spin } from "antd";
import { inject, observer } from 'mobx-react'
import cloud from "../Assets/cloud.svg"
import cross from "../Assets/redCross.svg"
import axios from 'axios';

const Signature = inject("store")(observer(({ store, otherProp }) => {

    const history = useHistory();
    const [image, setImage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [url, setUrl] = useState(null);
    const [docData, setDocData] = useState([]);
    var form_data = new FormData();
    console.log(image);

    useEffect(() => {
        axios
            .get("api/doc/doctors/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.access,
                },
            })
            .then((res) => {
                if (res.data.status) {
                    if (res.data.data) {
                        setDocData(res.data.data);
                    }
                }
            })

    }, [])

    console.log({ docData });

    const onImageChange = (event) => {
        setImage(event.target.files[0])
        if (event.target.files && event.target.files[0]) {
            setUrl(URL.createObjectURL(event.target.files[0]));
        }
    }

    const handleSubmit = async () => {
        if (image.length === 0) {
            // message.error({ content: "Please Select File", key, duration: 1 });
            setError(true);
        }
        else {
            setLoading(true);
            form_data.append("sign", image);

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + localStorage.access,
                },
            };

            await axios
                .post(`api/doc/doctor-sign/`, form_data, config)
                .then((response) => {
                    if (response.data.status) {
                        setLoading(false);
                        message.success("Upload successfully");
                        history.push("/doctorhome")
                    }
                    console.log(response);
                })
                .catch((err) => {
                    setLoading(false);
                    message.error("something went wrong, please try again!!")
                    console.log(err);
                });
        }
    };

    return  (
            <div className="relative">
                <div className="justify-start pt-12 pl-5 " >
                    <img onClick={() => history.goBack()} src={leftarrow_black} alt="" />
                </div>
                <div
                    className="mb-8 mx-auto pt-2 pb-1 px-0.5"
                    style={{
                        marginTop: "91px",
                        width: "80%",
                    }}
                >
                    <h2 className=" text-xl font-medium pt-2 md:text-5xl sm:text-3xl" >Upload your digital signature</h2>
                    <h2 className=" text-sm font-medium pt-2 sm:text-xl" >*The signature will be used in prescriptions to assure the patient.</h2>
                </div>
                <div style={{ width: "87%", }} className="text-center mt-14 mx-auto">
                    <Form name="nest-messages">
                        <div
                            className="h-40 mx-auto pt-10 pb-1 px-0.5 relative"
                            style={{
                                borderRadius: "5px",
                                marginTop: "15px",
                                background: "#C4C4C4",
                                backgroundImage: url ? `url(${url})` : "",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                width: "92%",
                            }}
                        >
                            {url === null &&
                                <>
                                    <input type="image" src={cloud} alt="" />
                                    <input style={{ opacity: "0", width: "100%" }} onChange={onImageChange} type="file" className="bg-red-600 absolute top-0 h-44 mx-auto" accept="image/*" src={cloud} />
                                    {!error ? <p>Upload Signature</p> : <p>Retry Upload !</p>}
                                </>}
                        </div>
                        {error &&
                            <><div
                                className="flex justify-around mx-auto px-3 py-1 h-8 mt-7"
                                style={{
                                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                                    borderRadius: "5px",
                                    maxWidth: "220px",
                                    alignItems: "center",
                                }}
                            >
                                <img src={cross} alt="" />
                                <p className="text-sm font-normal">Upload unsuccessful</p>
                            </div>
                                <p className="mt-5">*Image needs to be less than 5mb.</p></>
                        }
                    </Form>
                </div>

                <div className="h-28"></div>
                <Button
                    onClick={() => handleSubmit()}
                    className="fixed  bottom-0 left-0 w-full mt-2 text-xl"
                    style={{
                        background: store.color,
                        borderRadius: "2px",
                        color: "white",
                        height: "70px",
                    }}
                >
                    {"Upload"}
                </Button>
                {
                    loading? (
        <div
            style={{
                width:"100%",
                margin: "0 auto",
                padding: "0",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                opacity:"0.75",
                position:"absolute",
                top:"0",
            }}
        >
            <Spin tip="Uploading..."></Spin>
        </div>
    ) :""
       
                }
            </div>
        )
}))

export default Signature
