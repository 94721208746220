import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Colors, HexColors } from '../Utils/colors'

const DoctorPublication = inject("store")(observer(({ store, topic,content,doctor,img }) =>  {
    return (
        <div>
            <div className="md:flex mt-2 justify-around" style={{   borderRadius: "16.3px" }}>
                <div className="flex " style={{border: "1px solid", borderColor: `${store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)}`, borderRadius: "10px", alignItems: "center", boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.04)" }}>
                    <div className="flex justify-around text-sm" style={{ borderRadius: "5px", alignItems: "center" }}>
                        <div className="pl-2">
                            <p className="mb-1 pt-2  text-xs" style={{ color: "rgba(210, 210, 210, 1)" }}>{topic}</p>
                            <p className="font-medium md:w-72  text-sm mb-2 ">{content}</p>
                            <p className="pb-2  text-xs" style={{ color: "rgba(210, 210, 210, 1)" }}>{doctor}</p>
                        </div>
                    </div>
                    <div className="pr-3 md:p-1" style={{ borderRadius: "10px" }}>
                        <img src={img} className=" md:w-32 w-72" style={{ borderRadius: "10px"}} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )

}))
export default DoctorPublication
