import React from 'react'
import "./Profile/style/doctor_style.css";
function DoctorProfileContents({ title, content }) {
    return (
        <div className="mx-auto my-3"  id="profile_contents" >
            <div
                className="rounded-xl "
                style={{ boxShadow: "0px 4px 42px rgba(0, 0, 0, 0.07)" }}>
                <div className=" py-1 pl-5" id="title_content">
                    <p className="font-normal text-m mt-1">{title}</p>
                    <p className="font-normal text-xs" >{content}</p>
                </div>
            </div>
        </div>
    )
}

export default DoctorProfileContents
