import React, { useEffect, useState } from "react";
import DoctorPatients from "../DoctorHome/DoctorPatients";
import DoctorProfileContents from "../DoctorProfileContents";
import DoctorPublication from "../DoctorPublication";
import Image2 from "../../Assets/Image2.png";
// import editform from "../../Assets/editform.svg";
import Edit2 from "../../Assets/Edit2.svg";
import { inject, observer } from "mobx-react";
import { Colors, HexColors } from "../../Utils/colors";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./style/doctor_style.css";
import { Modal, Spin } from "antd";
import DocSideBar from "../Headers/Doctorsidebar";
import editform from "../../Assets/editform.svg";
const DoctorProfile = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();

    const [Data, setData] = useState([" "]);
    const [Info, setInfo] = useState([" "]);
    const [List, setList] = useState([" "]);
    const [spec, setSpec] = useState("");
    const [doc_img, setDoc_img] = useState("");
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setLoading(true);
      axios
        .get("api/doc/doctors/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res.data);
          setInfo(res.data.data.user_data);
          setDoc_img(res.data.data.user_data.profile_pic);
          setData(res.data.data);
          setSpec(res.data.data.specialization);
          setLoading(false);
        });
    }, []);
    // console.log({ Data });
    // console.log({ Info });
    // console.log({ spec });

    useEffect(() => {
      axios
        .get("api/doc/specializations/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setList(res.data.data);
          }
          // console.log(res.data);
        });
    }, []);
    if (loading) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      );
    }

    console.log({ List });
    return (
      <div className="main_div">
        <div className="sidebar">
          <DocSideBar />
        </div>
        <div className="second_main_div">
          <div className="pt-5 pl-5 mt-4" id="goback_doctor">
            <svg
              onClick={() => history.goBack()}
              
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 13L2 7L8 1"
                stroke={
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                    ? HexColors.homeopathic
                    : HexColors.ayurvedic
                }
                stroke-width="2"
              />
            </svg>
          </div>
          <div className="details_div">
            <div
              className=" flex justify-center"
              id="doctor_personal_details"
              style={{ alignItems: "center", marginTop: "-25px" }}
            >
              <div className=" px-4   ">
                <img
                  src={editform}
                  className="edit_doctor"
                  alt=""
                  onClick={() => history.push("/profileediting")}
                />
                <img
                  style={{
                    border: `4px solid ${
                      store.color === Colors.allopathic
                        ? HexColors.allopathic
                        : store.color === Colors.homeopathic
                        ? HexColors.homeopathic
                        : HexColors.ayurvedic
                    }`,
                  }}
                  className="doctor_profile_pic"
                  onClick={() => setVisible(true)}
                  src={
                    doc_img
                      ? doc_img
                      : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                  }
                  // src="https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                  alt="Doctor_image"
                />
              </div>
              {/* #################adding modal for view doctor profile pic ########################  */}
              <Modal
                visible={visible}
                footer={null}
                closable={false}
                bodyStyle={{ width: "90%", margin: "0", padding: "0" }}
                onCancel={() => setVisible(false)}
              >
                <div
                  style={{
                    width: "92%",
                    margin: "0",
                    padding: "0",
                    position: "absolute",
                    top: "0",
                    left: "9px",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "60%" }}
                    className="modal_image"
                    src={
                      doc_img
                        ? doc_img
                        : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                    }
                    alt="view profile"
                  />
                </div>
              </Modal>
              <div
                // style={{ marginTop: "50px" }}
                className=" doctor_display_name"
              >
                <p style={{ fontSize: "29px", fontWeight: "500" }}>
                  {Info.name ? Info.name : "NA"}
                  <br />{" "}
                </p>
                <hr
                  className=" line_breaker  "
                  style={{ border: "1px solid #000000" }}
                />
                <p className=" text-sm font-normal ">
                  {List[spec - 1] ? List[spec - 1].name : "NA"}
                </p>
                <p className=" text-xs font-normal ">
                  {Info.username ? Info.username : "NA"}
                </p>
                <div
                  onClick={() => history.push("/profileediting")}
                  className="flex mt-2 py-1 px-2"
                  id="mobile_edit"
                  style={{
                    boxShadow: "0px 0px 1px 2px rgba(0, 0, 0, 0.03)",
                    borderRadius: "6px",
                  }}
                >
                  <img src={Edit2} alt="" style={{ height: "15px" }} />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#747474",
                      marginLeft: "10px",
                    }}
                  >
                    Edit details
                  </p>
                </div>
              </div>
            </div>

            <div className="doc_patient_publications">
              <div
                style={{ marginTop: "60px" }}
                className="doctor_qualification"
              >
                <DoctorProfileContents
                  title="Availbility"
                  content="4:00pm-6:00pm"
                />
                <DoctorProfileContents
                  title="Location"
                  className="truncate"
                  content={Data.wp ? Data.wp : "NA"}
                />
                <DoctorProfileContents
                  title=" Qualification"
                  content={Data.qualification ? Data.qualification : "NA"}
                />
              </div>
            </div>
          </div>
          <div className="publication_patient_care">
            <div className="patient_under_care">
              <hr className=" mx-7 " id="horizon" />
              <div className=" flex justify-around pt-4 ">
                <p style={{ fontSize: "16px" }}>Patients Under Care</p>
                <p
                  // className="mt-3"
                  style={{
                    color: "#717EF0",
                    textDecorationColor: "#717EF0",
                    textDecorationLine: "underline",
                    fontSize: "10px",
                    marginTop: "3.5vh",
                  }}
                >
                  View all
                </p>
              </div>
              <div className=" w-10/12 mx-auto ">
                <DoctorPatients patientName="Sejal" status="Patient" />
                <DoctorPatients patientName="Sejal" status="Patient" />
                <DoctorPatients patientName="Sejal" status="Patient" />
              </div>
              <hr className=" mx-7 " />
            </div>

            <div className="publications">
              <div className=" flex justify-around  ">
                <p style={{ fontSize: "16px" }}>Your publications</p>
                <p
                  className="mt-1"
                  style={{
                    color: "#717EF0",
                    textDecorationColor: "#717EF0",
                    textDecorationLine: "underline",
                    fontSize: "10px",
                    marginTop: "4vh",
                  }}
                >
                  View all
                </p>
              </div>
              <div className="mx-5">
                <DoctorPublication
                  topic="Covid care"
                  content="Must have vitamins when you are infected with the Novel Corona Virus !"
                  doctor="Dr Senthi"
                  img={Image2}
                />
                <DoctorPublication
                  topic="Covid care"
                  content="Must have vitamins when you are infected with the Novel Corona Virus !"
                  doctor="Dr Senthi"
                  img={Image2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default DoctorProfile;
