import React from 'react'
import { inject, observer } from "mobx-react";
import './styles/screen4.css'
import Successful from '../../Assets/Successful.png'

const Successfull = inject("store")(
    observer(({ store, data }) => {

        return (
            <div className="successful-main">
                <div className="successful">
                    <div className="flex justify-center successful-image"><img src={Successful} alt='' /></div>
                    <div className="successful-text">Appointment Successfully
                        Booked</div>
                </div>

            </div>
        );
    })
);

export default Successfull;