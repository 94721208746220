import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Form, Input, Button, Select, message } from "antd";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../Styles/form.css";
import { Colors, HexColors } from "../Utils/colors";
import avatar from "../Assets/form/avatar.svg";
import quali from "../Assets/form/quali.svg";
import speci from "../Assets/form/spec.svg";
import phone from "../Assets/form/phone.svg";
import wp_add from "../Assets/form/wp_add.svg";
import wp_pin from "../Assets/form/wp_pin.svg";
import id from "../Assets/form/id.svg";
import email1 from "../Assets/form/email.svg";
import info1 from "../Assets/info.svg";
import cross from "../Assets/cross.svg";

const DoctorForm = inject("store")(
  observer(({ store, otherProp }) => {
    // var mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const Option = Select.Option;
    const history = useHistory();
    const [error, setError] = useState(false);
    const [mobDis, setMobDis] = useState(false);
    const [emaDis, setEmaDis] = useState(false);
    const [spec, setSpec] = useState("");
    const [medicine, setMedicine] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [name, setName] = useState("");
    const [qualification, setQualification] = useState("");
    const [specializations, setSpecializations] = useState([]);
    const [info, setInfo] = useState(false);

    useEffect(() => {
      axios
        .get("api/auth/user/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.data.email) {
              setEmail(res.data.data.email);
              setEmaDis(true);
            }
            if (res.data.data.mobile) {
              setMobile(res.data.data.mobile.substr(2));
              setMobDis(true);
            }
          }
        });
      axios
        .get("api/doc/specializations/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            setSpecializations(res.data.data);
          }
        });
    }, []);

    let emailcheck = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    const [patientData, setPatientData] = useState({
      // name: "",
      // qualification: "",
      wp: "",
      wp_pincode: "",
      gender: "1",
      reg_id: "",
    });
    const { wp, wp_pincode, gender, reg_id } = patientData;
    const onChange = (e) =>
      setPatientData({ ...patientData, [e.target.name]: e.target.value });

    const checkPincode = async (val) => {
      setPatientData({ ...patientData, wp_pincode: val });
      if (val.length === 6) {
        let res = await axios.get(
          `https://api.postalpincode.in/pincode/${val}`
        );
        let Message = await res.data[0].Message;
        if (res && Message === "No records found") {
          setPatientData({ ...patientData, wp_pincode: "" });
          message.error("Oops!! Wrong Pincode");
        }
      }
    };

    const handleSubmit = () => {
      if (
        name === "" ||
        mobile.length < 10 ||
        qualification === "" ||
        patientData.wp_pincode === "" ||
        email === "" ||
        patientData.wp === "" ||
        patientData.medicine === "" ||
        spec === "" ||
        patientData.reg_id.length < 12 ||
        patientData.gender === "" ||
        !email.trim().match(emailcheck)
      ) {
        setError(true);
      } else {
        const data = {
          qualification: qualification,
          wp: patientData.wp,
          wp_pincode: patientData.wp_pincode,
          doc_field: parseInt(medicine),
          specialization: parseInt(spec),
          reg_id: patientData.reg_id,
          sign: null,
          user_data: {
            name: name,
            email: email,
            gender: parseInt(patientData.gender),
            mobile: mobile,
          },
        };
        console.log(data);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/doc/doctors/", body, config)
          .then((response) => {
            console.log(response);
            if (response.data.status) {
              message.success("Registration successful");
              history.push("/sign");
            }
            console.log(response);
          })
          .catch((err) => {
            message.error("something went wrong, please try again!!");
            console.log("error", err.response);
          });
      }
    };

    return (
      <>
        <div className="">
          <div className="pl-8 pb-2 pt-4 mt-4">
            <svg
              onClick={() => history.goBack()}
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 13L2 7L8 1"
                stroke={
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                    ? HexColors.homeopathic
                    : HexColors.ayurvedic
                }
                stroke-width="2"
              />
            </svg>
          </div>
          <h1 className="pt-4 pl-12 font-medium  md:text-center md:text-2xl md:mb-6">
            Kindly Enter the following details
          </h1>

          <div className="text-center">
            <p
              style={{ visibility: !error ? "hidden" : "visible" }}
              className=" text-red-500 text-xs pt-3 "
            >
              *Kindly fill the required fields{" "}
            </p>
            <Form
              name="nest-messages"
              className="grid md:grid-cols-2 grid-cols-1"
            >
              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={avatar} alt="" />
                </div>
                <Input
                  name="name"
                  type="text"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && name.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(event) => {
                    if (
                      event.target.value.match(/^[A-Za-z]+$/) ||
                      event.target.value === ""
                    ) {
                      setName(event.target.value);
                    }
                  }}
                  // onChange={(e) => onChange(e)}
                  value={name}
                  placeholder="Name "
                  autoFocus
                />
              </div>

              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={phone} alt="" />
                </div>
                <Input
                  name="mobile"
                  type="number"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && mobile.length < 10 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none text-black text-xs  font-normal "
                  onChange={(e) =>
                    e.target.value.length > 10 ? "" : setMobile(e.target.value)
                  }
                  value={mobile}
                  disabled={mobDis}
                  placeholder="Mobile Number "
                  maxLength={10}
                />
              </div>

              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={speci} alt="" />
                </div>
                <Select
                  bordered={false}
                  className="mx-auto"
                  placeholder="Types of medicines"
                  style={{
                    cursor: "pointer",
                    textAlign: "left",
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && medicine.length === 0 ? "1px solid red" : "none",
                  }}
                  onSelect={(e) => {
                    setSpec(e);
                    setMedicine(e);
                  }}
                  name="medicine"
                  value={medicine || "Types of Medicine"}
                >
                  <Option value="1">Ayurvedic</Option>
                  <Option value="2">Homeopathic</Option>
                  <Option value="3">Allopathic</Option>
                </Select>
              </div>

              {medicine === "3" && (
                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "18.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={speci} alt="" />
                  </div>
                  <Select
                    bordered={false}
                    className="mx-auto"
                    title="Specialisation"
                    style={{
                      cursor: "pointer",
                      textAlign: "left",
                      color: "black",
                      width: "16.25rem",
                      border:
                        error && spec.length === 0 ? "1px solid red" : "none",
                      fontSize: "12px !important",
                    }}
                    onSelect={(e) => setSpec(e)}
                    name="spec"
                    value={
                      specializations.length > 0
                        ? specializations[spec - 1].name
                        : "Specialization"
                    }
                  >
                    {specializations.map(
                      (eachItem) =>
                        eachItem.id !== 1 &&
                        eachItem.id !== 2 && (
                          <Option value={eachItem.id}>{eachItem.name}</Option>
                        )
                    )}
                  </Select>
                </div>
              )}

              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={quali} alt="" />
                </div>
                <Input
                  name="qualification"
                  type="text"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && qualification.length === 0
                        ? "1px solid red"
                        : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  // onChange={(e) => onChange(e)}
                  onChange={(event) => {
                    if (
                      event.target.value.match(/^[A-Za-z]+$/) ||
                      event.target.value === ""
                    ) {
                      setQualification(event.target.value);
                    }
                  }}
                  value={qualification}
                  placeholder="Qualification "
                />
              </div>

              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={wp_add} alt="" />
                </div>
                <Input
                  name="wp"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border: error && wp.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => onChange(e)}
                  value={wp}
                  placeholder="Workplace Address"
                />
              </div>

              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={wp_pin} alt="" />
                </div>
                <Input
                  type="number"
                  name="wp_pincode"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      error && wp_pincode.length === 0
                        ? "1px solid red"
                        : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="h-8 mx-auto outline-none pl-2  text-black text-xs  font-normal "
                  onChange={(e) =>
                    e.target.value.length > 6
                      ? ""
                      : checkPincode(e.target.value)
                  }
                  value={wp_pincode}
                  placeholder=" Workplace Pincode"
                  maxLength={6}
                />
              </div>

              <div
                className="flex justify-around text-sm mx-auto"
                style={{ width: "18.25rem", fontSize: "12px" }}
              >
                <div
                  className="h-8 flex outline-none px-2 text-black mr-3"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "12px",
                    marginTop: "11px",
                    marginRight: "13px",
                    alignItems: "center",
                  }}
                >
                  <input
                    checked={gender === "1"}
                    type="radio"
                    value="1"
                    name="gender"
                    onChange={(e) => onChange(e)}
                    className="mr-1.5 ml-2 "
                  />
                  <p className="pr-2" style={{ color: "#8F8F8F" }}>
                    Male
                  </p>
                </div>
                <div
                  className=" h-8 flex outline-none px-2 my-2 text-black"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "12px",
                    marginTop: "11px",
                    marginRight: "13px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    value="2"
                    name="gender"
                    onChange={(e) => onChange(e)}
                    className="mr-1 ml-2"
                  />
                  <p className="pr-1.5" style={{ color: "#8F8F8F" }}>
                    Female
                  </p>
                </div>
                <div
                  className="h-8 flex outline-none px-2 my-2 text-black pr-1"
                  style={{
                    boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                    marginBottom: "12px",
                    marginTop: "11px",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    value="3"
                    onChange={(e) => onChange(e)}
                    name="gender"
                    className="mr-1.5 ml-2"
                  />
                  <p className="pr-1.5" style={{ color: "#8F8F8F" }}>
                    Others
                  </p>
                </div>
              </div>

              <div
                className="flex mx-auto my-3 pr-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={id} alt="" />
                </div>
                <Input
                  name="reg_id"
                  style={{
                    color: "black",
                    width: "14.40rem",
                    border:
                      error && reg_id.length < 12 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) =>
                    e.target.value.length > 12 ? "" : onChange(e)
                  }
                  value={reg_id}
                  placeholder="Registration ID"
                  type="number"
                />
                <input
                  onClick={() => setInfo(!info)}
                  type="image"
                  src={info1}
                  alt=""
                />
              </div>

              <div
                className="flex mx-auto my-3"
                style={{
                  width: "18.25rem",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "2px",
                  marginBottom: "11px",
                  marginTop: "12px",
                }}
              >
                <div
                  className="bg-blue-400 h-8"
                  style={{
                    width: "2rem",
                    background: "#EEEEEE",
                    borderRadius: "2px 0px 0px 2px",
                  }}
                >
                  <img className=" mx-auto my-2" src={email1} alt="" />
                </div>
                <Input
                  name="email"
                  style={{
                    color: "black",
                    width: "16.25rem",
                    border:
                      email.length === 0 && error
                        ? "1px solid red"
                        : email
                        ? !email.trim().match(emailcheck)
                          ? "1px solid red"
                          : "none"
                        : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  disabled={emaDis}
                  placeholder="Email ID"
                  type="email"
                />
              </div>
            </Form>
          </div>
          {info && (
            <div
              className="mx-auto my-3 py-3 px-2"
              style={{
                background: "#EEEEEE",
                width: "20rem",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "2px",
                marginBottom: "11px",
                marginTop: "12px",
              }}
            >
              <div className="flex">
                <p className="font-semibold">
                  UPRN (unique permanent registration number)
                </p>
                <input
                  className="w-2 h-2"
                  onClick={() => setInfo(!info)}
                  type="image"
                  src={cross}
                  alt=""
                />
              </div>
              <p className="text-justify">
                The Medical Council of India (MCI) has directed all states to
                provide a <b>12 Digit</b> unique permanent registration number
                (UPRN) to every doctor registered in their jurisdiction.
              </p>
            </div>
          )}
          <div className="h-28"></div>
          <Button
            onClick={() => handleSubmit()}
            className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl"
            style={{
              background: store.color,
              borderRadius: "2px",
              color: "white",
              height: "70px",
            }}
          >
            Next
          </Button>
        </div>
      </>
    );
  })
);

export default DoctorForm;
