import React,{useEffect,useState} from 'react'
import { useHistory } from 'react-router-dom'
import Blackarrow from '../Assets/Blackarrow.png'
import CommunityCard from '../Components/CommunityCard'
import axios from 'axios'

function DoctorCommunityMyUploads() {
    const history = useHistory();
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        axios
            .get("api/vol/my-commmunity/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.access,
                },
            })
            .then((res) => {
                console.log(res.data.data);
                if (res.data.data) {
                    setBlogs(res.data.data);
                }
            }).catch(() => {
                console.log("Blogs not get")
            })

    }, [])
    return (
        <div>
            <div onClick={() => history.goBack()}><img className="pl-2 pb-2 pt-4 mt-5 ml-5" src={Blackarrow} alt="" /></div>
            <p className="text-2xl mt-3 ml-10">My Uploads</p>
            {blogs.map((blog, index) => (<>
                        <CommunityCard key={index} data={blog} />
                        <button>Edit</button></>
                    ))}
        </div>
    )
}

export default DoctorCommunityMyUploads
