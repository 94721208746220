import React, { useEffect } from "react";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { Form, Input, Button, Select, message } from "antd";
import { Modal, Spin } from "antd";
import ImageUploader from "react-images-upload";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import avatar from "../../Assets/form/avatar.svg";
import quali from "../../Assets/form/quali.svg";
import speci from "../../Assets/form/spec.svg";
import phone from "../../Assets/form/phone.svg";
import wp_add from "../../Assets/form/wp_add.svg";
import wp_pin from "../../Assets/form/wp_pin.svg";
import id from "../../Assets/form/id.svg";
import email1 from "../../Assets/form/email.svg";
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import axios from "axios";
import editform from "../../Assets/editform.svg";
import "../../Styles/form.css";
import { Colors, HexColors } from "../../Utils/colors";
import "./style/doctor_profile_editing.css";
import DocSidebar from "../Headers/Doctorsidebar";

const DoctorEditingform = inject("store")(
  observer(({ store, otherProp }) => {
    const Option = Select.Option;
    const history = useHistory();
    const [error, setError] = useState(false);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [spec, setSpec] = useState("");
    const [qualification, setQualification] = useState("");
    const [medicine, setMedicine] = useState("");
    const [specializations, setSpecializations] = useState([]);
    const [wp, setWp] = useState("");
    const [wp_pincode, setWp_pincode] = useState("");
    const [gender, setGender] = useState("1");
    const [reg_id, setReg_id] = useState("");
    const [email, setEmail] = useState("");
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [doc_img, setdoc_img] = useState("");

    useEffect(() => {
      axios
        .get("api/doc/specializations/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.data) {
            setSpecializations(res.data.data);
          }
        });
      setLoading2(true);
      axios
        .get("api/doc/doctors/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.status) {
            if (res.data.data) {
              setSpec(res.data.data.specialization.toString());
              setMedicine(res.data.data.specialization.toString());
              setQualification(res.data.data.qualification);
              setWp(res.data.data.wp);
              setWp_pincode(res.data.data.wp_pincode);
              setReg_id(res.data.data.reg_id);
              setName(res.data.data.user_data.name);
              setMobile(res.data.data.user_data.mobile);
              setGender(res.data.data.user_data.gender.toString());
              setEmail(res.data.data.user_data.email);
              setdoc_img(res.data.data.user_data.profile_pic);
            }
          }
          setLoading2(false);
        });
    }, []);

    const checkPincode = async (val) => {
      setWp_pincode(val);
      if (val.length === 6) {
        let res = await axios.get(
          `https://api.postalpincode.in/pincode/${val}`
        );
        let Message = await res.data[0].Message;
        if (res && Message === "No records found") {
          setWp_pincode("");
          message.error("Oops!! Wrong Pincode");
        }
      }
    };

    const handleSubmit = () => {
      if (
        name === "" ||
        mobile.length < 10 ||
        qualification === "" ||
        wp_pincode === "" ||
        email === "" ||
        wp === "" ||
        spec === "" ||
        reg_id === "" ||
        gender === ""
      ) {
        setError(true);
      } else {
        const data = {
          qualification: qualification,
          wp: wp,
          wp_pincode: wp_pincode,
          doc_field: parseInt(localStorage.doctor_type),
          specialization: parseInt(spec),
          reg_id: reg_id,
          user_data: {
            name: name,
            email: email,
            gender: parseInt(gender),
            mobile: mobile,
          },
        };
        console.log(data);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/doc/doctors/", body, config)
          .then((response) => {
            if (response.data.status) {
              message.success("Profile Changed successfully");
              history.push("/doctorhome");
            }
            console.log(response);
          })
          .catch((err) => {
            message.error("something went wrong, please try again!!");
            console.log(err);
          });
      }
    };

    // ###############################doctor profile updating code #############################
    const updateProfile = async (doc_image) => {
      setLoading(true);
      var form_data = new FormData();
      form_data.append("profile_pic", doc_image[0]);
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.access,
        },
      };
      await axios
        .post("api/auth/user/", form_data, config)
        .then((res) => {
          console.log(res);
          message.success("Profile Picture Updated");
          setVisible(false);
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (loading) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Uploading...."></Spin>
        </div>
      );
    }

    if (loading2) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Loading...."></Spin>
        </div>
      );
    }

    // ###############################doctor profile updating code #############################

    return (
      <>
        <div className="main_div">
          <div className="sidebar">
            <DocSidebar />
          </div>
          <div className="flex justify-start" style={{ alignItems: "center" }}>
            <div onClick={() => history.goBack()} className="mt-4 pt-5 pl-5">
              <img
                className=" text-black "
                src={leftarrow_black}
                alt=""
                id="goback"
              />
            </div>
            <h2
              className=" text-base pl-5 font-medium md:text-center md:text-2xl"
              id="profile_heading"
            >
              Edit Profile
            </h2>
          </div>
          <div className="second_div">
            <div className=" flex justify-center pt-5 " id="profile_div">
              <img
                style={{
                  border: `4px solid ${
                    store.color === Colors.allopathic
                      ? HexColors.allopathic
                      : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                  }`,
                }}
                className="doctor_editing_profile"
                src={
                  doc_img
                    ? doc_img
                    : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                }
                // src="https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
                alt=""
              />
              <img
                onClick={() => setVisible(true)}
                className="openFile"
                // style={{ marginLeft: "-12vw" }}
                src={editform}
                alt=""
              />
            </div>

            {/*########################## doctor profile picture uploading modal ####################*/}
            <Modal
              visible={visible}
              footer={null}
              onCancel={() => setVisible(false)}
              bodyStyle={{ width: "90%", margin: "0", padding: "0" }}
            >
              <div
                className="upload_image"
                style={{
                  margin: "0",
                  padding: "0",
                  position: "absolute",
                  top: "0",
                  height: "30vh",
                }}
              >
                <ImageUploader
                  buttonText={"Select"}
                  onChange={(e) => updateProfile(e)}
                  label="Choose Your Profile Picture"
                  fileSizeError="File is Too Big"
                  fileTypeError="This type of file is not supported"
                  imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                  maxFileSize={5242880}
                  singleImage={true}
                />
              </div>
            </Modal>
            {/*########################## doctor profile picture uploading modal ####################*/}
            <div className="text-center">
              <p
                style={{ visibility: !error ? "hidden" : "visible" }}
                className=" text-red-500 text-xs pt-3 "
              >
                *Kindly fill the required fields{" "}
              </p>
              <Form
                name="nest-messages"
                className="grid md:grid-cols-2 grid-cols-1"
                id="doctor_from"
              >
                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={avatar} alt="" />
                  </div>
                  <Input
                    name="name"
                    type="text"
                    style={{
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && name.length === 0 ? "1px solid red" : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Name"
                  />
                </div>

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={phone} alt="" />
                  </div>
                  <Input
                    name="mobile"
                    type="tel"
                    style={{
                      color: "black",
                      borderRadius: "2px",
                      width: "19.25rem",
                      border:
                        error && mobile.length < 10 ? "1px solid red" : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    className="mx-auto h-8 outline-none text-black text-xs  font-normal "
                    onChange={(e) =>
                      e.target.value.length > 10
                        ? ""
                        : setMobile(e.target.value)
                    }
                    value={mobile}
                    placeholder="Mobile Number "
                    maxLength={10}
                  />
                </div>

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={speci} alt="" />
                  </div>
                  <Select
                    bordered={false}
                    className="mx-auto"
                    placeholder="Types of medicines"
                    style={{
                      cursor: "pointer",
                      textAlign: "left",
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && medicine.length === 0
                          ? "1px solid red"
                          : "none",
                    }}
                    onSelect={(e) => {
                      setSpec(e);
                      setMedicine(e);
                    }}
                    name="medicine"
                    value={medicine || "Types of Medicine"}
                  >
                    <Option value="1">Ayurvedic</Option>
                    <Option value="2">Homeopathic</Option>
                    <Option value="3">Allopathic</Option>
                  </Select>
                </div>

                {medicine === "3" && (
                  <div
                    className="flex mx-auto my-3"
                    style={{
                      width: "21.25rem",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                      borderRadius: "2px",
                      marginBottom: "11px",
                      marginTop: "12px",
                    }}
                  >
                    <div
                      className="bg-blue-400 h-8"
                      style={{
                        width: "2rem",
                        background: "#EEEEEE",
                        borderRadius: "2px 0px 0px 2px",
                      }}
                    >
                      <img className=" mx-auto my-2" src={speci} alt="" />
                    </div>
                    <Select
                      bordered={false}
                      className="mx-auto"
                      title="Specialisation"
                      style={{
                        cursor: "pointer",
                        textAlign: "left",
                        color: "black",
                        width: "19.25rem",
                        border:
                          error && spec.length === 0 ? "1px solid red" : "none",
                        fontSize: "12px !important",
                      }}
                      onSelect={(e) => setSpec(e)}
                      name="spec"
                      value={
                        specializations.length > 0
                          ? specializations[spec - 1].name
                          : "Specialization"
                      }
                    >
                      {specializations.map(
                        (eachItem) =>
                          eachItem.id !== 1 &&
                          eachItem.id !== 2 && (
                            <Option value={eachItem.id}>{eachItem.name}</Option>
                          )
                      )}
                    </Select>
                  </div>
                )}

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={quali} alt="" />
                  </div>
                  <Input
                    required
                    name="qualification"
                    type="text"
                    style={{
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && qualification.length === 0
                          ? "1px solid red"
                          : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    onChange={(e) => setQualification(e.target.value)}
                    value={qualification}
                    placeholder="Qualification "
                  />
                </div>

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={wp_add} alt="" />
                  </div>
                  <Input
                    name="wp"
                    style={{
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && wp.length === 0 ? "1px solid red" : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    maxLength="30"
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    onChange={(e) => setWp(e.target.value)}
                    value={wp}
                    placeholder="Workplace Address"
                  />
                </div>

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={wp_pin} alt="" />
                  </div>
                  <Input
                    type="tel"
                    name="wp_pincode"
                    style={{
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && wp_pincode.length === 0
                          ? "1px solid red"
                          : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    className="h-8 mx-auto  outline-none pl-2  text-black text-xs  font-normal "
                    onChange={(e) =>
                      e.target.value.length > 6
                        ? ""
                        : checkPincode(e.target.value)
                    }
                    value={wp_pincode}
                    placeholder=" Workplace Pincode"
                    maxLength={6}
                  />
                </div>

                <div
                  className="flex justify-around text-sm mx-auto"
                  style={{ width: "21.25rem", fontSize: "12px" }}
                >
                  <div
                    className="h-8 flex outline-none px-2 text-black mr-3"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "11px",
                      marginTop: "12px",
                      marginRight: "13px",
                    }}
                  >
                    <input
                      checked={gender === "1"}
                      style={{ marginTop: "10px" }}
                      type="radio"
                      value="1"
                      name="gender"
                      onChange={(e) => setGender(e.target.value)}
                      className="mr-1.5 ml-2 "
                    />
                    <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                      Male
                    </p>
                  </div>
                  <div
                    className=" h-8 flex outline-none px-2 my-2 text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "11px",
                      marginTop: "12px",
                      marginRight: "13px",
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="radio"
                      value="2"
                      name="gender"
                      onChange={(e) => setGender(e.target.value)}
                      className="mr-1 ml-2"
                    />
                    <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-1.5">
                      Female
                    </p>
                  </div>
                  <div
                    className="h-8 flex outline-none px-2 my-2 text-black pr-1"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "11px",
                      marginTop: "12px",
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="radio"
                      value="3"
                      onChange={(e) => setGender(e.target.value)}
                      name="gender"
                      className="mr-1.5 ml-2"
                    />
                    <p style={{ paddingTop: "7px" }} className="pr-1.5">
                      Others
                    </p>
                  </div>
                </div>

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={id} alt="" />
                  </div>
                  <Input
                    name="reg_id"
                    text="number"
                    style={{
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && reg_id.length === 0 ? "1px solid red" : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    onChange={(e) =>
                      e.target.value.length > 12
                        ? ""
                        : setReg_id(e.target.value)
                    }
                    value={reg_id}
                    placeholder="Registration ID"
                    type="number"
                  />
                </div>

                <div
                  className="flex mx-auto my-3"
                  style={{
                    width: "21.25rem",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    borderRadius: "2px",
                    marginBottom: "11px",
                    marginTop: "12px",
                  }}
                >
                  <div
                    className="bg-blue-400 h-8"
                    style={{
                      width: "2rem",
                      background: "#EEEEEE",
                      borderRadius: "2px 0px 0px 2px",
                    }}
                  >
                    <img className=" mx-auto my-2" src={email1} alt="" />
                  </div>
                  <Input
                    name="email"
                    style={{
                      color: "black",
                      width: "19.25rem",
                      border:
                        error && email.length === 0 ? "1px solid red" : "none",
                      paddingLeft: "12px",
                      paddingright: "12px",
                      fontSize: "12px !important",
                    }}
                    className="mx-auto h-8 outline-none px-5  text-black text-xs  font-normal "
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email ID"
                    type="email"
                  />
                </div>
                <Button
                  onClick={() => handleSubmit()}
                  className="fixed md:static bottom-0 left-0  mt-2 text-xl"
                  id="form_submit_btn"
                  style={{
                    background:
                      store.color === Colors.allopathic
                        ? HexColors.allopathic
                        : store.color === Colors.homeopathic
                        ? HexColors.homeopathic
                        : HexColors.ayurvedic,
                    borderRadius: "2px",
                    color: "white",
                  }}
                >
                  Save
                </Button>
              </Form>
            </div>
            <div className="h-28"></div>
            {/* <Button
              onClick={() => handleSubmit()}
              className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl"
              style={{
                background:
                  store.color === Colors.allopathic
                    ? HexColors.allopathic
                    : store.color === Colors.homeopathic
                    ? HexColors.homeopathic
                    : HexColors.ayurvedic,
                borderRadius: "2px",
                color: "white",
                height: "70px",
              }}
            >
              Save
            </Button> */}
          </div>
        </div>
      </>
    );
  })
);

export default DoctorEditingform;
