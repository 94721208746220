import React, { useState } from 'react'
import leftarrow from "../Assets/leftarrow.svg"
import message1 from "../Assets/message1.svg"
import blueBack2 from "../Assets/blueBack2.png"
import axios from "axios"
import { Input, message } from "antd";
import { useHistory } from 'react-router-dom';

function Email() {
    const [email, setEmail] = useState("");
    const [error, seterror] = useState(false);
    var mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const history = useHistory();

    const handleSubmit = async () => {
        if (email.match(mailFormat)) {
            const data = {
                email: email,
            };

            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const body = JSON.stringify(data);

            axios
                .post("api/auth/email-otp/", body, config)
                .then(res => {
                    console.log(res);
                    if (res.data.status) {
                        localStorage.setItem("rid", res.data.data.rid)

                        history.push('/otpverification', { num: email })
                    } else {
                        console.log("wrong input")
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            message.success("Otp Send to Your Email Id")
        }
        else {
            seterror(true);
            message.error("Please Enter A valid Email address")
        }

    };

    return (
        <div className="  max-w-md  mx-auto text-center  overflow-y-hidden  " style={{ height: "100vh" }} >
            <div onClick={() => history.push("/login")} className=" pt-8 pl-8  " >
                <img src={leftarrow} alt="" />
            </div>

            <div className=" text-center pt-16 " >
                <h1 className=" text-2xl font-medium  " >Enter Your Email id</h1>
                <p className=" text-xs pt-24 px-14" >We will send you an <span className=" font-medium " >One Time Password </span> <br /> to this Email ID</p>
            </div>
            <div className="  flex justify-center rounded-tl-md rounded-bl-md rounded-r-md  mt-4   mx-auto" style={{ alignItems: "center", height: "50px" }}  >
                <div style={{ background: "#8F99FF", height: "50px", borderRadius: "6px 0px 0px 6px" }} className="  px-4  text-center  text-white font-semibold pt-3" >
                    <img src={message1} alt="" />
                </div>
                <Input type="text" autoFocus value={email} onPressEnter={() => handleSubmit()} onChange={(e) => { setEmail(e.target.value) }} style={{ height: "50px", borderRadius: "0px 6px 6px 0px" }} className=" w-72  border-2 pl-2 outline-none " />
            </div>

            <p style={{ visibility: !error ? "hidden" : "visible" }} className=" text-red-500 text-xs pt-2 " >*Please Enter Valid Email Id</p>

            <button onClick={() => handleSubmit()} style={{ color: "#8F99FF", boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)", width: "284px", height: "49px", borderRadius: "10px" }} className=" text-base  mt-12 bg-white  font-semibold  " >Get OTP</button>

            <div className="" style={{ zIndex: "-10", overflowX: "hidden" }} >
                <img src={blueBack2} style={{ maxWidth: "125%" }} alt="" />
            </div>

        </div>
    )
}

export default Email