import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import dot from "../../Assets/Dot.png";
import axios from "axios";
import { Colors, HexColors } from "../../Utils/colors";
import "../Appointment/styles/screen4.css";

const DoctorCard = inject("store")(
  observer(({ store, data }) => {
    const [doc_img, setDoc_img] = useState("");
    const [Info, setInfo] = useState([" "]);
    const [List, setList] = useState([" "]);
    const [spec, setSpec] = useState("");

    useEffect(() => {
      // setLoading(true);
      axios
        .get("api/doc/doctors/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res.data);
          setInfo(res.data.data.user_data);
          setDoc_img(res.data.data.user_data.profile_pic);
          // setData(res.data.data);
          setSpec(res.data.data.specialization);
          // setLoading(false);
        });
    }, []);

    useEffect(() => {
      axios
        .get("api/doc/specializations/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          if (res.data.status) {
            setList(res.data.data);
          }
          // console.log(res.data);
        });
    }, []);
    return (
      <div>
        <div
          className=" flex justify-center profile-doc-appoint"
          style={{ alignItems: "center" }}
        >
          <div>
            <img
              style={{ borderRadius: "50%" }}
              className="profile_pic"
              src={
                doc_img
                  ? doc_img
                  : "https://images.theconversation.com/files/304957/original/file-20191203-66986-im7o5.jpg?ixlib=rb-1.1.0&q=45&auto=format&w=1200&h=1200.0&fit=crop"
              }
              alt="Doctor_image"
            />
          </div>
          <div className="display_name">
            <p style={{ fontSize: "29px", fontWeight: "500" }}>
              {Info.name ? Info.name : "NA"}
              <br />{" "}
            </p>
            <hr
              className=" line_breaker  "
              style={{ border: "1px solid #000000" }}
            />
            <p className="spec text-sm font-normal ">
              {List[spec - 1] ? List[spec - 1].name : "NA"}
            </p>
            <p className=" text-xs font-normal ">
              {Info.username ? Info.username : "NA"}
            </p>
          </div>
        </div>
        <div className="content-appoint-main">
          <div
            className="content-appoint"
            style={{
              border: " 1px solid",
              borderRadius: "9px",
              borderColor: `${
                store.color === Colors.allopathic
                  ? HexColors.allopathic
                  : store.color === Colors.homeopathic
                  ? HexColors.homeopathic
                  : HexColors.ayurvedic
              }`,
            }}
          >
            <div className="flex">
              <div className="flex" style={{ width: "100%" }}>
                <div style={{ marginTop: "0.1rem" }}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 7.5L7 10L11 5"
                      stroke={
                        store.color === Colors.allopathic
                          ? HexColors.allopathic
                          : store.color === Colors.homeopathic
                          ? HexColors.homeopathic
                          : HexColors.ayurvedic
                      }
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <circle
                      cx="7.5"
                      cy="7.5"
                      r="6.9"
                      stroke={
                        store.color === Colors.allopathic
                          ? HexColors.allopathic
                          : store.color === Colors.homeopathic
                          ? HexColors.homeopathic
                          : HexColors.ayurvedic
                      }
                      stroke-width="1.2"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    color: "#484848",
                    fontSize: "12px",
                    marginLeft: "0.5rem",
                  }}
                >
                  Verified and trusted
                </div>
              </div>
              <div className="flex" style={{ width: "57%" }}>
                <div
                  className="font-medium"
                  style={{ fontSize: "16px", marginLeft: "35%" }}
                >
                  ₹ 900
                </div>
                <div
                  style={{ fontSize: "9px", marginLeft: "1%", marginTop: "1%" }}
                >
                  fee
                </div>
              </div>
            </div>
            <div className="flex" style={{ width: "100%", marginTop: "0.5vh" }}>
              <div style={{ marginTop: "0.1rem" }}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.5"
                    cy="7.5"
                    r="6.9"
                    stroke={
                      store.color === Colors.allopathic
                        ? HexColors.allopathic
                        : store.color === Colors.homeopathic
                        ? HexColors.homeopathic
                        : HexColors.ayurvedic
                    }
                    stroke-width="1.2"
                  />
                  <path
                    d="M7 3V7.66667L11 10"
                    stroke={
                      store.color === Colors.allopathic
                        ? HexColors.allopathic
                        : store.color === Colors.homeopathic
                        ? HexColors.homeopathic
                        : HexColors.ayurvedic
                    }
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div
                style={{
                  color: "#484848",
                  fontSize: "12px",
                  marginLeft: "0.5rem",
                }}
              >
                15 years of experience
              </div>
            </div>
            <div
              className="flex"
              style={{ fontSize: "10px", color: "#484848", marginTop: "3%" }}
            >
              <div>
                <svg
                  width="12"
                  height="15"
                  viewBox="0 0 12 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.00098 4.50001C5.17255 4.50001 4.50098 5.17158 4.50098 6.00001C4.50098 6.82843 5.17255 7.50001 6.00098 7.50001C6.8294 7.50001 7.50098 6.82843 7.50098 6.00001C7.50098 5.17158 6.8294 4.50001 6.00098 4.50001ZM3.00098 6.00001C3.00098 4.34315 4.34412 3.00001 6.00098 3.00001C7.65783 3.00001 9.00098 4.34315 9.00098 6.00001C9.00098 7.65686 7.65783 9.00001 6.00098 9.00001C4.34412 9.00001 3.00098 7.65686 3.00098 6.00001Z"
                    fill={
                      store.color === Colors.allopathic
                        ? HexColors.allopathic
                        : store.color === Colors.homeopathic
                        ? HexColors.homeopathic
                        : HexColors.ayurvedic
                    }
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.75736 1.7973C2.88258 0.646508 4.4087 0 6 0C7.5913 0 9.11742 0.646508 10.2426 1.7973C11.3679 2.94809 12 4.5089 12 6.13636C12 6.82951 11.9281 7.44009 11.7406 8.01845C11.5522 8.59984 11.258 9.11477 10.8535 9.63095C10.8482 9.63777 10.8427 9.64448 10.8371 9.65109L6.50379 14.7647C6.37717 14.9142 6.19335 15 6 15C5.80666 15 5.62283 14.9142 5.49621 14.7647L1.16287 9.65109C1.15728 9.64448 1.1518 9.63777 1.14646 9.63095C0.741958 9.11477 0.447795 8.59984 0.259359 8.01845C0.0719086 7.44009 0 6.82951 0 6.13636C0 4.5089 0.632141 2.94809 1.75736 1.7973ZM6 1.36364C4.76232 1.36364 3.57534 1.86648 2.70017 2.76154C1.825 3.6566 1.33333 4.87056 1.33333 6.13636C1.33333 6.73322 1.39542 7.18968 1.52497 7.58939C1.65252 7.98291 1.8561 8.35427 2.17908 8.76817L6 13.2771L9.82092 8.76817C10.1439 8.35427 10.3475 7.98291 10.475 7.58939C10.6046 7.18968 10.6667 6.73322 10.6667 6.13636C10.6667 4.87056 10.175 3.6566 9.29983 2.76154C8.42466 1.86648 7.23768 1.36364 6 1.36364Z"
                    fill={
                      store.color === Colors.allopathic
                        ? HexColors.allopathic
                        : store.color === Colors.homeopathic
                        ? HexColors.homeopathic
                        : HexColors.ayurvedic
                    }
                  />
                </svg>
              </div>
              <div
                style={{
                  color: "#484848",
                  fontSize: "12px",
                  marginLeft: "0.5rem",
                }}
              >
                M.G Road
              </div>
              <div
                style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}
              >
                <img src={dot} alt="" />
              </div>
              <div style={{ color: "#484848", fontSize: "12px" }}>
                Appolo Hospital
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default DoctorCard;
