import React from "react";
import Sidebar from "../Headers/sidebar";
import WebHeader from "../Headers/WebHeader";
import "./styles/appointment.css";
import Offline from "../../Assets/offline.png";
import Online from "../../Assets/online.png";

const Appointment = () => {
  return (
    <div className="flex">
      <div className="websites">
        <Sidebar />
      </div>
      <div className="web p-8 pt-2">
        <WebHeader />
        <div className="mt-10 font-medium md:text-center text-3xl">
          Mode Of Communication
        </div>
        <div className="comm-div flex">
          <div className="comm-image-div flex">
            <div>
              <img className="comm-images" src={Online} alt="" />
            </div>
            <div className="comm-text font-medium text-center text-2xl">
              Online
            </div>
          </div>
          <div className="comm-image-div flex">
            <div>
              <img className="comm-images" src={Offline} alt="" />
            </div>
            <div className="comm-text font-medium text-center text-2xl">
              In person
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
