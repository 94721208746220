import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Blackarrow from '../Assets/Blackarrow.png'
import Single from '../Assets/Single.png'
import write from "../Assets/write.svg"


function CommunitySingleMyUpload() {
    const history = useHistory();
    const { state } = useLocation();
    const { blog } = state;

    return (
        <div className="mx-auto">
            <div onClick={() => history.goBack()}><img className="pl-2 pb-2 pt-4 mt-8 ml-3" src={Blackarrow} alt="" /></div>
            <p className="text-2xl mt-3 ml-10 sm:text-center sm:text-5xl">My Upload</p>
            <div className="flex pl-2 mt-3 mx-auto" style={{ alignItems: "center", width: "85%" }}>
                <img className="inline-block h-8 w-8 rounded-full ring-2 ring-white" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                <div onClick={() => history.push("/login")} className="flex bottom-2 ml-2 text-sm">{localStorage.DocName}
                    <div className="ml-2" style={{ color: "#A7A7A7" }}>{blog.created_at.substring(0, 10).split("-").reverse().join("-")}</div>
                </div>
            </div>
            <div className="mx-auto mt-6">
                <div className="mx-auto relative" style={{ width: "85%" }}>
                    <img className="mx-auto md:h-80" style={{ width: "100%", borderRadius: "5px" }} src={blog.image!==null?blog.image:Single} alt=" " />
                    <img onClick={() => history.push("/doctorCommunityForm", { blog: blog })} className="absolute top-0" src={write} alt="" />
                </div>
                <div className="mx-auto px-2" style={{ width: "85%" }}>
                    <p className="font-semibold text-lg mt-4 md:text-center md:text-3xl md:mb-4">{blog.title}</p>
                    <p className=" mt-2 md:text-xl" style={{ color: "black" }}>{blog.content}</p>
                </div>
            </div>
            <hr className="mx-auto mt-4" style={{ width: "85%" }}></hr>
            <div className="h-10"></div>
        </div>
    )
}

export default CommunitySingleMyUpload
