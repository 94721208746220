import React,{useState} from 'react'
// import leftarrow from '../Assets/leftarrow.svg'
import { inject, observer } from "mobx-react";
import { Colors, HexColors } from '../Utils/colors';

const Doctortype = inject("store")(observer(({ store, otherProp }) => {
    const [doctor_type, setDoctor_type] = useState();

    localStorage.setItem("doctor_type",doctor_type)
    return (
        <div className=" max-w-lg mx-auto text-center p-4" >
            <div className="pl-8 pb-2 pt-4">
                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 13L2 7L8 1" stroke={store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)} stroke-width="2" />
                </svg>
            </div>
            {/* <img className="pl-2 pb-2 pt-4" src={leftarrow} alt="" /> */}
            <div className="pt-20">
                <div className="text-left mx-8 pb-6">
                    <h1 className="font-semibold md:text-2xl text-xl">Type of doctor</h1>
                </div>

                <div onClick={()=>setDoctor_type("1")} className="mx-8 my-4 p-9 text-xs md:text-sm" style={{ boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.10)" }} >Allopathy</div>
                <div onClick={()=>setDoctor_type("2")} className="mx-8 my-4 p-9 px-8 text-center  md:text-sm text-xs" style={{ boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.10)" }}>Homeopathy</div>
                <div onClick={()=>setDoctor_type("3")} className="mx-8 my-4 p-9 md:text-sm text-xs mb-4" style={{ boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.10)" }}>Ayurvedic</div>
            </div>
        </div>
    )
}))
export default Doctortype
