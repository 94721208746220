import React, { useState, useEffect } from 'react'
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import leftarrow_black from "../../Assets/leftarrow_black.svg";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import axios from "axios";

const WebHeader = inject("store")(
    observer(({ store, data }) => {
        const history = useHistory();
        const [user, setUser] = useState({});
        const patient = user;

        useEffect(() => {
            if (localStorage.user_type === null) {
                localStorage.setItem("user_type", 4);
            }

            if (localStorage.access && localStorage.refresh) {
                axios
                    .get("api/auth/user/", {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + localStorage.access,
                        },
                    })
                    .then((res) => {
                        console.log("user", res);
                        if (res.data.status) {
                            setUser(res.data.data);
                            localStorage.setItem("userId", res.data.data.id);
                        }
                    });
            }
        }, []);

        return (
            <div>
                <div className="header-appointment">
                    <div className="flex">
                        <div onClick={() => history.goBack()} style={{ marginTop: "1.7rem" }} className="pt-5 pl-5 cursor-pointer">
                            <img className=" text-black " src={leftarrow_black} alt="" />
                        </div>
                        <div onClick={() => history.goBack()} style={{ marginTop: "1.62rem" }} className="pt-5 pl-3 cursor-pointer">Back</div>
                    </div>
                    <div
                        onClick={() => {
                            history.push("/patientprofile");
                        }}
                        className="flex mt-10 cursor-pointer icon-patient"
                        style={{
                            alignItems: "center",
                            boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.1)",
                            borderRadius: "24px",
                        }}
                    >
                        <div
                            style={{ minWidth: "6.9vw", width: "70px", marginRight: "0.8rem" }}
                            className="bottom-2 pl-2 truncate"
                        >
                            {localStorage.access && localStorage.refresh
                                ? patient
                                    ? patient.name
                                    : "Hello"
                                : "Guest"}
                        </div>
                        {patient.profile_pic ? (
                            <Avatar
                                className="inline-block rounded-full ring-2 ring-white"
                                style={{ height: "2rem", width: "2rem" }}
                                src={patient.profile_pic}
                            />
                        ) : (
                            <Avatar
                                className="inline-block rounded-full ring-2 ring-white"
                                style={{ height: "2rem", width: "2rem" }}
                                // src={patient.profile_pic}
                                icon={<UserOutlined />}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    })
);

export default WebHeader
