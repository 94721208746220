import React from 'react'
import "./style/home.css";
import { Colors, HexColors } from "../../Utils/colors";
import { inject, observer } from "mobx-react";

const WellnessCheckCard = inject("store")(
    observer(({ store, data }) => {
        return (
            <div className="md:flex mt-12 justify-around" style={{width:"95%"}}>
                <div
                    className="flex mx-3 ml-px quiz-content"
                    style={{
                        borderRadius: "10px",
                        alignItems: "center",
                        boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.04)",
                        border: "1px solid #EAEAEA",
                    }}
                >
                    <div
                        className="flex justify-around text-sm pl-3"
                        style={{ borderRadius: "5px", alignItems: "center" }}
                    >
                        <div>
                            <p
                                className="mb-1 font-medium text-lg"
                                style={{
                                    color:
                                        store.color === Colors.allopathic
                                            ? HexColors.allopathic
                                            : store.color === Colors.homeopathic
                                                ? HexColors.homeopathic
                                                : HexColors.ayurvedic,
                                }}
                            >
                                Wellness Check
                            </p>
                            <p className="font-light text-xs mb-2 mr-2">
                                Take our well curated quiz to check your wellness and
                                mental health!{" "}
                            </p>
                            <p
                                className="pb-2 underline"
                                style={{
                                    color:
                                        store.color === Colors.allopathic
                                            ? HexColors.allopathic
                                            : store.color === Colors.homeopathic
                                                ? HexColors.homeopathic
                                                : HexColors.ayurvedic,
                                }}
                            >
                                Take quiz
                            </p>
                        </div>
                    </div>
                    <div style={{ borderRadius: "10px" }}>
                        <svg
                            className="-mt-10"
                            style={{ marginRight: "-2.6rem", marginBottom: "-0.6rem" }}
                            width="138"
                            height="175"
                            viewBox="0 0 138 175"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M84.156 76.0979L56.3726 73.6031L60.8099 164.755L84.3479 164.813L84.156 76.0979Z"
                                fill="white"
                            />
                            <path
                                d="M106.01 165.882L104.629 150.984L111.315 150.944L118.004 149.98L123.934 147.819L128.508 143.914L130.366 139.298L130.323 134.46L129.247 129.185L127.682 123.799L121.62 111.447L112.896 98.5988L103.356 86.184L92.1267 75.3421L78.2789 68.8433L76.704 93.2475L74.5323 117.107L70.678 164.935L106.01 165.882Z"
                                fill={
                                    store.color === Colors.allopathic
                                        ? HexColors.allopathic
                                        : store.color === Colors.homeopathic
                                            ? HexColors.homeopathic
                                            : HexColors.ayurvedic
                                }
                                stroke="#14BFFA"
                            />
                            <path
                                d="M44.5737 40.3322C44.7133 31.3734 53.8311 20.0068 59.362 14.3981C60.1682 13.5805 61.0543 12.8422 62.0778 12.3217C65.7529 10.4528 70.1545 10.0592 73.693 10.2215C76.8874 10.368 80.2384 11.1042 82.638 13.2178C85.3657 15.6204 89.4393 20.1505 95.0967 28.1725C107.969 46.4252 90.0817 49.5217 87.118 50.4141C84.7471 51.1279 71.1362 51.4108 64.6271 51.4629C57.8799 51.7776 44.4231 49.992 44.5737 40.3322Z"
                                fill="#030406"
                                stroke="#050505"
                            />
                            <path
                                d="M31.4578 165.798L32.8389 150.9L26.1525 150.86L19.4641 149.896L13.5342 147.735L8.95952 143.831L7.10128 139.214L7.14518 134.376L8.22096 129.101L9.78575 123.716L15.8474 111.363L24.5715 98.515L34.112 86.1003L45.3411 75.2583L59.1889 68.7595L60.7638 93.1638L62.9354 117.023L66.7898 164.852L31.4578 165.798Z"
                                fill={
                                    store.color === Colors.allopathic
                                        ? HexColors.allopathic
                                        : store.color === Colors.homeopathic
                                            ? HexColors.homeopathic
                                            : HexColors.ayurvedic
                                }
                                stroke="#14BFFA"
                            />
                            <rect
                                x="57.3945"
                                y="23.7385"
                                width="28.6936"
                                height="22.8614"
                                transform="rotate(-3.35279 57.3945 23.7385)"
                                fill="#FFB4B4"
                            />
                            <path
                                d="M53.4965 126.23L56.4265 123.723L59.1929 120.945L60.8734 118.821L61.9612 119.091L62.7237 119.741L62.4562 121.753L61.6459 124.092L66.967 121.254L69.3561 119.999L73.374 117.761L74.8968 118.139L75.3879 118.953L75.2282 120.53L78.0005 120.524L78.1383 121.366L77.8968 122.807L75.6733 125.258L77.793 125.09L78.5554 125.741L78.1782 127.264L75.6833 129.879L78.0205 129.765L78.3499 131.232L76.0186 134.118L68.9071 138.591L60.8136 141.435L56.4665 142.206L52.8239 141.996L52.5345 133.843L53.4965 126.23Z"
                                fill="#FFC9C8"
                            />
                            <path
                                d="M74.6686 120.925C75.6279 120.47 78.1231 119.657 78.2572 121.352C78.3914 123.048 75.2767 125.617 73.7025 126.689C71.9654 127.799 68.4042 129.995 68.0561 129.909"
                                stroke="#EA8989"
                            />
                            <path
                                d="M75.1849 125.623C76.0971 125.229 78.4753 124.535 78.63 126.063C78.7847 127.591 75.84 129.854 74.3483 130.794C72.701 131.765 69.3227 133.687 68.9879 133.604"
                                stroke="#EA8989"
                            />
                            <path
                                d="M75.3911 130.244C76.2067 129.853 78.3273 129.151 78.4381 130.603C78.5488 132.055 75.8987 134.261 74.5599 135.182C73.0825 136.136 62.649 143.347 52.807 142.064"
                                stroke="#EA8989"
                            />
                            <path
                                d="M53.4257 126.516C55.397 124.772 59.5129 120.958 60.2059 119.652C60.8989 118.346 62.0528 119.032 62.5431 119.538C62.6363 120.716 62.5295 123.322 61.3575 124.324C64.3115 122.839 69.9001 119.667 72.3252 118.267C73.7243 117.459 76.9997 118.039 74.6684 120.925C70.924 123.923 67.5589 126.322 65.8205 126.815"
                                stroke="#EA8989"
                            />
                            <path
                                d="M59.2427 68.5417C55.4411 70.217 47.2192 74.0146 44.7443 75.803C42.2693 77.5913 36.4439 83.5219 33.8405 86.2637C26.8635 94.2335 12.0471 113.654 8.59814 127.578C7.52035 131.929 2.00165 143.954 17.071 149.303C29.1264 153.583 46.5868 148.533 53.81 145.473C52.9138 141.633 51.6072 132.366 53.5505 126.013C50.7589 126.293 40.6455 127.052 35.1796 125.603M32.6731 124.536C33.2923 124.981 34.1542 125.331 35.1796 125.603M35.1796 125.603C35.4285 123.976 36.0888 120.44 36.7387 119.308M47.5705 75.5794L51.6382 96.4449C52.1106 97.0237 53.6422 98.0496 55.9894 97.5227C58.3366 96.9957 55.6661 98.828 54.0374 99.81C53.6754 100.028 53.1915 100.801 54.1511 102.147"
                                stroke="#00A5E9"
                            />
                            <path
                                d="M83.9713 126.314L81.0413 123.807L78.2749 121.029L76.5943 118.905L75.5066 119.175L74.7441 119.825L75.0116 121.837L75.8219 124.176L70.5008 121.338L68.1116 120.083L64.0938 117.845L62.5709 118.223L62.0799 119.037L62.2396 120.614L59.4672 120.608L59.3295 121.45L59.571 122.891L61.7945 125.342L59.6748 125.174L58.9123 125.825L59.2896 127.348L61.7845 129.963L59.4472 129.849L59.1179 131.316L61.4491 134.202L68.5606 138.675L76.6541 141.519L81.0013 142.29L84.6439 142.08L84.9333 133.927L83.9713 126.314Z"
                                fill="#FFC9C8"
                            />
                            <path
                                d="M62.7992 121.009C61.8398 120.554 59.3447 119.74 59.2105 121.436C59.0764 123.132 62.1911 125.701 63.7652 126.773C65.5024 127.882 69.0636 130.079 69.4117 129.993"
                                stroke="#EA8989"
                            />
                            <path
                                d="M62.2819 125.707C61.3697 125.313 58.9915 124.619 58.8368 126.147C58.6821 127.675 61.6268 129.938 63.1185 130.878C64.7658 131.849 68.1441 133.77 68.4789 133.688"
                                stroke="#EA8989"
                            />
                            <path
                                d="M62.0766 130.328C61.261 129.936 59.1404 129.235 59.0297 130.687C58.919 132.139 61.569 134.345 62.9079 135.266C64.3853 136.219 74.8188 143.431 84.6608 142.148"
                                stroke="#EA8989"
                            />
                            <path
                                d="M84.0421 126.6C82.0707 124.856 77.9549 121.042 77.2619 119.736C76.5689 118.43 75.415 119.116 74.9247 119.622C74.8315 120.8 74.9382 123.405 76.1102 124.408C73.1563 122.923 67.5677 119.751 65.1426 118.351C63.7435 117.543 60.4681 118.123 62.7994 121.009C66.5437 124.007 69.9089 126.406 71.6473 126.899"
                                stroke="#EA8989"
                            />
                            <path
                                d="M78.2251 68.6255C82.0266 70.3007 90.2485 74.0984 92.7235 75.8867C95.1984 77.6751 101.024 83.6057 103.627 86.3474C110.604 94.3172 125.421 113.738 128.87 127.661C129.947 132.012 135.466 144.038 120.397 149.387C108.341 153.666 90.881 148.617 83.6578 145.557C84.554 141.717 85.8605 132.45 83.9173 126.096C86.7088 126.377 96.8223 127.136 102.288 125.686M104.795 124.619C104.175 125.065 103.314 125.414 102.288 125.686M102.288 125.686C102.039 124.06 101.379 120.524 100.729 119.392M89.8973 75.6631L85.8295 96.5286C85.3572 97.1074 83.8256 98.1333 81.4784 97.6064C79.1312 97.0795 81.8017 98.9117 83.4304 99.8937C83.7923 100.112 84.2763 100.885 83.3166 102.231"
                                stroke="#00A5E9"
                            />
                            <path
                                d="M80.3139 120.642C78.6713 115.156 77.3834 102.83 85.3735 97.4198C93.3636 92.01 95.2866 106.495 95.2494 114.413"
                                stroke="#4F45BD"
                            />
                            <path
                                d="M54.0942 103.911C52.2799 94.4565 50.5945 74.2273 58.3674 68.9484C66.1403 63.6695 74.9097 68.3509 78.3228 71.3515C81.0466 74.0273 86.4691 82.8364 86.3685 96.6658"
                                stroke="#4F45BD"
                            />
                            <path
                                d="M59.514 68.3782L62.8052 56.9554L77.9284 62.0869C77.9171 65.2398 77.8634 72.2307 77.7389 74.9707C71.5819 83.4207 63.1792 79.0611 59.7475 75.825L59.514 68.3782Z"
                                fill="#FFB4B4"
                            />
                            <path
                                d="M77.4055 71.6551C68.7464 69.3255 62.8638 58.8939 61.0049 53.9692C63.257 56.3743 68.5663 61.4763 71.7869 62.6435C75.0076 63.8107 77.2232 62.7586 77.9284 62.0866C77.9517 64.4784 77.8798 69.7406 77.4055 71.6551Z"
                                fill="#FF8D8B"
                            />
                            <ellipse
                                cx="73.1411"
                                cy="45.658"
                                rx="14.3444"
                                ry="17.9409"
                                transform="rotate(9.27277 73.1411 45.658)"
                                fill="#FFB4B4"
                            />
                            <ellipse
                                cx="54.1075"
                                cy="106.985"
                                rx="2.24132"
                                ry="4.48264"
                                transform="rotate(13.9123 54.1075 106.985)"
                                fill="#4F45BD"
                            />
                            <ellipse
                                cx="80.1211"
                                cy="119.219"
                                rx="1.12066"
                                ry="2.24132"
                                transform="rotate(-20.577 80.1211 119.219)"
                                fill="#4F45BD"
                            />
                            <ellipse
                                cx="94.8949"
                                cy="113.642"
                                rx="1.12066"
                                ry="2.24132"
                                transform="rotate(0.377143 94.8949 113.642)"
                                fill="#4F45BD"
                            />
                            <ellipse
                                cx="53.5643"
                                cy="107.313"
                                rx="1.34479"
                                ry="2.24132"
                                transform="rotate(13.9123 53.5643 107.313)"
                                fill="white"
                            />
                            <circle
                                cx="74.2865"
                                cy="36.7083"
                                r="0.896527"
                                transform="rotate(13.9123 74.2865 36.7083)"
                                fill="#FF0000"
                            />
                            <path
                                d="M73.8653 43.0697C73.7023 43.1063 73.5731 43.69 74.3603 45.7323C75.3443 48.2851 76.6495 48.6084 76.9769 49.1513C77.3042 49.6942 76.7632 50.9456 74.9151 50.9496C73.4366 50.9528 72.4136 50.4839 72.0869 50.2491"
                                stroke="#FF8D8B"
                                stroke-linecap="round"
                            />
                            <path
                                d="M69.6475 53.5706C70.0659 54.3669 70.5556 55.7227 71.9911 55.8935C73.4266 56.0644 75.3261 55.4261 75.67 55.2804"
                                stroke="#FF8D8B"
                                stroke-linecap="round"
                            />
                            <circle
                                cx="71.4827"
                                cy="9.22917"
                                r="7.62048"
                                transform="rotate(13.9123 71.4827 9.22917)"
                                fill="black"
                            />
                            <path
                                d="M71.6172 26.4862C71.0745 32.0668 62.4289 37.2673 55.9928 40.6695C53.1455 42.1746 49.588 41.0109 48.1187 38.145C47.1723 36.2989 47.29 34.0867 48.4271 32.3515L53.6333 24.4062C55.5832 21.4304 58.2917 19.0287 61.4793 17.4486L65.03 15.6885C69.5944 13.4259 74.8554 13.0101 79.7186 14.5275C84.104 15.8958 87.9232 18.7164 90.267 22.6676C94.7504 30.2257 99.7595 40.3726 94.055 40.22C87.97 40.0573 79.2159 31.118 74.8586 25.6082C73.975 24.4908 72.0035 24.8389 71.661 26.2217C71.6395 26.3086 71.6259 26.397 71.6172 26.4862Z"
                                fill="#030406"
                                stroke="#030406"
                            />
                            <path
                                d="M73.9023 21.8357C74.4373 26.2015 77.0073 34.8428 83.0078 34.4819C89.0084 34.121 88.2638 35.0141 87.1414 35.5058"
                                stroke="#030406"
                            />
                            <circle
                                cx="57.8556"
                                cy="41.8745"
                                r="2.68958"
                                transform="rotate(13.9123 57.8556 41.8745)"
                                fill="#FFB4B4"
                            />
                            <circle
                                cx="87.8576"
                                cy="40.0701"
                                r="2.68958"
                                transform="rotate(13.9123 87.8576 40.0701)"
                                fill="#FFB4B4"
                            />
                            <path
                                d="M57.8848 44.6566C58.1198 43.7129 58.6671 45.516 58.6324 49.1412C58.6103 51.4518 56.0545 51.5233 56.2901 49.2246C56.61 46.1031 57.5019 46.1945 57.8848 44.6566Z"
                                stroke="#14BFFA"
                            />
                            <path
                                d="M87.7659 43.2329C87.6862 42.2637 88.7823 43.7963 89.9109 47.2415C90.6302 49.4374 88.232 50.324 87.7188 48.071C87.0217 45.0116 87.8959 44.8124 87.7659 43.2329Z"
                                stroke="#14BFFA"
                            />
                            <path
                                d="M58.2323 40.352C58.8851 40.5137 59.233 41.8906 58.7535 42.2033"
                                stroke="#FF8D8B"
                                stroke-linecap="round"
                            />
                            <path
                                d="M87.3441 40.9097C86.8433 40.4609 87.1814 39.0816 87.7515 39.0301"
                                stroke="#FF8D8B"
                                stroke-linecap="round"
                            />
                            <ellipse cx="79.5" cy="42" rx="0.5" ry="1" fill="#030406" />
                            <ellipse cx="68.5" cy="42" rx="0.5" ry="1" fill="#030406" />
                        </svg>
                    </div>
                </div>
            </div>
        );
    })
);

export default WellnessCheckCard
