import React from "react";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import { Form, Input, Button, Select, message } from "antd";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "../Styles/form.css";
import { Colors, HexColors } from "../Utils/colors";
// import Next from "../Assets/Next.png";
import Group105 from "../Assets/Group105.png";
import edit from "../Assets/edit.svg";
import cross from "../Assets/cross.svg";


const PrescriptionForm = inject("store")(
  observer(({ store, otherProp }) => {
    // var mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const Option = Select.Option;
    const history = useHistory();
    const [error, setError] = useState(false);
    const [add, setAdd] = useState(true);
    const [mg, setMg] = useState("");
    const [medicine, setMedicine] = useState("");
    const [med, setMed] = useState();
    const [days, setDays] = useState(0);
    const [name, setName] = useState("");
    const [observation, setObservation] = useState([]);
    const [note, setNote] = useState("");
    const [med_food, setMed_food] = useState("");
    const [power, setPower] = useState("");
    const [diagnostics, setDiagnostics] = useState("");
    const [medicineObj, setMedicineObj] = useState([]);
    const [obsData, setObsData] = useState([]);
    const [medData, setMedData] = useState([]);
    const [fp_req, setFp_req] = useState(null);
    const [fp_days, setFp_days] = useState(0);
    const [hosp_req, setHosp_req] = useState(null);
    const [is_morning, setIs_morning] = useState(false);
    const [is_noon, setIs_noon] = useState(false);
    const [is_evening, setIs_evening] = useState(false);
    const [is_night, setIs_night] = useState(false);
    const [input_med, setInput_med] = useState("");
    const [input_obs, setInput_obs] = useState("");
    const [showData, setShowData] = useState(false)


    const handleSearch = (e) => {
      setObsData([]);
      setInput_med(e.target.value)
      axios
        .get(`api/doc/medicines/?query=${input_med}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setMedData(res.data.data);
        }).catch(() => console.log("Medicines not get"))

      setShowData(true);
    }

    const handleSearch2 = (e) => {
      setMedData([]);
      setInput_obs(e.target.value)
      axios
        .get(`api/doc/observation/?query=${input_obs}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          setObsData(res.data.data);
        }).catch(() => console.log("Observation not get"))

      setShowData(true);
    }

    const AddMedicine = (obj) => {
      if (
        power === "" ||
        mg === "" ||
        days === "" ||
        medicine === "" ||
        note === "" ||
        med_food === ""
      ) {
        setError(true);
      } else {
        setMedicineObj([...medicineObj, obj]);
        setAdd(false);
      }
    };
    console.log({ medicineObj });

    const refreshMedForm = () => {
      setInput_med("");
      setMedicine("");
      setMed();
      setPower("");
      setMg("");
      setMed_food("");
      setDays(0);
      setIs_morning(false);
      setIs_noon(false);
      setIs_evening(false);
      setIs_night(false);
      setNote("");
      setAdd(true);
    }

    const editItem = async (val) => {
      try {
        let filterItems = (arr, query) => {
          return arr.filter((item) => {
            let hosp_req = item.medicine.indexOf(query) !== -1;
            return hosp_req;
          });
        };
        let deleteItems = (arr, query) => {
          return arr.filter((item) => {
            let hosp_req = item.medicine.indexOf(query) === -1;
            return hosp_req;
          });
        };

        let result = await filterItems(medicineObj, val);

        console.log({ result });

        setInput_med(result[0].medicine);
        setMedicine(result[0].medicine);
        setMed(result[0].med);
        setPower(result[0].power);
        setMg(result[0].mg);
        setMed_food(result[0].med_food);
        setDays(result[0].days);
        setIs_morning(result[0].is_morning);
        setIs_noon(result[0].is_noon);
        setIs_evening(result[0].is_evening);
        setIs_night(result[0].is_night);
        setNote(result[0].note);

        let newObj = deleteItems(medicineObj, val);
        setMedicineObj(newObj);
        setAdd(true);

      } catch (e) {
        console.log("e2", e);
      }
    };

    const handleSubmit = () => {

      if (
        name === "" ||
        observation === "" ||
        medicine === "" ||
        diagnostics === "" ||
        days === "" ||
        power === "" ||
        note === "" ||
        med_food === "" ||
        mg === ""
      ) {
        setError(true);
      } else {

        const data = {
          obs: observation.toString(),
          condition: "",
          diagnosis: diagnostics,
          fp_req: fp_req,
          fp_days: fp_days,
          hosp_req: hosp_req,
          emergency: false,
          chat: 106,
          meds: medicineObj
        };

        console.log(data);

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        };

        const body = JSON.stringify(data);

        axios
          .post("api/pat/prescription/", body, config)
          .then((response) => {
            if (response.data.status) {
              message.success("Registration successful");
              history.push("/doctorhome");
            }
            console.log(response);
          })
          .catch((err) => {
            message.error("something went wrong, please try again!!");
            console.log(err);
          });
      }
    };

    return (
      <div className="">
        <div className="pl-8 pb-2 pt-4">
          <svg
            onClick={() => history.goBack()}
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 13L2 7L8 1"
              stroke={
                store.color === Colors.allopathic
                  ? HexColors.allopathic
                  : store.color === Colors.homeopathic
                    ? HexColors.homeopathic
                    : HexColors.ayurvedic
              }
              strokeWidth="2"
            />
          </svg>
        </div>

        <div className="text-center px-auto px-8">
          <h1 className="pt-4 text-left text-2xl">Prescription</h1>
          <p
            style={{ visibility: !error ? "hidden" : "visible" }}
            className=" text-red-500 text-xs pt-3 "
          >
            *Kindly fill the required fields{" "}
          </p>
          <Form name="nest-messages">
            <Input
              name="name"
              type="text"
              style={{
                color: "black",
                borderRadius: "2px",
                width: "18.25rem",
                marginBottom: "12px",
                marginTop: "11px",
                boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                border: error && name.length === 0 ? "1px solid red" : "none",
                paddingLeft: "12px",
                paddingright: "12px",
                fontSize: "12px !important",
              }}
              className=" h-8 outline-none px-5  text-black text-xs  font-normal "
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder="Patient"
            />

            <div className="mx-auto" style={{ width: "18.25rem" }}>
              <Input
                name="input_obs"
                type="text"
                style={{
                  color: "black",
                  borderRadius: "2px",
                  width: "18.25rem",
                  marginBottom: "2px",
                  marginRight: "10px",
                  marginTop: "11px",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  border:
                    error && observation.length === 0
                      ? "1px solid red"
                      : "none",
                  fontSize: "12px !important",
                }}
                className=" h-8 outline-none text-black text-xs  font-normal "
                onChange={(e) => handleSearch2(e)}
                value={input_obs}
                autoComplete="off"
                placeholder="Observation- Type Keyword"
              />
              {showData && <div
                className="bg-white text-left"
                style={{
                  color: "black",
                  borderRadius: "2px",
                  width: "18.25rem",
                  marginBottom: "12px",
                  marginRight: "10px",
                  marginTop: "0px",
                  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                  fontSize: "12px !important",
                  position: "absolute",
                  zIndex: "7"
                }}
              >
                {obsData.map((data, index) =>
                  <div
                    className="my-2 mx-2"
                    style={{
                      color: "black",
                      borderRadius: "2px",
                      width: "18.25rem",
                      marginBottom: "12px",
                      marginRight: "10px",
                      fontSize: "12px !important",
                    }}
                    key={data.id}
                    onClick={() => { setObservation([...observation, data.name]); setInput_obs(""); setShowData(false); }}
                  >
                    {data.name}
                  </div>
                )}
              </div>
              }
            </div>

            {observation.length !== 0 &&
              <div className="mx-auto"
                style={{ width: "18.25rem", alignItems: "center", display: "grid", gridTemplateColumns: "auto auto auto" }}>
                {observation.map((data, index) =>
                  <div
                    className="outline-none text-black bg-gray-200 mx-0.5 my-1 flex justify-around"
                    style={{
                      borderRadius: "2px",
                      alignItems: "center",
                      fontSize: "12px"
                    }}
                    key={index}
                    onClick={() => {
                      let newArray = [...observation];
                      newArray.splice(index, 1);
                      setObservation(newArray);
                    }}
                  >{data}<img className="w-2.5 h-2.5" src={cross} alt="" /></div>
                )}
              </div>
            }

            <div className="mx-auto">
              {medicineObj.map((data, index) => (

                <div
                  onClick={() => editItem(data.medicine)}
                  key={index}
                  className=" py-4 pl-24 mx-auto flex justify-between"
                  style={{
                    color: "black",
                    borderRadius: "2px",
                    width: "18.25rem",
                    marginBottom: "20px",
                    marginTop: "11px",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    border: "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                    alignItems: "center"
                  }}
                >
                  <div>{data.medicine}</div>
                  <div className="-ml-6">{data.power} {data.mg}</div>
                  <div>{data.days} Days</div>
                  <div className="pr-3" ><img src={edit} alt="" /></div>
                </div>
              ))}
            </div>

            {add && (
              <>
                {" "}
                <div className="mx-auto text-left" style={{ width: "18.25rem", }}><h1 className="pt-2 text-sm">Add Medicines</h1></div>

                <div className="mx-auto" style={{ width: "18.25rem" }}>
                  <Input
                    name="input_med"
                    type="text"
                    style={{
                      color: "black",
                      borderRadius: "2px",
                      width: "18.25rem",
                      marginBottom: "12px",
                      marginRight: "10px",
                      marginTop: "11px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                      border:
                        error && input_med.length === 0
                          ? "1px solid red"
                          : "none",
                      fontSize: "12px !important",
                    }}
                    className=" h-8 outline-none text-black text-xs  font-normal "
                    onChange={(e) => handleSearch(e)}
                    value={input_med}
                    placeholder="Search for Medicine"
                    autoComplete="off"
                  />
                  {showData && <div
                    className="bg-white text-left"
                    style={{
                      color: "black",
                      borderRadius: "2px",
                      width: "18.25rem",
                      marginRight: "10px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                      fontSize: "12px !important",
                      position: "absolute",
                      zIndex: "5"
                    }}
                  >
                    {medData.map((data, index) => <>
                      <div
                        className="my-2 mx-2"
                        style={{
                          color: "black",
                          borderRadius: "2px",
                          width: "18.25rem",
                          marginBottom: "12px",
                          marginRight: "10px",
                          fontSize: "12px !important",
                        }}
                        key={index}
                        onClick={() => { setMedicine(data.med); setMed(data.id); setInput_med(data.med); setShowData(false); }}
                      >
                        {data.med}
                        <hr></hr>
                      </div>
                    </>)}
                  </div>
                  }
                </div>



                <div className="mx-auto" style={{ width: "18.25rem" }}>
                  <Input
                    name="power"
                    type="text"
                    style={{
                      color: "black",
                      borderRadius: "2px",
                      width: "11.25rem",
                      marginBottom: "12px",
                      marginRight: "10px",
                      marginTop: "11px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                      border:
                        error && power.length === 0
                          ? "1px solid red"
                          : "none",
                      fontSize: "12px !important",
                    }}
                    className=" h-8 outline-none text-black text-xs  font-normal "
                    onChange={(e) => setPower(e.target.value)}
                    value={power}
                    placeholder="potency"
                    autoComplete="off"
                  />

                  <Select
                    title="mg"
                    style={{
                      cursor: "pointer",
                      textAlign: "left",
                      color: "black",
                      borderRadius: "2px",
                      width: "6.25rem",
                      marginBottom: "12px",
                      marginTop: "11px",
                      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                      border:
                        error && mg.length === 0 ? "1px solid red" : "none",
                      fontSize: "12px !important",
                    }}
                    onSelect={(e) => setMg(e)}
                    name="mg"
                    value={mg || "Unit"}
                  >
                    <Option value="mg">mg</Option>
                    <Option value="grams">grams</Option>
                    <Option value="ml">ml</Option>
                    <Option value="unit2">jitna chaho</Option>
                  </Select>
                </div>

                <div className="mx-auto flex" style={{ width: "18.25rem", alignItems: "center" }}>
                  <div
                    className="h-8 flex pl-3 outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "4.25rem",
                      alignItems: "center",
                      fontSize: "12px"
                    }}
                  >Days</div>
                  <div
                  >
                    <button
                      style={{ marginLeft: "13px" }}
                      onClick={() => setDays(parseInt(days) - 1)}
                    >
                      -
                    </button>
                    <Input
                      name="days"
                      type="text"
                      style={{
                        color: "black",
                        borderRadius: "2px",
                        width: "10rem",
                        marginBottom: "12px",
                        marginRight: "10px",
                        marginLeft: "16px",
                        marginTop: "11px",
                        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                        border:
                          error && days.length === 0
                            ? "1px solid red"
                            : "none",
                        fontSize: "12px !important",
                      }}
                      className=" h-8 outline-none px-5  text-black text-xs text-center font-normal "
                      onChange={(e) => setDays(e.target.value)}
                      value={days}
                    />
                    <button onClick={() => setDays(parseInt(days) + 1)}>
                      +
                    </button>
                  </div>

                </div>

                <div
                  className="flex justify-between text-sm mx-auto"
                  style={{ width: "18.25rem", fontSize: "12px" }}
                >
                  <div
                    className="h-8 flex outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "8.75rem"
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="radio"
                      value="1"
                      name="food"
                      onChange={(e) => setMed_food(e.target.value)}
                      className="mr-1.5 ml-2 "
                    />
                    <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                      Before Food
                    </p>
                  </div>

                  <div
                    className="h-8 flex outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "8.75rem"
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="radio"
                      value="2"
                      name="food"
                      onChange={(e) => setMed_food(e.target.value)}
                      className="mr-1 ml-2"
                    />
                    <p
                      style={{ paddingTop: "7px" }}
                      className="pt-1.5"
                    >
                      After Food
                    </p>
                  </div>
                </div>

                <div
                  className="flex justify-between text-sm mx-auto"
                  style={{ width: "18.25rem", fontSize: "12px" }}
                >
                  <div
                    className="h-8 flex outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "8.75rem"
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="checkbox"
                      value="1"
                      name="morning"
                      onChange={() => setIs_morning(!is_morning)}
                      className="mr-1.5 ml-2 "
                    />
                    <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                      Morning
                    </p>
                  </div>
                  <div
                    className="h-8 flex outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "8.75rem"
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="Checkbox"
                      value="2"
                      name="noon"
                      onChange={() => setIs_noon(!is_noon)}
                      className="mr-1 ml-2"
                    />
                    <p
                      style={{ paddingTop: "7px" }}
                      className="pt-1.5 pr-1.5"
                    >
                      Noon
                    </p>
                  </div>
                </div>

                <div
                  className="flex justify-between text-sm mx-auto"
                  style={{ width: "18.25rem", fontSize: "12px" }}
                >
                  <div
                    className="h-8 flex outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "8.75rem"
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="checkbox"
                      value="1"
                      name="evening"
                      onChange={() => setIs_evening(!is_evening)}
                      className="mr-1.5 ml-2 "
                    />
                    <p style={{ paddingTop: "7px" }} className="pt-1.5 pr-2">
                      Evening
                    </p>
                  </div>
                  <div
                    className="h-8 flex outline-none text-black"
                    style={{
                      boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      marginBottom: "12px",
                      marginTop: "11px",
                      width: "8.75rem"
                    }}
                  >
                    <input
                      style={{ marginTop: "10px" }}
                      type="Checkbox"
                      value="2"
                      name="night"
                      onChange={() => setIs_night(!is_night)}
                      className="mr-1 ml-2"
                    />
                    <p
                      style={{ paddingTop: "7px" }}
                      className="pt-1.5 pr-1.5"
                    >
                      Night
                    </p>
                  </div>
                </div>

                <Input
                  type="text"
                  name="note"
                  style={{
                    color: "black",
                    borderRadius: "2px",
                    width: "18.25rem",
                    marginBottom: "12px",
                    marginTop: "11px",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    border:
                      error && note.length === 0 ? "1px solid red" : "none",
                    paddingLeft: "12px",
                    paddingright: "12px",
                    fontSize: "12px !important",
                  }}
                  className="h-8  outline-none text-black text-xs  font-normal "
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                  placeholder="Note"
                  autoComplete="off"
                />
              </>
            )}

            {add ? (
              <div>
                <Button
                  onClick={() =>
                    AddMedicine({ dose: `${power} ${mg}`, power, mg, days, note, med_food, is_morning, is_noon, is_evening, is_night, medicine, med, quan: null, prescription: 3 })
                  }
                  className=""
                  style={{
                    background: store.color,
                    borderRadius: "2px",
                    color: "white",
                    height: "33px",
                    width: "299px",
                    marginTop: "15px",
                  }}
                >
                  Add
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  onClick={() => refreshMedForm()}
                  className=""
                  style={{
                    background: store.color,
                    borderRadius: "2px",
                    color: "white",
                    height: "33px",
                    width: "290px",
                  }}
                >
                  Add More...
                </Button>
              </div>
            )}
          </Form>
          <div
            className="h-20 mb-8 mx-auto"
            style={{
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "2px",
              marginTop: "15px",
              width: "296px",
              border:
                error && diagnostics.length === 0
                  ? "1px solid red"
                  : "none",
            }}
          >
            <textarea
              style={{ width: "290px", borderRadius: "2px", }}
              className="h-20 px-2"
              value={diagnostics}
              name="diagnostic"
              onChange={(e) => setDiagnostics(e.target.value)}
              placeholder="Diagnostic" />
          </div>

          <div
            className="mx-auto flex"
            style={{
              width: "18.25rem"
            }}>
            <div
              className="h-8 flex pl-3 outline-none text-black mr-4"
              style={{
                boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginBottom: "12px",
                marginTop: "11px",
                width: "11rem",
                alignItems: "center",
                fontSize: "12px",
                border:
                  error && fp_req === null
                    ? "1px solid red"
                    : "none",
              }}
            >Follow up Required
            </div>
            <div
              className="h-8 flex px-4 outline-none text-black mr-1"
              style={{
                boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginBottom: "12px",
                marginTop: "11px",
                width: "3rem",
                alignItems: "center",
                fontSize: "12px",
                border:
                  fp_req === true
                    ? "1px solid blue"
                    : "none",
              }}
              onClick={() => setFp_req(true)}
            >yes
            </div>
            <div
              className="h-8 flex px-4 outline-none text-black"
              style={{
                boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginBottom: "12px",
                marginTop: "11px",
                width: "3rem",
                alignItems: "center",
                fontSize: "12px",
                border:
                  fp_req === false
                    ? "1px solid blue"
                    : "none",
              }}
              onClick={() => setFp_req(false)}
            >No
            </div>
          </div>
          {fp_req && <div className="mx-auto" style={{ fontSize: "10px", textAlign: "left", width: "18.25rem" }}><p>Follow up required in:</p></div>}
          {fp_req &&
            <div className="mx-auto flex" style={{ width: "18.25rem", alignItems: "center" }}>
              <div
                className="h-8 flex pl-3 outline-none text-black"
                style={{
                  boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                  borderRadius: "2px",
                  marginBottom: "12px",
                  marginTop: "11px",
                  width: "4.25rem",
                  alignItems: "center",
                  fontSize: "12px"
                }}
              >Days</div>
              <div
              >
                <button
                  style={{ marginLeft: "13px" }}
                  onClick={() => setFp_days(parseInt(fp_days) - 1)}
                >
                  -
                </button>
                <Input
                  name="fp_days"
                  type="text"
                  style={{
                    color: "black",
                    borderRadius: "2px",
                    width: "10rem",
                    marginBottom: "12px",
                    marginRight: "10px",
                    marginLeft: "16px",
                    marginTop: "11px",
                    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
                    border:
                      error && fp_days.length === 0
                        ? "1px solid red"
                        : "none",
                    fontSize: "12px !important",
                  }}
                  className=" h-8 outline-none px-5  text-black text-xs text-center font-normal "
                  onChange={(e) => setFp_days(e.target.value)}
                  value={fp_days}
                />
                <button onClick={() => setFp_days(parseInt(fp_days) + 1)}>
                  +
                </button>
              </div>

            </div>}

          <div
            className="mx-auto flex"
            style={{
              width: "18.25rem"
            }}>
            <div
              className="h-8 flex pl-3 outline-none text-black mr-4"
              style={{
                boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginBottom: "12px",
                marginTop: "11px",
                width: "11rem",
                alignItems: "center",
                fontSize: "12px",
                border:
                  error && hosp_req === null
                    ? "1px solid red"
                    : "none",
              }}
            >Hospitalisation Required
            </div>
            <div
              className="h-8 flex px-4 outline-none text-black mr-1"
              style={{
                boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginBottom: "12px",
                marginTop: "11px",
                width: "3rem",
                alignItems: "center",
                fontSize: "12px",
                border:
                  hosp_req === true
                    ? "1px solid blue"
                    : "none",
              }}
              onClick={() => setHosp_req(true)}
            >yes
            </div>
            <div
              className="h-8 flex px-4 outline-none text-black"
              style={{
                boxShadow: " 0px 0px 20px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginBottom: "12px",
                marginTop: "11px",
                width: "3rem",
                alignItems: "center",
                fontSize: "12px",
                border:
                  hosp_req === false
                    ? "1px solid blue"
                    : "none",
              }}
              onClick={() => setHosp_req(false)}
            >No
            </div>
          </div>

          <div
            className="flex justify-between -ml-4 cursor-pointer "
            style={{
              width: "340px",
              marginBottom: "27px",
            }}
          >

          </div>
        </div>

        <div
          className="mx-auto flex justify-between cursor-pointer "
          style={{
            width: "360px",
            height: "30px",
            marginBottom: "27px",
          }}
        >
          <p
            className="px-6 ml-4"
            style={{
              background: "#929BFF",
              color: "#FFFFFF",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "3px",
              paddingTop: "4px",
            }}
          >
            Save a copy
          </p>
          <img
            onClick={() => handleSubmit()}
            className="w-8 mb-1 mr-4 cursor-pointer "
            src={Group105}
            alt=""
          />
        </div>

        <div className="h-5"></div>
      </div>
    );
  })
);

export default PrescriptionForm;
