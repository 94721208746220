import React, { useEffect, useState } from "react";
import "../Styles/verification.css";
import { useHistory, useLocation } from "react-router-dom";
import leftarrow from "../Assets/leftarrow.svg";
import axios from "axios";
import ReactCodeInput from "react-verification-code-input";
import { message, Spin } from "antd";

function Otpverification() {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const key = "updatable";
  const history = useHistory();
  const { state } = useLocation();
  const { num } = state;
  useEffect(() => {
    if (
      localStorage.access !== undefined &&
      localStorage.refresh !== undefined
    ) {
      history.push("/");
    }
  }, [history]);
  const clear = () => {
    setOtp(" ");
  };

  const handleClick = (otp) => {
    if (otp.length === 6) {
      const data = {
        otp: otp,
        rid: localStorage.rid,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify(data);

      axios
        .post("api/auth/verify-otp/", body, config)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            localStorage.setItem("access", res.data.data.access);
            localStorage.setItem("refresh", res.data.data.refresh);
            localStorage.setItem("user_type", res.data.data.status);
            localStorage.setItem("sb_access", res.data.data.access_token);

            message.success({
              content: "Succesfully Logged in",
              key,
              duration: 1,
            });
            if (res.data.data.status === 1) {
              history.push("/home");
            } else if (res.data.data.status === 2) {
              history.push("/doctorhome");
            } else {
              history.push("/selection");
            }
          }
        })
        .catch((err) => {
          console.log(err);

          clear();
          setLoading(false);
          message.error("Wrong OTP");
          setError(true);
        });
    }
  };

  return loading ? (
    <div
      style={{
        margin: 0,
        padding: 0,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#ececec",
      }}
    >
      <Spin tip="Verifying OTP..."></Spin>
    </div>
  ) : (
    <div className="login-container">
      <div className=" cursor-pointer pt-8 pl-8  ">
        <img onClick={() => history.goBack()} src={leftarrow} alt="" />
      </div>
      <div className="verification_box">
        <div
          style={{ color: "#000000" }}
          className="  max-w-lg md:max-w-md  text-center mx-auto  "
        >
          <h1 className=" text-2xl pt-16 pb-2 " style={{ fontWeight: "500" }}>
            OTP Verification
          </h1>

          <p style={{ paddingTop: "0.1rem" }} className="  pt-1  text-xs ">
            Enter the otp sent to <span className=" font-medium   ">{num}</span>{" "}
          </p>

          <p
            onClick={() => history.goBack()}
            className=" text-xs font-medium underline pb-10  pt-1 "
            style={{ color: "#8075FF" }}
          >
            {num.substr(-3) === "com" ? " Change Email" : "Change Number?"}
          </p>

          <div id="divOuter" className=" mx-auto  text-center ">
            <div id="divInner" className="">
              <ReactCodeInput
                style={{
                  background: "black",
                  fontFamily: "Roboto, sans-serif",
                }}
                fieldWidth={40}
                type="number"
                fields={6}
                value={otp}
                onChange={(val) => {
                  handleClick(val);
                  setOtp(otp);
                }}
                onComplete={() => {
                  setLoading(true);
                }}
                loading={loading}
              />
              <p
                style={{ visibility: !error ? "hidden" : "visible" }}
                className=" text-red-500 text-xs pt-3 "
              >
                *Please Enter the correct OTP{" "}
              </p>
            </div>
          </div>

          <div className="pt-3">
            <p className="pt-5 pb-4 text-xs ">
              Didn't receive an Otp ?{" "}
              <span
                className=" resend  text-sm font-medium   text     underline "
                style={{}}
              >
                {" "}
                Resend Otp{" "}
              </span>
            </p>
            <button
              onClick={() =>
                otp.length < 6
                  ? message.error({ content: "Wrong OTP", key, duration: 1 })
                  : handleClick(otp)
              }
              className=" bg-white md:w-72 w-56  font-medium   rounded-xl "
              style={{
                color: "#8075FF",
                height: "49px",
                boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.15)",
                marginBottom: "5vh",
                marginTop:"15vh"
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otpverification;
