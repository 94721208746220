import React, { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { HostName } from "../Utils/utils";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import black from "../Assets/black.svg";
import avatar from "../Assets/avatar.svg";
import attach2 from "../Assets/attach2.svg";
import camera2 from "../Assets/camera2.svg";
import { inject, observer } from "mobx-react";
import Send_button from "../Assets/Send_button.svg";
import dial from "../Assets/dial.svg";
import { Input, Spin } from "antd";
import axios from "axios";
import ImageUploader from "react-images-upload";
import "../Styles/chat.css";
import Sidebar from "./Headers/sidebar";
const StartChat = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const { state } = useLocation();

    const [msg, setMsg] = useState("");
    const [chats, setChats] = useState([]);
    const [startMsg, setStartMsg] = useState(false);
    const [update, setUpdate] = useState(false);
    const [image, setImage] = useState([]);
    const [upload, setUpload] = useState(false);
    const [doctorName, setDoctorName] = useState("");
    const [qualification, setQualification] = useState("");
    const [request, setRequest] = useState(false);
    const [ws, setWs] = useState(
      new W3CWebSocket(
        `wss://${HostName}/ws/medhelp/doctor/chat/${state.doctor}/2/${state.id}/?user_token=${localStorage.access}`
      )
    );

    useEffect(() => {
      axios
        .get(`api/pat/chat-details/?chat=${state.id}&type=doc`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res);
          setDoctorName(res.data.data.user_data.name);
          localStorage.setItem("doc_name", res.data.data.user_data.name);
          setQualification(res.data.data.qualification);
        });
    }, [state.id]);

    const scroll = () => {
      const element = document.getElementById("chats");
      if (element) {
        element.scroll({
          top: element.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    };

    const connect = async () => {
      ws.onopen = (e) => {
        console.log(e);
        return true;
      };
      ws.onclose = () => {
        setWs("");
      };
      ws.onerror = (e) => {
        console.log("error", e);
        return false;
      };
    };
    useEffect(() => {
      connect();
      datasavetodb();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      scroll();
      setUpdate(false);
    }, [update]);

    const datasavetodb = async () => {
      if (connect) {
        ws.onmessage = async (message) => {
          const dataFromServer = JSON.parse(message.data);
          console.log("msgs", dataFromServer);
          if (dataFromServer.ev_type === 0) {
            ws.close();
            setChats([]);
          } else if (dataFromServer.ev_type === 2) {
            setStartMsg(true);
          } else if (
            dataFromServer.ev_type === 3 ||
            dataFromServer.ev_type === 4
          ) {
            setStartMsg(true);

            let msgs = await dataFromServer;
            msgs["time"] = await moment(new Date()).format("h:mm a");
            await chats.push(msgs);
            console.log("chat here", chats);
            setUpdate(true);

            if (
              dataFromServer.message === "Doctor has accepted call request." &&
              parseInt(localStorage.user_type) === 1
            ) {
              console.log("call request");
              history.push("/call");
            }
          }
        };
      }
    };
    const sendpicture = async () => {
      var form_data = new FormData();

      form_data.append("file", image[0]);
      form_data.append("doc", state.doctor);
      form_data.append("pid", state.id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.access,
        },
      };

      await axios
        .post(`api/doc/uploadfile/`, form_data, config)
        .then((res) => {
          setUpload(false);
          console.log(res);
        })
        .catch((err) => console.error("err", err));
    };
    const sendMsg = async () => {
      if (msg.length !== 0) {
        let message = JSON.stringify({ msg: msg.trim() });
        ws.send(message);

        setMsg("");
      }
    };

    const requestNotify = async () => {
      setRequest(!request);
      let message = JSON.stringify({
        msg: "Patient wants to call you!!".trim(),
      });
      ws.send(message);
    };

    if (!startMsg) {
      return (
        <div
          style={{
            margin: 0,
            padding: 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ececec",
          }}
        >
          <Spin tip="Loading Chats..."></Spin>
        </div>
      );
    }
    console.log(image);
    return (
      <div>
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className=" sticky  top-0 ">
          <div
            className=" flex py-2 pl-4  shadow-md  bg-white z-50   "
            style={{ alignItems: " center", height: "100px" }}
            id="chat_header"
          >
            <div onClick={() => history.goBack()}>
              <img className="pl-2 pb-2 pt-4" src={black} alt="" />
            </div>
            <div className="flex pl-8 " style={{ alignItems: " center" }}>
              <img src={avatar} className=" rounded-full " alt="" />
              <div className=" px-4 ">
                <p className=" font-medium text-sm ">{doctorName}</p>
                <p className=" text-xs  ">{qualification}</p>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setUpload(false);
          }}
          id="chats"
          style={{ height: "80vh", overflow: "scroll" }}
        >
          {chats.map((msg, index) =>
            msg.message === "Patient wants to call you!!" ? (
              ""
            ) : msg.ev_type === 3 ? (
              <div key={index}>
                <div className=" mx-4">
                  <div className="pt-4 pb-2">
                    <div
                      className=" p-2  text-black  bg-white   rounded-tr-xl rounded-br-xl rounded-bl-xl   "
                      id="message_box_width"
                      style={{
                        marginLeft:
                          msg.sender === parseInt(localStorage.userId)
                            ? "auto"
                            : "none",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                        borderRadius:
                          msg.sender === parseInt(localStorage.userId)
                            ? " 16.2014px 3.24029px 16.2014px 16.2014px"
                            : "3.24029px 16.2014px 16.2014px 16.2014px",
                      }}
                    >
                      {msg.message}

                      <div className="  ">
                        <span
                          style={{ fontSize: "8px" }}
                          className=" flex justify-end    "
                        >
                         
                          {msg.time}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index}>
                <div className=" mx-4">
                  <div className="pt-4 pb-2">
                    <div
                      className=" p-2  w-52 text-black  bg-white   rounded-tr-xl rounded-br-xl rounded-bl-xl   "
                      style={{
                        marginLeft:
                          msg.sender === parseInt(localStorage.userId)
                            ? "auto"
                            : "none",
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                        borderRadius:
                          msg.sender === parseInt(localStorage.userId)
                            ? " 16.2014px 3.24029px 16.2014px 16.2014px"
                            : "3.24029px 16.2014px 16.2014px 16.2014px",
                      }}
                    >
                      <img
                        src={msg.message}
                        style={{ borderRadius: "20px" }}
                        alt={"Not Available"}
                      />
                      <div className="  ">
                        <span
                          style={{ fontSize: "8px" }}
                          className=" flex justify-end    "
                        >
                          
                          {msg.time}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className=" h-28 "></div>
        <div className=" fixed bottom-3 z-50 " id="chats_inputs">
          <div
            onClick={() => requestNotify()}
            className="outline-none mx-auto mb-2 flex justify-around py-2 bg-white"
            id="request_for_a_call"
            style={{
              alignItems: "center",
              maxWidth: "85%",
              boxShadow:
                "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
              borderRadius: "10px",
              
            }}
          >
            <img src={dial} alt=""className="call_request" />
            {request ? (
              <p  id="call_text">Requested For a Voice/Video Call</p>
            ) : (
              <p id="call_text" >Request For a Voice/Video Call</p>
            )}
          </div>
          <div className=" flex  justify-between px-4   ">
            <div
              className=" flex justify-between bg-white"
              id="sender_message_inputs"
              style={{
                marginLeft: "3%",
                boxShadow:
                  "0px 4px 4px rgba(51, 51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
                borderRadius: " 20px",
                
              }}
            >
              {upload ? (
                <div className=" " id="patient_upload_image">
                  <ImageUploader
                    buttonText={image.length === 0 ? "Select" : "Upload"}
                    onChange={(e) => setImage(e)}
                    imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                    maxFileSize={5242880}
                    singleImage={true}
                    withPreview
                  />
                </div>
              ) : (
                <Input
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  className=" pl-3 outline-none h-10 border-none "
                  style={{ borderRadius: "20px", border: "none" }}
                  placeholder=" Write A message "
                  type="text"
                  onPressEnter={() => sendMsg()}
                />
              )}
              <img
                className=" pr-4 ml-2"
                onClick={() => {
                  if (upload) {
                    sendpicture();
                  } else {
                    sendMsg();
                  }
                }}
                src={Send_button}
                alt=""
              />
            </div>

            <div className=" flex  pl-10 " style={{ alignItems: "center " }} id="file_uploads_btn">
              <img
                onClick={() => setUpload(!upload)}
                className=" px-2  "
                src={attach2}
                alt=""
              />
              <img className="  px-2" src={camera2} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default StartChat;
