import { inject, observer } from 'mobx-react'
import React from 'react'
import Right_arrowblack from "../../Assets/Right_arrowblack.svg"
import patientImg from "../../Assets/patientImg.svg"
import { Colors, HexColors } from '../../Utils/colors'
const DoctorPatients = inject("store")(observer(({ store, patientName, status }) => {
    return (
        <div>
            <div className="flex justify-between my-4"
                style={{
                    alignItems: "center",
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.06)",
                    borderRadius: "29px",
                    borderRight: `2px solid ${store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)}`,
                    borderTop: `2px solid ${store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)}`,
                    borderBottom: `2px solid ${store.color === Colors.allopathic ? HexColors.allopathic : (store.color === Colors.homeopathic ? HexColors.homeopathic : HexColors.ayurvedic)}`,
                }}
            >
                <div className="flex" style={{ alignItems: "center" }}>
                    <img className="bg-red-400"
                        style={{ borderRadius: "29px", }}
                        src={patientImg} alt="" />
                    <div className="mx-4">
                        <div style={{ fontSize: "16px" }}>{patientName}</div>
                        <div className="status" style={{ fontSize: "10px" }}>{status}</div>
                    </div>
                </div>
                <img className="mr-6" src={Right_arrowblack} alt="" />
            </div>

        </div>
    )
}))

export default DoctorPatients
