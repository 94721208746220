import React, { useState } from "react";
import Community from "../Assets/Community.png";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";

const CommunityCard = inject("store")(
  observer(({ store, data }) => {
    const history = useHistory();
    const [blog] = useState([data]);
    // console.log(blog[0]);

    return (
      <div
        style={{ width: "85%" }}
        className="mx-auto mt-6"
        onClick={() =>
          history.push(
            !blog[0].writer_name ? "singleMyUpload" : "singlecommunity",
            { blog: blog[0] }
          )
        }
      >
        <img
          className="h-20"
          style={{
            width: "100%",
            height: "23vh",
            borderRadius: "17px 17px 0px 0px",
            overflow: "hidden",
          }}
          src={blog[0].image !== null ? blog[0].image : Community}
          alt=""
        />
        <div
          className="h-32"
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
            borderRadius: "17px",
          }}
        >
          <p
            className="font-semibold text-sm pt-2 px-5"
            style={{ fontWeight: "600" }}
          >
            {data.title
              ? data.title.length > 30
                ? data.title.substring(0, 30) + "..."
                : data.title
              : "NA"}
          </p>
          <p className="text-xs pt-2 px-5" style={{}}>
            {data.content
              ? data.content.length > 100
                ? data.content.substring(0, 100) + "..."
                : data.content
              : "NA"}
          </p>
          <p
            className="underline float-right pr-4 "
            style={{ fontSize: "10px" }}
          >
            See More
          </p>
        </div>
      </div>
    );
  })
);

export default CommunityCard;
