import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (
        localStorage.access !== undefined &&
        localStorage.refresh !== undefined
      ) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        );
      }
    }}
  />
);

export default ProtectedRoute;
