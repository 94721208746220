import React from 'react'
import { inject, observer } from "mobx-react";
// import { Colors, HexColors } from "../../Utils/colors";


const AppointmentTime = inject("store")(
    observer(({ store, data }) => {

        // const [book, setBook] = useState(null);

        return (
            <div>
                <div className="flex mt-4 gap-4">
                    <div>
                        <div
                            className="time"
                            style=
                            {{
                                background: "#FFFFFF",
                                border: "0.5px solid rgba(166, 174, 255, 0.7)",
                                borderRadius: "9px",
                                color: "rgba(166, 174, 255, 0.8)",
                                fontWeight: "500",
                                // background:
                                //     book === true
                                //         ? store.color
                                //         : "none",
                            }}
                        // onClick={() => setBook(true)}
                        >11 : 30 to 12 : 30
                        </div>
                    </div>
                    <div>
                        <div className="time" style={{
                            color: "rgba(166, 174, 255, 0.8)", background: "#FFFFFF",
                            border: "0.5px solid rgba(166, 174, 255, 0.7)", borderRadius: "9px", fontWeight: "500"
                        }}>11 : 30 to 12 : 30</div>

                    </div>
                </div>
            </div>
        );
    })
);

export default AppointmentTime
