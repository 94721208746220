import React, { useState, useEffect } from "react";
import blackleftarrow from "../Assets/blackleftarrow.svg";
// import doublearrow from '../Assets/doublearrow.svg'
import doctor from "../Assets/doctor.svg";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Colors, HexColors } from "../Utils/colors";
import axios from "axios";
import { Button } from "antd";
import "../Styles/foundDoctor.css"

const FoundDoctor = inject("store")(
  observer(({ store, otherProp }) => {
    const history = useHistory();
    const { state } = useLocation();
    const [doctorName, setDoctorName] = useState("");
    const [qualification, setQualification] = useState("");

    console.log({ state });

    useEffect(() => {
      axios
        .get(`api/pat/chat-details/?chat=${state.id}&type=doc`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.access,
          },
        })
        .then((res) => {
          console.log(res);
          setDoctorName(res.data.data.user_data.name);
          setQualification(res.data.data.qualification);
        });
    }, [state.id]);

    return (
      <div
        className="container text-center mx-w-sm mx-auto"
        style={{ alignItems: "center" }}
      id="found_doctor_main_div"
      >
        <img
          onClick={() => history.push("/home")}
          className="pt-10 pl-8"
          src={blackleftarrow}
          alt=""
          id="found_doctor_back"
        />
        <div
          className="md:grid md:place-items-center md:h-screen md:w-full mt-8"
          style={{ alignItems: "center" }}
          
        >
          <h1 className="font-medium text-2xl px-10">Available Doctor</h1>
          <div
            className="mx-7 pt-1.5 mt-4"
            id="doctor_available_div"
            style={{
              height: "490px",
              background: "#FFFFFF",
              boxShadow:
                "0px 4px 4px rgba(51,51, 51, 0.04), 0px 4px 16px rgba(51, 51, 51, 0.08)",
              borderRadius: "10px",
            }}
          >
            <div className="" style={{ height: "200px" }}>
              <img
                style={{ borderRadius: "50%", marginTop: "22px" }}
                className="mx-auto mt-10"
                src={doctor}
                alt=""
              />
              <div className="font-medium text-2xl pt-2">{doctorName}</div>
              <div
                style={{ fontSize: "20px", fontWeight: "normal" }}
                className=""
              >
                {qualification}
              </div>
            </div>
            <hr className="mt-48 mx-6"></hr>
            <div
              onClick={() =>
                history.push("/chat", { id: state.id, doctor: state.doctor })
              }
              className=" flex justify-evenly mt-2mx-7 mb-2 pb-3 pt-4 mt-0.5"
            >
              <div
                className="text-xl text-black font-medium"
                style={{ marginLeft: "-72px" }}
              >
                Chat now
              </div>
              <svg
                style={{ marginRight: "-95px" }}
                className="text-left"
                width="31"
                height="35"
                viewBox="0 0 31 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2L28 17.5L12 33"
                  stroke={
                    store.color === Colors.allopathic
                      ? HexColors.allopathic
                      : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                  }
                  stroke-width="4"
                  stroke-linecap="round"
                />
                <path
                  d="M2 6L14 17.5L2 29"
                  stroke={
                    store.color === Colors.allopathic
                      ? HexColors.allopathic
                      : store.color === Colors.homeopathic
                      ? HexColors.homeopathic
                      : HexColors.ayurvedic
                  }
                  stroke-width="4"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <Button
          // onClick={() => handleSubmit()}
          className="fixed md:static bottom-0 left-0 w-full mt-2 text-xl"
          style={{
            background:
              store.color === Colors.allopathic
                ? HexColors.allopathic
                : store.color === Colors.homeopathic
                ? HexColors.homeopathic
                : HexColors.ayurvedic,
            borderRadius: "2px",
            color: "white",
            height: "70px",
          }}
        >
          Exit
        </Button>
      </div>
    );
  })
);

export default FoundDoctor;
